import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addService, btnModalT, serviceCallA, SetService } from '../../../store/actions';

const ServiceCard = ({service,checkboxChecked,onClick,onCheckboxChange,checked}) => {
    const dispatch = useDispatch()
    const serviceReduxId = useSelector(state => state.packReducer.serviceId);
     const serviceR = useSelector(state =>
            state.packReducer.services.find(service => service.id === serviceReduxId)
        );
    // const statusR = serviceR.status || null ;
    const hanldeSelectService = () => {
        // dispatch(addService(service))
         dispatch(serviceCallA())
            dispatch(SetService(service._id));
            dispatch(btnModalT());
        
    }
    // checked={serviceR && serviceR.status !== null ? serviceR.status : false}
    
    return (

        <div onClick={hanldeSelectService} style={{margin:'7.5px'}}>
        <div className="mil-mb-15 mil-up widthService" style={{ width:360, translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1 }}>
            <label htmlFor={service._id}  className={`mil-service mil-custom-checkbox mil-m-1 mil-fw-600  ${checked ? 'mil-checked mil-already-included' : ''}`} >
                <input type="checkbox" id={service._id} name={service.title}  className="mil-additional-service" 
      disabled={!checkboxChecked} onChange={onCheckboxChange} checked={checked} />
                <span className="mil-service-name mil-fs-18">{service.title}</span>
                <span className="mil-checkbox-checkmark" />
            </label>
        </div>
    </div>
    );
}



export default ServiceCard;