import * as ActionTypes from '../action-types'

export function initialStatManual(payload) {
    return {
        type: ActionTypes.INITSTATE_T,
        payload
    }
}

export function authLogin(payload) {
    return {
        type: ActionTypes.AUTH_LOGIN,
        payload
    }
}


export function authLogout() {
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authCheck() {
    return {
        type: ActionTypes.AUTH_CHECK
    }
}

export function authVerify(payload) {
    return {
        type: ActionTypes.AUTH_VERIFY,
        payload
    }
}

export function changeNavigatorTheme(payload) {
    return {
        type: ActionTypes.CHANGE_N_T,
        payload
    }
}

export function auth_t(payload) {
    return {
        type: ActionTypes.AUTH_T,
        payload
    }
}
export function profile(payload) {
    return {
        type: ActionTypes.PROFILE_T,
        payload
    }
}
export function classes(payload) {
    return {
        type: ActionTypes.CLASSES_T,
        payload
    }
}
export function dataTable(payload) {
    return {
        type: ActionTypes.DATA_T,
        payload
    }
}
export function limit(payload) {
    return {
        type: ActionTypes.LIMIT_T,
        payload
    }
}
export function pageNo(payload) {
    return {
        type: ActionTypes.PAGE_NO_T,
        payload
    }
}
export function ramassage(payload) {
    return {
        type: ActionTypes.RAMASS_TRANS,
        payload
    }
}
export function mapsRoutes(payload) {
    return {
        type: ActionTypes.MAPSROUTES,
        payload
    }
}
export function addBusColor(payload) {
    return {
        type: ActionTypes.ADDbUSCOLOR,
        payload
    }
}
export function addMapPosition(payload) {
    return {
        type: ActionTypes.ADDMAPPOSITION,
        payload
    }
}
export function shop_action(payload) {
    return {
        type: ActionTypes.SHOPACTION,
        payload
    }
}
export function prodId_t(payload) {
    return {
        type: ActionTypes.PRODID_T,
        payload
    }
}
export function cartTab_t(payload) {
    return {
        type: ActionTypes.CARTTAB_T,
        payload
    }
}
export function homeCartvalue(payload) {
    return {
        type: ActionTypes.HOMECARTPRODUCTVALUE,
        payload
    }
}
export function showCartTab_t(payload) {
    return {
        type: ActionTypes.SHOWCARTTAB_T,
        payload
    }
}
export function cartInitTab_t(payload) {
    return {
        type: ActionTypes.CARTINITTABT,
        payload
    }
}
export function cartUpdateTab_t(payload) {
    return {
        type: ActionTypes.CARUPDATETTAB_T,
        payload
    }
}
export function deleteCartTab_t(payload) {
    return {
        type: ActionTypes.DELETECARTTAB_t,
        payload
    }
}
export function home_p(payload) {
    return {
        type: ActionTypes.HOMEP,
        payload
    }
}

export function relaypointId_t(payload) {
    return {
        type: ActionTypes.RELAYPOINTID,
        payload
    }
}

export function showAlertHomeProduct(payload) {
    return {
        type: ActionTypes.SHOWALERTHOMEPRODUCT,
        payload
    }
}

export function showCarteadhesionStore(payload) {
    return {
        type: ActionTypes.showCarteadhesion,
        payload
    }
}

export function tabsCatValueFunction(payload) {
    return {
        type: ActionTypes.TABSCATVALUE,
        payload
    }
}

export function navCartStore(payload) {
    return {
        type: ActionTypes.NAVCARTSTORE,
        payload
    }
}

export function existantReceiverUser(payload) {
    return {
        type: ActionTypes.EXISTANTRECEIVERUSER,
        payload
    }
}

export function saveUserInfos(payload) {
    return {
        type: ActionTypes.SAVEUSERINFOS,
        payload
    }
}

export function onClickOnCmd(payload) {
    return {
        type: ActionTypes.ONCLICKONCMD,
        payload
    }
}

export function relayPointValue(payload) {
    return {
        type: ActionTypes.RELAYPOINTVALUE,
        payload
    }
}

export function beneficiaryLocation(payload) {
    return {
        type: ActionTypes.BENEFICIARYLOCATION,
        payload
    }
}

export function initRelayPointData(payload) {
    return {
        type: ActionTypes.INITRELAYPOINTDATA,
        payload
    }
}

export function typeOfHomeLocalization(payload) {
    return {
        type: ActionTypes.TYPEOFHOMELOCALIZATION,
        payload
    }
}

export function socialAssistanceObject(payload) {
    return {
        type: ActionTypes.SOCIALASSISTANCEOBJECT,
        payload
    }
}

export function selectedValueFunct(payload) {
    return {
        type: ActionTypes.SELECTEDVALUEFUNCT,
        payload
    }
}

export function initRelaypointId(payload) {
    return {
        type: ActionTypes.INITRELAYPOINTIDTYPE,
        payload
    }
}

export function economicAccessibility(payload) {
    return {
        type: ActionTypes.ECONOMICACCESSIBILITY,
        payload
    }
}

export function seacrchListAction(payload) {
    return {
        type: ActionTypes.SEARCHLIST,
        payload
    }
}

export const addToCart = (offre) => {
    return {
        type: 'ADD_TO_CART',
        payload: offre
    };
};
export const incrementQuantity = () => {
    return {
        type: 'INCREMENT_QUANTITY'
    };
};
export const decrementQuantity = () => {
    return {
        type: 'DECREMENT_QUANTITY'
    };
};

export const removeFromCart = (productId) => {
    return {
        type: 'REMOVE_FROM_CART',
        payload: productId
    };
};
export const addToWishlist = (offre) => {
    return {
        type: 'ADD_TO_WISHLIST',
        payload: offre
    };
};

export const removeFromwishlist = (productId) => {
    return {
        type: 'REMOVE_FROM_WISHLIST',
        payload: productId
    };
};
export const clearCart = () => {
    return {
        type: 'CLEAR_CART',
    };
};
export const updateSubtotalForProduct = (productId, subtotal) => {
    return {
        type: 'UPDATE_SUBTOTAL',
        payload: { productId, subtotal },
    };
};

// actions/packActions.js
export const setSelectedPack = (id) => ({
    type: 'SET_SELECTED_PACK',
    payload: id,
});
export const setEmail = (email) => {
    return {
        type: 'SET_EMAIL',
        payload: email,
    };
};
export const setMdp = (mdp) => {
    console.log("yysts ", mdp);
    return {
        type: 'SET_MDP',
        payload: mdp,
    };
};
export const setIdProtector = (id) => {
    return {
        type: 'SET_ID',
        payload: id,
    };
};
export const activer = () => {
    return {
        type: 'ACTIVER',
    };
};
export const activerpanier = () => {
    return {
        type: 'ACTIVERPANIER',
    };
};
export const desactiverpanier = () => {
    return {
        type: 'DESACTIVERPANIER',
    };
};


// Action pour désactiver
export const desactiver = () => {
    return {
        type: 'DESACTIVER',
    };
};

export const set_MESSAGE_INVALID_CREDENTIAL = (message) => {
    return {
        type: 'SET_MESSAGE_INVALID_CREDENTIAL',
        payload: message,
    };
};
export const logout = () => {
    return {
        type: 'LOGOUT',
    };
};
export const setDateOfCompletion = (date) => {
    return {
        type: 'DATE_OF_COMPLETION',
        payload: date,
    };
};
export const setTimeRedux = (time) => {
    return {
        type: 'SET_TIME_REDUX',
        payload: time,
    };
};

export const setOffrePaiement = (offre) => {
    return {
        type: 'SET_OFFRE',
        payload: offre,
    };
};
export const emailexiste = () => {
    return {
        type: 'EMAILEXISTE',
    };
};

export const setLocation = (location) => {
    return {
        type: 'SET_LOCATION',
        payload: location,
    };
};

export const serviceCallA = () => {
    return {
        type: 'SERVICECALLA',
    };
};
export const serviceCallD = () => {
    return {
        type: 'SERVICECALLD',
    };
};
export const serviceCallA_ = () => {
    return {
        type: 'SERVICECALA',
    };
};
export const serviceCallD_ = () => {
    return {
        type: 'SERVICECALD',
    };
};
export const SetService = (id) => {
    return {
        type: 'SET_SERVICE',
        payload: id,
    };
};
export const SetServiceSante = (id) => {
    return {
        type: 'SET_SERVICE_SANTE',
        payload: id,
    };
};
export const setListes = (data) => {
    return {
        type: 'SET_LISTES',
        payload: data,
    };
};
export const setListesData = (data) => {
    return {
        type: 'SET_LISTES_Pack',
        payload: data,
    };
};
export const addServiceSante = (service) => {
    return {
        type: 'ADD_SERVICES_SANTE',
        payload: service,
    };
};

export const addService = (service) => {
    return {
        type: 'ADD_SERVICES',
        payload: service,
    };
};
// const handleUpdateService = (id, newStatus) => {
//     dispatch({
//         type: 'UPDATE_SERVICE',
//         payload: { id, status: newStatus }, // Envoie l'id et la nouvelle valeur de status
//     });
// };
export const UpdateServiceDateOfCompletion = (serviceId, date_of_completion) => {
    return {
        type: 'UPDATE_SERVICE_DATE_OF_COMPLETION',
        payload: {
            serviceId, // ID du service à mettre à jour
            date_of_completion     // Nouveau statut (true/false)},
        }
    };
};
export const UpdateServiceDocumentFiles = (serviceId, documentFiles) => {

    return {
        type: 'UPDATE_SERVICE_DOCUMENT_FILES',
        payload: { serviceId, documentFiles },
    };
};
export const UpdateService = (id, status) => {
    return {
        type: 'UPDATE_SERVICE',
        payload: { id, status },
    };
};
export const UpdateServices = (id, location, date, description) => {
    return {
        type: 'UPDATE_SERVICE',
        payload: { id, location, date, description },
    };
};

export const removeService = (serviceId) => {
    return {
        type: "REMOVE_SERVICE",
        payload: serviceId,
    };
};

export const setbenificiaryid = (id) => {
    return {
        type: "BENEFICIARY_ID",
        payload: id,
    };
};
export const setSymbol = (data) => {
    return {
        type: "SYMBOL",
        payload: data,
    };
};

//SANTE
export const updateServiceStatus = (serviceId, status) => {
    return {
        type: 'UPDATE_SERVICE_STATUS',
        payload: {
            serviceId, // ID du service à mettre à jour
            status     // Nouveau statut (true/false)
        }
    };
};
export const updateServiceStatusNew = (serviceId, status, priceConvert) => {
    return {
        type: 'UPDATE_SERVICE_STATUS_PACk',
        payload: {
            serviceId, // ID du service à mettre à jour
            status,     // Nouveau statut (true/false)
            priceConvert
        }
    };
};

export const updateServicePriceConvert = (serviceId, priceConvert) => {
    return {
        type: 'UPDATE_SERVICE_PRICE_CONVERT',
        payload: {
            serviceId, // ID du service à mettre à jour
            priceConvert     // Nouveau statut (true/false)
        }
    };
};
export const updateServiceCity = (serviceId, cityId) => {
    return {
        type: 'UPDATE_SERVICE_CITY',
        payload: {
            serviceId,
            cityId
        }
    };
}

export const setDocumentFiles = (data) => {
    return {

        type: 'DOCUMENT_FILES',
        payload: data
    };
}
export const setCurrentPath = (path) => {
    return {
        type: 'CURRENT_PATH',
        payload: path
    };
}
export const setTitle = (title) => {
    return {
        type: 'SET_TITLE',
        payload: title
    };
}
export const setTotalAmount = (totalAmount) => {
    return {
        type: 'SET_TOTAL_AMOUNT',
        payload: totalAmount
    };
}
export const setCurrentPathCurrency = (data) => {
    return {
        type: 'CURRENCYCURRENTPATH',
        payload: data
    };
}

export const setUserBeneficiaryId = (userBeneficiaryId) => {
    return {
        type: 'SET_USER_BENEFICIARY_ID',
        payload: userBeneficiaryId
    };
}
export const setServiceBeneficiaryId = (id) => {
    return {
        type: 'SET_SERVICE_BENEFICIARY_ID',
        payload: id
    };
}
export const updateServiceUserBeneficiary = (serviceId, userBeneficiaryId) => {
    return {
        type: 'UPDATE_SERVICE_USER_BENEFICIARY',
        payload: {
            serviceId,
            userBeneficiaryId
        }
    };
};
export const UpdateServiceSante = (id) => {
    return {
        type: 'UPDATE_SERVICE_SANTE',
        payload: { id },
    };
};
export const UpdateCurrencyCountry = (currencyCountry) => {
    return {
        type: 'UPDATE_CURRENCY_COUNTRY',
        payload: currencyCountry,
    };
};
// export const UpdateServicesSante = (id,location,date,description ) => {
//     return {
//         type: 'UPDATE_SERVICE_SANTE',
//         payload: { id,location,date,description },
//     };
// };
export const removeServiceSante = (serviceId) => {
    return {
        type: "REMOVE_SERVICE_SANTE",
        payload: serviceId,
    };
};




export const verificationModalT = () => {
    return {
        type: 'SETISACTIVEVERIFICATIONT',
    };
};
export const verificationModalF = () => {
    return {
        type: 'SETISACTIVEVERIFICATIONF',
    };
};

export const btnModalT = () => {
    return {
        type: 'SETISACTIVEBTNT',
    };
};
export const btnModalF = () => {
    return {
        type: 'SETISACTIVEBTNF',
    };
};
export const navigateTrue = () => {
    return {
        type: 'NAVIGATETRUE',
    };
};
export const navigateFalse = () => {
    return {
        type: 'NAVIGATEFALSE',
    };
};
export const updateServicesReducer = (services) => {
    return {
        type: 'UPDATE_SERVICES',
        payload: services
    };
};
export const clearLocalStorageExcept = (keysToKeep) => {
    return (dispatch) => {
      // Récupérer toutes les clés dans le localStorage
      const keys = Object.keys(localStorage);
  
      // Supprimer toutes les clés sauf celles à garder
      keys.forEach((key) => {
        if (!keysToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      });
  
      // Dispatcher une action si tu veux suivre ce changement dans ton état global
      dispatch({
        type: "CLEAR_LOCAL_STORAGE_EXCEPT",
        payload: keysToKeep, // facultatif, si tu veux conserver les clés conservées
      });
    };
  };