import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const Profil = () => {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [desactiver, setDesactiver] = useState(true)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        phone: phoneNumber,
        location: '',
        role: 'protector',
    });
    const [avatar, setAvatar] = useState(null);
    const [preview, setPreview] = useState(null);




    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileURL = URL.createObjectURL(file);
            setPreview(fileURL); // Pour prévisualiser l'image localement
            setAvatar(file); // Stocker le fichier pour l'envoi au serveur
        }
    };
    const [formErrors, setFormErrors] = useState({
        name: '',
        password: '',
        email: '',
        location: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Validation simple
        if (value.trim() === '') {
            setFormErrors({
                ...formErrors,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setFormErrors({
                ...formErrors,
                [name]: ''
            });
        }
    };
    const [valid, setValid] = useState(true)
    const handleChangePhone = (value) => {
        setPhoneNumber(value);
        setValid(validphonenumber(value));
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const validphonenumber = (input) => {
        const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return patt.test(input);
    }




    const handleSubmit = (e) => {
        e.preventDefault();
    }
    const [couleur, setCouleur] = useState(null)
    const [couleur2, setCouleur2] = useState('rgb(102, 102, 102)')
    const [activeProcteur, setActiveProcteur] = useState(true);
    const [activeBeneficiary, setactiveBeneficiary] = useState(false);
    const handleactiveP = () => {
        setCouleur('black')
        setCouleur2('rgb(102, 102, 102)')
        setactiveBeneficiary(false);
        setActiveProcteur(true)
    };
    const handleactiveB = () => {
        setCouleur('rgb(102, 102, 102)')
        setCouleur2('black')
        setactiveBeneficiary(true);
        setActiveProcteur(false)
    };
    const storeduserInfo = localStorage.getItem('userInfo');
    console.log('storeduserInfo aaaaaaa', storeduserInfo);
    const parsedUserInfo = JSON.parse(storeduserInfo);
    // URL de base de ton serveur
    const baseUrl = "https://api.wemaza.com/";

    // URL complète de l'image
    const avatarUrl = baseUrl + parsedUserInfo.avatar;

    const navigateHome = () => { navigate('/') }

    const handleDesactive = () => {
        setDesactiver(false)
    }
    const handleDesactiveT = () => {
        setDesactiver(true)
    }
    console.log('parsedUserInfo.avatar ttttttttttttttttttttt', parsedUserInfo.avatar)

    return (
        <div className='d-flex flex-column' style={{ marginBottom: 50 }}>
            <div className="header-top header-top1" style={{ position: 'fixed', boxShadow: 'none' }} >
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12 d-none d-sm-block">
                            <div className="header-left d-table-cell align-middle">
                                <div className="phone-icon d-inline-block">
                                    <i className="fa-solid fa-phone-volume" />
                                </div>
                                <p className="free-consultation-text">
                                    Besoin d'aide ? Contactez-nous gratuitement :
                                    <a href="tel:+123456789" className="text-decoration-none">0800 123 45 67 890</a>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="header-right">
                                <ul className="list-unstyled d-flex justify-content-center justify-content-md-end align-items-center">
                                    <li className="d-inline-block">
                                        <i className="fa-sharp fa-solid fa-envelope" />
                                        <a className="d-inline-block email-span text-decoration-none" href="mailto:info@sencare.com">info@sencare.com</a>
                                    </li>
                                    <li className="d-inline-block ml-3">
                                        <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#4267B2' }} />
                                    </li>
                                    <li className="d-inline-block ml-4">
                                        <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25D366' }} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' d-flex marginBottom220' style={{ height: '730px', marginBottom: 40, marginTop: 62 }}>


                <div style={{ height: '730px', width: 250, background: "#378941", padding: 15, paddingTop: 25 }} className=' displaynone d-flex flex-column  justify-content-between align-items-center'>
                    <div className='d-flex'>
                        <img src="favicon.ico " className='imageProfil1' /><p style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }}>WEMAZA</p>
                        {/* /assets/images/logo1.png favicon.ico */}
                    </div>

                    <div className='d-flex flex-column  justify-content-evenly' style={{ height: 443, width: 'auto', marginBottom: '252px' }}>
                        <div className='d-flex ' style={{ cursor: 'pointer' }} onClick={navigateHome}>
                            <img src="./assets/images/71.png" className='imageProfil' />
                            <p style={{ fontSize: 16, fontWeight: 'bold', color: 'black', marginLeft: 7 }} >Accueil</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/72.png" className='imageProfil' />
                            <p style={{ fontSize: 16, fontWeight: 'bold', color: 'black', marginLeft: 7 }} >Notifications</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/73.png" className='imageProfil' />
                            <p style={{ fontSize: 16, fontWeight: 'bold', color: 'black', marginLeft: 7 }} >Calendrier</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/74.png" className='imageProfil' />
                            <p style={{ fontSize: 16, fontWeight: 'bold', color: 'black', marginLeft: 7 }} >Partage</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/deconnecter.png" className='imageProfil' />
                            <p style={{ fontSize: 16, fontWeight: 'bold', color: 'black', marginLeft: 7 }} >Déconnexion</p>
                        </div>





                    </div>
                    <div> <img src="./assets/images/77.png" className='imageProfil2' /></div>

                </div>

                <div className='displaynone' style={{ height: '730px', width: 350, borderRight: '1px solid #858585', backgroundColor: 'white' }}>

                    <div className='d-flex ' style={{ marginTop: 25 }}>
                        <img src="./assets/images/78.png" className='imageProfil' />
                        <div style={{ marginLeft: 24 }}>
                            <p style={{ fontSize: 20, fontWeight: 'bold' }}>Paramètres</p>
                        </div>

                    </div>

                    <div className='settingCss' style={{ height: 390, padding: 15, marginTop: '-15px' }}>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/79.png" className='imageProfil6' />
                            <p style={{ fontSize: 14, fontWeight: 'bold', color: couleur }} onClick={handleactiveP}>Modifier le profil</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/72_.png" className='imageProfil4' />
                            <p style={{ fontSize: 14, fontWeight: 'bold', color: couleur2 }} onClick={handleactiveB}>Bénéficiaires</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/w_.png" className='imageProfil4' />
                            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(102, 102, 102)' }}>Wallet</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/82.png" className='imageProfil4' />
                            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(102, 102, 102)' }}>Parrainnage</p>
                        </div>
                        <div className='d-flex ' style={{ cursor: 'pointer' }}>
                            <img src="./assets/images/83.png" className='imageProfil4' />
                            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(102, 102, 102)' }}>Aide</p>
                        </div>
                    </div>
                </div>


                {activeProcteur ? <>
                    <div style={{ backgroundColor: 'white', width: "100%" }}>
                        <div className='marginleft0' style={{ width: 'auto', padding: 10 }}>
                            <div className='menudropdown margin0' style={{ display: 'none', marginTop: 20 }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Menu
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='d-flex' onClick={navigateHome}><img src="./assets/images/71.png" className='imageProfil4' /><p style={{ marginTop: 4 }}>Accueil</p> </Dropdown.Item>
                                        <Dropdown.Item className='d-flex ' >  <img src="./assets/images/72_.png" className='imageProfil4' /><p style={{ cursor: 'pointer', marginTop: 4 }}> Bénéficiaires</p></Dropdown.Item>
                                        <Dropdown.Item className='d-flex' > <img src="./assets/images/w_.png" className='imageProfil4' /><p style={{ cursor: 'pointer', marginTop: 4 }}> Wallet</p></Dropdown.Item>
                                        <Dropdown.Item className='d-flex' ><img src="./assets/images/82.png" className='imageProfil4' /><p style={{ cursor: 'pointer', marginTop: 4 }}>  Parrainnage</p></Dropdown.Item>
                                        <Dropdown.Item className='d-flex' > <img src="./assets/images/83.png" className='imageProfil4' /><p style={{ cursor: 'pointer', marginTop: 4 }}> Aide</p></Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item ><span style={{ fontWeight: 'bold' }}>Paramètres</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                            <div style={{ marginTop: 40, marginBottom: 30 }} className='d-flex justify-content-center'>
                                <p style={{ fontSize: 24, fontWeight: '750', marginTop: 20 }}>Modifier le Protecteur</p>


                            </div>
                            <div className='d-flex-div'>
                                <form onSubmit={handleSubmit} className='width400'>
                                    <div className='d-flex' style={{ justifyContent: 'center' }}>
                                        {
                                            (parsedUserInfo.avatar != null || parsedUserInfo.avatar === null) && desactiver === false
                                                ?
                                                (
                                                    <div className="upload">
                                                    <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                                        {preview != null ? (
                                                            <img src={preview} width="100" height="100" alt="" />
                                                        ) : (
                                                            <img src="./assets/images/noprofil.jpg" width={100} height={100} alt="" />
                                                        )}
                
                                                        <div className="round">
                                                            <i className="fa fa-camera" style={{ color: '#fff' }} />
                                                        </div>
                                                    </label>
                
                                                    <input
                                                        type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange}
                                                    />
                                                </div>
                                                ) : parsedUserInfo.avatar != null ?

                                                    (<img src={avatarUrl} className='imageProfil3' />) : (<img src="./assets/images/noprofil.jpg" width={100} height={100} alt />)


                                        }


                                    </div>
                                    <div className='d-flex flexcolumn' >
                                        <div style={{ width: '50%' }} className='mr-3 mt-1 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.name ? 'red' : 'black' }} htmlFor="name" className="form-label">Nom et Prénom</label>
                                            <input
                                                style={{ height: 55 }}
                                                type="text"
                                                className={`form-control height70  ${formErrors.name ? 'is-invalid' : ''}`}
                                                id="name"
                                                disabled={desactiver}
                                                name="name"
                                                value={formData.name || parsedUserInfo.name}
                                                onChange={handleChange}
                                                placeholder="Entrez votre nom"
                                            />
                                            {formErrors.name && <small className="text-danger">Veuillez saisir votre nom et prénom</small>}
                                        </div>
                                        <div style={{ width: '50%' }} className='mt-1 ml-3 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.email ? 'red' : 'black' }} htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                style={{ height: 55 }}
                                                type="email"
                                                className={`form-control height70 ${formErrors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                name="email"
                                                disabled={desactiver}
                                                value={formData.email || parsedUserInfo.email}
                                                onChange={handleChange}
                                                placeholder="Entrez votre email"
                                            />

                                            {formErrors.email && (
                                                <small className="text-danger" style={{ display: 'block', marginTop: 8 }}>
                                                    Veuillez saisir votre email
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className='d-flex flexcolumn'>
                                        <div style={{ width: '50%' }} className='mr-3 mt-1 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.phone ? 'red' : 'black' }} htmlFor="phone" className="form-label">Téléphone</label>
                                            <PhoneInput
                                                country={'fr'}
                                                className="phoneClass"
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                disabled={desactiver}
                                                value={phoneNumber || parsedUserInfo.phone}
                                                onChange={handleChangePhone}
                                                placeholder="Entrez votre numéro de téléphone"
                                            />
                                            {formErrors.phone && <small className="text-danger">Veuillez saisir votre numéro de teléphone</small>}
                                        </div>
                                        <div style={{ width: '50%' }} className=' ml-3  mt-1 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.location ? 'red' : 'black' }} htmlFor="location1" className="form-label">Adresse</label>
                                            <input
                                                style={{ height: 55 }}
                                                type="text"
                                                className={`form-control height70 ${formErrors.location ? 'is-invalid' : ''}`}
                                                id="location1"
                                                name="location"
                                                disabled={desactiver}
                                                value={formData.location || parsedUserInfo.location}
                                                onChange={handleChange}
                                                placeholder="Entrez votre adresse"
                                            />
                                            {formErrors.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                        </div>
                                    </div>
                                    <div className='d-flex flexcolumn'>
                                        <div style={{ width: '50%' }} className='mr-3 mt-1 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.password ? 'red' : 'black' }} htmlFor="password" className="form-label">Ancien mot de passe</label>
                                            <input
                                                style={{ height: 55 }}
                                                type="password"
                                                className={`form-control height70 ${formErrors.password ? 'is-invalid' : ''}`}
                                                id="password"
                                                name="password"
                                                disabled={desactiver}
                                                value={formData.password}
                                                onChange={handleChange}
                                                placeholder="Entrez votre mot de passe"
                                            />
                                            {formErrors.password && <small className="text-danger">Veuillez saisir votre mot de passe</small>}
                                        </div>
                                        <div style={{ width: '50%' }} className=' ml-3  mt-1 with1001 marginleftright0'>
                                            <label style={{ fontWeight: "normal", color: formErrors.password ? 'red' : 'black' }} htmlFor="password" className="form-label">Nouveau mot de passe</label>
                                            <input
                                                style={{ height: 55 }}
                                                type="password"
                                                className={`form-control height70 ${formErrors.password ? 'is-invalid' : ''}`}
                                                id="password"
                                                name="password"
                                                disabled
                                                value={formData.password}
                                                onChange={handleChange}
                                                placeholder="Entrez votre mot de passe"
                                            />
                                            {formErrors.password && <small className="text-danger">Veuillez saisir votre mot de passe</small>}
                                        </div>

                                    </div>
                                    <div className='d-flex mt-3'>

                                        <button type="button" className="btn btn-outline-success mr-4" onClick={handleDesactiveT} >Annuler</button>

                                        {desactiver === true ? <button type="button" className="btn btn-success" onClick={handleDesactive} >Modifier</button>

                                            :
                                            <button type="button" className="btn btn-success" onClick={handleDesactive} >Enregistrer</button>
                                        }

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div></> : null}
                {
                    activeBeneficiary ? <>
                        <div style={{ backgroundColor: 'white' }}>
                            <div style={{ width: 700, padding: 10, marginLeft: 60, marginRight: 15 }}>
                                <div style={{ marginTop: 40, marginBottom: 30 }} className='d-flex justify-content-between'>
                                    <p style={{ fontSize: 24, fontWeight: '750', marginTop: 20 }}>Liste de vos bénéficiaires</p>
                                    <img src={avatarUrl} className='imageProfil3' />

                                </div>
                            </div>
                        </div>

                    </> : null
                }

            </div>
        </div>
    );
};

export default Profil;

