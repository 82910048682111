import React, { useState } from 'react';
import { debounce } from 'lodash';

const OSMSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [places, setPlaces] = useState([]);

  // Fonction pour lancer la recherche
  const handleSearch = async (query) => {
    const url = `https://nominatim.openstreetmap.org/search?q=${query}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setPlaces(data);
    } catch (error) {
      console.error('Erreur lors de la recherche OSM', error);
    }
  };

  // Définir le debounce de la fonction handleSearch (500ms d'attente)
  const debouncedSearch = debounce((query) => handleSearch(query), 1000);

  // Lorsque l'utilisateur tape dans l'input, on met à jour la requête et lance la recherche
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);  // Appeler la recherche après un délai
  };

  return (
    <div style={{ margin: 300 }}>
      <input
        type="text"
        placeholder="Rechercher un lieu"
        value={searchQuery}
        onChange={handleChange}  // Lancer la recherche après saisie
      />
      <ul>
        {places.map((place, index) => (
          <li key={index}>{place.display_name}</li>  // Utilisation de l'index comme clé
        ))}
      </ul>
    </div>
  );
};

export default OSMSearch;
