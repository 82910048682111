import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ProductFeaturedCard from './common/components/ProductFeaturedCard';
import ProductPopularCArd from './common/components/ProductPopularCArd';
import Register from './common/pages/register/Register';
import Login from './common/pages/login/Login';
import About from './common/pages/about/About';
import ShopGridSdebar from './common/pages/shop/ShopGridSdebar';
import ShopGridTopFilter from './common/pages/shop/ShopGridTopFilter';
import ShopListTopFilter from './common/pages/shop/ShopListTopFilter';
import ShopListSidebar from './common/pages/shop/ShopListSidebar';
import ShopDetail from './common/pages/shop/ShopDetail';
import ShopCompare from './common/pages/shop/product feature/ShopCompare';
import ShopDetailsAffiliats from './common/pages/shop/product feature/shopDetailsAffiliats';
import ShopDetailsGroup from './common/pages/shop/product feature/ShopDetailsGroup';
import ShopDetailsVariable from './common/pages/shop/product feature/ShopDetailsVariable';
import Cart from './common/pages/shop/ShopOrders/Cart';
import Checkout from './common/pages/shop/ShopOrders/Checkout';
import Trackorder from './common/pages/shop/ShopOrders/Trackorder';
import VendorDetails from './common/pages/vendors/VendorDetails';
import VendorGrid from './common/pages/vendors/VendorGrid';
import VendorList from './common/pages/vendors/VendorList';
import Store from './common/pages/store/Store';
import Faq from './common/pages/faq/Faq';
import invoice from './common/pages/invoice/invoice';
import contact from './common/pages/contact/contact';
import PrivatePolicy from './common/pages/Privacy policy/PrivatePolicy';
import CookiesPolicy from './common/pages/cookies policy/CookiesPolicy';
import Error from './common/pages/error/Error';
import Account from './common/pages/account/Account';
import Header from './common/pages/home/Header';
import Footer from './common/pages/home/Footer';
import Wishlist from './common/pages/wishlist/Wishlist';
import SousCard from './common/components/SousCard';
import CategoriesPage from './common/components/CategoriesPage';
import SearchBarre from './common/components/SearchBarre';
import Bouton from './common/components/Bouton';
import NosServises from './common/pages/presentation_wemaza_service/NosServises';
import Pharmacie from './common/pages/presentation_wemaza_service/Pharmacie';
import DetailsPartenaire from './common/pages/presentation_wemaza_service/DetailsPartenaire';
import Home from './common/pages/home/home';
import Pricecing from './common/pages/princing/Pricecing';
import Pricecing2 from './common/pages/princing/Pricecing2';
import Pricecing3 from './common/pages/princing/Pricecing3';
import Personnalise from './common/pages/princing/Personnalise';
import ScrollToTop from './common/pages/scrollTop/ScrollTop';
import Paiement from './common/pages/paiement/Paiement';
import RegisterForProtecteur from './common/pages/register/RegisterForProtecteur';
import Register_ from './common/pages/register/Register_';
import Abonnement from './common/pages/princing/Abonnement';
import 'bootstrap/dist/css/bootstrap.min.css';
import Verify from './common/pages/verfiy/Verify';
import SecondPaiement from './common/pages/paiement/SecondPaiement';
import BenficiaryPaiment from './common/pages/paiement/BenficiaryPaiment';
import Page404 from './common/pages/page404/Page404';
import Intermediary from './common/pages/intermediaire/Intermediary';
import NewPaiement from './common/pages/paiement/NewPaiement';
import VerifiedAlert from './common/pages/sweetalert/VerifiedAlert';
import IntermediaryPersonnalise from './common/pages/intermediaire/IntermediaryPersonnalise';
import FormWithGeolocation from './common/pages/page404/FormWithGeolocation';
import FromTest from './common/pages/page404/FromTest';
import Myform from './common/pages/error/Myform';
import Profil from './common/pages/profil/Profil';
import AvatarUpload from './common/pages/error/AvatarUpload';
import NewPAck from './common/pages/newPack/NewPack';
import Services from './common/pages/newPack/Services';
import ServiceCall from './common/pages/newPack/ServiceCall';
import ListeService from './common/pages/newPack/ListeService';
import ServiceTemp from './common/pages/services/ServiceTemp';
import NewIntermediary from './common/pages/intermediaire/NewIntermediary';
import ServiceTempSante from './common/pages/services/ServiceTempSante';
import GeoLocation from './common/pages/geolocation/GeoLocation';
import LocationInput from './common/pages/geolocation/LocationInput';
import OSMSearch from './common/pages/geolocation/OSMSearch';
import SearchBar from './common/pages/geolocation/SearchBar';
import Success from './common/pages/success/Success';
import Splashscreen from './common/components/Splashscreen';
const AppContent = React.lazy(() => import('./AppContent'));

const MesRoutes = () => {
    return (
        <Router>
            <Suspense fallback={<Splashscreen />}>
                <AppContent />
                {/* <Routes>
                    <Route path="/" Component={Splashscreen} />
                </Routes> */}
            </Suspense>
        </Router>
    );
};



export default MesRoutes;
