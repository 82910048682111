import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import 'bootstrap/dist/css/bootstrap.min.css';

const countryCodes = {
  "Cameroon": "+237",
  "France": "+33",
  "USA": "+1",
  // Ajoute d'autres pays ici
};

const FormWithGeolocation = () => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  
  const handleSelect = (value) => {
    setAddress(value);

    // Appel à l'API Google pour obtenir les détails du lieu
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${value}&key=TA_CLE_API`)
      .then(response => response.json())
      .then(data => {
        const country = data.results[0].address_components.find(component => 
          component.types.includes("country")
        ).long_name;

        const countryPrefix = countryCodes[country] || "";
        setCountryCode(countryPrefix);
      })
      .catch(error => console.error("Erreur lors de la géolocalisation :", error));
  };

  return (
    <div className="container mt-4">
      <form className="border p-4 shadow-sm bg-light rounded">
        <div className="mb-3">
          <label className="form-label">Adresse :</label>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Recherche de lieu...',
                    className: 'form-control'
                  })}
                />
                <div className="list-group mt-2">
                  {loading && <div className="list-group-item">Chargement...</div>}
                  {suggestions.map((suggestion) => {
                    const style = suggestion.active
                      ? { backgroundColor: '#e9ecef', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })} className="list-group-item">
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div className="mb-3">
          <label className="form-label">Téléphone :</label>
          <div className="input-group">
            <span className="input-group-text">{countryCode}</span>
            <input
              type="tel"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Numéro de téléphone"
            />
          </div>
        </div>

        <button type="submit" className="btn btn-primary">Soumettre</button>
      </form>
    </div>
  );
};

export default FormWithGeolocation;
