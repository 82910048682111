import React, { use, useEffect, useState } from 'react';
import axios from 'axios';


const currency = {
  "XOF": ["Benin", "Burkina Faso", "Ivory Coast", "Guinea-Bissau", "Mali", "Niger", "Senegal", "Togo"],
  "AED": ["United Arab Emirates"],
  "AFN": ["Afghanistan"],
  "ALL": ["Albania"],
  "AMD": ["Armenia"],
  "ANG": ["Netherlands Antilles", "Netherlands"],
  "AOA": ["Angola"],
  "ARS": ["Argentina"],
  "AUD": ["Australia"],
  "AWG": ["Aruba"],
  "AZN": ["Azerbaijan"],
  "BAM": ["Bosnia and Herzegovina"],
  "BBD": ["Barbados"],
  "BDT": ["Bangladesh"],
  "BGN": ["Bulgaria"],
  "BHD": ["Bahrain"],
  "BIF": ["Burundi"],
  "BMD": ["Bermuda"],
  "BND": ["Brunei"],
  "BOB": ["Bolivia"],
  "BRL": ["Brazil"],
  "BSD": ["Bahamas"],
  "BTN": ["Bhutan"],
  "BWP": ["Botswana"],
  "BYN": ["Belarus"],
  "BZD": ["Belize"],
  "CAD": ["Canada"],
  "CDF": ["Democratic Republic of the Congo"],
  "CHF": ["Switzerland"],
  "CLP": ["Chile"],
  "CNY": ["China"],
  "COP": ["Colombia"],
  "CRC": ["Costa Rica"],
  "CUP": ["Cuba"],
  "CVE": ["Cape Verde"],
  "CZK": ["Czech Republic"],
  "DJF": ["Djibouti"],
  "DKK": ["Denmark"],
  "DOP": ["Dominican Republic"],
  "DZD": ["Algeria"],
  "EGP": ["Egypt"],
  "ERN": ["Eritrea"],
  "ETB": ["Ethiopia"],
  "EUR": ["European Union", "Germany", "France", "Italy", "Spain", "Portugal", "Greece"],
  "FJD": ["Fiji"],
  "FKP": ["Falkland Islands"],
  "FOK": ["Faroe Islands"],
  "GBP": ["United Kingdom"],
  "GEL": ["Georgia"],
  "GGP": ["Guernsey"],
  "GHS": ["Ghana"],
  "GIP": ["Gibraltar"],
  "GMD": ["Gambia"],
  "GNF": ["Guinea"],
  "GTQ": ["Guatemala"],
  "GYD": ["Guyana"],
  "HKD": ["Hong Kong"],
  "HNL": ["Honduras"],
  "HRK": ["Croatia"],
  "HTG": ["Haiti"],
  "HUF": ["Hungary"],
  "IDR": ["Indonesia"],
  "ILS": ["Israel"],
  "IMP": ["Isle of Man"],
  "INR": ["India"],
  "IQD": ["Iraq"],
  "IRR": ["Iran"],
  "ISK": ["Iceland"],
  "JEP": ["Jersey"],
  "JMD": ["Jamaica"],
  "JOD": ["Jordan"],
  "JPY": ["Japan"],
  "KES": ["Kenya"],
  "KGS": ["Kyrgyzstan"],
  "KHR": ["Cambodia"],
  "KID": ["Kiribati"],
  "KMF": ["Comoros"],
  "KRW": ["South Korea"],
  "KWD": ["Kuwait"],
  "KYD": ["Cayman Islands"],
  "KZT": ["Kazakhstan"],
  "LAK": ["Laos"],
  "LBP": ["Lebanon"],
  "LKR": ["Sri Lanka"],
  "LRD": ["Liberia"],
  "LSL": ["Lesotho"],
  "LYD": ["Libya"],
  "MAD": ["Morocco"],
  "MDL": ["Moldova"],
  "MGA": ["Madagascar"],
  "MKD": ["North Macedonia"],
  "MMK": ["Myanmar"],
  "MNT": ["Mongolia"],
  "MOP": ["Macau"],
  "MRU": ["Mauritania"],
  "MUR": ["Mauritius"],
  "MVR": ["Maldives"],
  "MWK": ["Malawi"],
  "MXN": ["Mexico"],
  "MYR": ["Malaysia"],
  "MZN": ["Mozambique"],
  "NAD": ["Namibia"],
  "NGN": ["Nigeria"],
  "NIO": ["Nicaragua"],
  "NOK": ["Norway"],
  "NPR": ["Nepal"],
  "NZD": ["New Zealand"],
  "OMR": ["Oman"],
  "PAB": ["Panama"],
  "PEN": ["Peru"],
  "PGK": ["Papua New Guinea"],
  "PHP": ["Philippines"],
  "PKR": ["Pakistan"],
  "PLN": ["Poland"],
  "PYG": ["Paraguay"],
  "QAR": ["Qatar"],
  "RON": ["Romania"],
  "RSD": ["Serbia"],
  "RUB": ["Russia"],
  "RWF": ["Rwanda"],
  "SAR": ["Saudi Arabia"],
  "SBD": ["Solomon Islands"],
  "SCR": ["Seychelles"],
  "SDG": ["Sudan"],
  "SEK": ["Sweden"],
  "SGD": ["Singapore"],
  "SHP": ["Saint Helena"],
  "SLE": ["Sierra Leone"],
  "SLL": ["Sierra Leone"],
  "SOS": ["Somalia"],
  "SRD": ["Suriname"],
  "SSP": ["South Sudan"],
  "STN": ["São Tomé and Príncipe"],
  "SYP": ["Syria"],
  "SZL": ["Eswatini"],
  "THB": ["Thailand"],
  "TJS": ["Tajikistan"],
  "TMT": ["Turkmenistan"],
  "TND": ["Tunisia"],
  "TOP": ["Tonga"],
  "TRY": ["Turkey"],
  "TTD": ["Trinidad and Tobago"],
  "TVD": ["Tuvalu"],
  "TWD": ["Taiwan"],
  "TZS": ["Tanzania"],
  "UAH": ["Ukraine"],
  "UGX": ["Uganda"],
  "USD": ["United States", "Puerto Rico"],
  "UYU": ["Uruguay"],
  "UZS": ["Uzbekistan"],
  "VES": ["Venezuela"],
  "VND": ["Vietnam"],
  "VUV": ["Vanuatu"],
  "WST": ["Samoa"],
  "XAF": ["Cameroon", "Central African Republic", "Chad", "Congo", "Gabon", "Equatorial Guinea"],
  "XCD": ["Saint Kitts and Nevis"],
  "XDR": ["International Monetary Fund"],
  "XPF": ["French Polynesia", "New Caledonia", "Wallis and Futuna"],
  "YER": ["Yemen"],
  "ZAR": ["South Africa"],
  "ZMW": ["Zambia"],
  "ZWL": ["Zimbabwe"]
}
const GeoLocation = () => {
  const [country, setCountry] = useState('');
  const [country3, setCountry3] = useState('');
  const [ip, setIp] = useState('');
  const [name, setName] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');



  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await axios.get('/geo'); 
        const location = response.data[0]; 
        setCountry(location.country);
        setCountry3(location.country_3);
        setIp(location.ip);
        setName(location.name);

        let foundCurrency = '';
        for (const [currencyCode, countries] of Object.entries(currency)) {
          if (countries.includes(location.name)) {
            foundCurrency = currencyCode;
            break;
          }
        }
        setCurrencyValue(foundCurrency || 'Devise non trouvée');

      } catch (err) {
        if (err.response) {
          console.log(err.response.data.message || 'Erreur inconnue');
        } else if (err.request) {
          console.log('Pas de réponse du serveur.');
        } else {
          console.log('Erreur de connexion. Vérifiez votre réseau.');
        }
      }
    };

    fetchGeoData();
  }, []);

 

  return (
    <div style={{ margin: 300 }}>
      <div>
        <p>Pays: {name}</p>
        <p>Code pays (2 lettres): {country}</p>
        <p>Code pays (3 lettres): {country3}</p>
        <p>Adresse IP: {ip}</p>
        <p>Devise: {currencyValue}</p> 
      </div>
    </div>
  );
};

export default GeoLocation;
