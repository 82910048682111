import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../services/auth/authService';
import { useDispatch, useSelector } from 'react-redux';
import Verify from '../verfiy/Verify';
import { setEmail } from '../../../store/actions';

const Login = () => {
  const dispatch = useDispatch();

  
  const [rememberMe, setRememberMe] = useState(false)
  const currentPath = useSelector((state) => state.packReducer.currentPath)
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const erreur = useSelector((state) => state.__ct.message)
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: ''
});



  const storedEmail = localStorage.getItem('emailRedux');
  console.log('qqqqqqqqqqqqqqqqqqqqqqq,', storedEmail)

  useEffect(() => {

    if (storedEmail) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: storedEmail
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
     // Validation simple
     if (value.trim() === '') {
      setFormErrors({
          ...formErrors,
          [name]: 'Ce champ est obligatoire'
      });
  } else {
      setFormErrors({
          ...formErrors,
          [name]: ''
      });
  }
  };

  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation pour formData
    let formIsValid = true;
    const newErrors = {};

    Object.keys(formData).forEach((field) => {
        if (formData[field].trim() === '') {
            newErrors[field] = 'Ce champ est obligatoire';
            formIsValid = false;
        }
    });

    setFormErrors(newErrors);  

    if(formIsValid){
    console.log('aaaaaaaaaaaaaaa',formData)

    dispatch(setEmail(formData.email));
    try {

      registerComponent(formData, navigate).then(() => {

        /*  navigate('/');
         window.location.reload(); */
      })
    } catch (error) {
      console.error("Erreur lors de la connexion", error);
    };}
  };

  const registerComponent = (credentials, navigate) => {
    dispatch(login(credentials, navigate))
      .then((result) => {
        if(currentPath != null){
          navigate('/connected-protector-beneficiary-account');
          window.location.reload();
        }else{
          navigate('/');
          window.location.reload();
        }
       

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }



  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  const [isActive, setIsActive] = useState(false)
  const handleActive = () => {
    setIsActive(true)
  }


  return (
    <div className='marginbottom104'>
      <section className="login-form d-flex align-items-center mb-5">
        <div className="container" style={{ marginBottom: 75 }}>
          <div className="login-form-title text-center">
            <figure className="login-page-logo"></figure>
            <p style={{ fontSize: 40, fontWeight: 'bold', lineHeight: '48px' }}>Bienvenue cher Protecteur !</p>
            <p style={{ fontSize: 19, color: 'rgb(102, 102, 102)' }}>Veuillez vous connecter.</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="login-form-box">
                <div className="login-card">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input

                        className={`input-field form-control ${formErrors.email ? "is-invalid" : ""}`}
                        name="email"
                        value={formData.email}
                        type="email"
                        id="exampleInputEmail1"
                        placeholder="Email"

                        onChange={handleChange}
                      />
                       {formErrors.email && <small className="text-danger">Veuillez saisir votre email</small>}
                    </div>

                    <div className="form-group">
                      <input
                        className={`input-field form-control ${formErrors.password ? "is-invalid" : ""}`}
                        type={showPassword ? "text" : "password"}
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {formErrors.password && <small className="text-danger">Veuillez saisir votre mot de passe</small>}
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="showPasswordCheckbox"
                        checked={showPassword}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="showPasswordCheckbox"
                      >
                        Afficher le contenu du mot de passe
                      </label>
                    </div>
                    {erreur === "votre compte n est pas verifie" ? (
                      <>
                        <p style={{ color: "red", textAlign: "center" }}>
                          {erreur}{" "}
                          <span
                           /*  onClick={handleActive} */
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                          >
                            Verifie ici
                          </span>
                        </p>

                        {isActive ? (
                          <div
                            style={{
                              position: "fixed",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              zIndex: 9999,
                            }}
                          >
                            <Verify />
                          </div>
                        ) : null}
                      </>
                    ) : erreur === "votre email est incorrect" ? (
                      <p
                        style={{
                          color: "#721c24",
                          textAlign: "center",
                          backgroundColor: "#f8d7da",
                          padding: 10,
                        }}
                      >
                        vos identifiants sont incorrects
                      </p>
                    ) : erreur === "votre mot de passe est incorrect" ? (
                      <p
                        style={{
                          color: "#721c24",
                          textAlign: "center",
                          backgroundColor: "#f8d7da",
                          padding: 10,
                        }}
                      >
                        {erreur}
                      </p>
                    ) : erreur != "" ?(
                      <p
                        style={{
                          color: "#721c24",
                          textAlign: "center",
                          backgroundColor: "#f8d7da",
                          padding: 10,
                        }}
                      >
                        {erreur}
                      </p>):null}

                    <button
                      type="submit"  // changez de "submit" à "button"
                      className="btn btn-primary hover-effect w-100"
                    >
                      Se connecter
                    </button>


                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <label className="font-weight-normal mb-0">
                        <input
                          className="checkbox"
                          type="checkbox"
                          name="userRememberMe"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                        Souvenez-vous de moi
                      </label>
                      <a href="#" className="forgot-password" style={{fontSize:16}}>
                        Mot de passe perdu ?
                      </a>
                    </div>
                  </form>
                </div>

                <div className="join-now-outer text-center mt-4">
                  <Link
                    to={"/register"}
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      Nouveau sur Wemaza ?
                    </span>
                    <> </>
                    <span style={{ color: "var(--e-global-color-accent)" }}>
                      créez un compte
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default Login;
