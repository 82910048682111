import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Link, useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SousCardcart from '../../components/SousCardcart';
import CategoryMenu from '../../components/CategoryMenu';
import CategoriesPage from '../../components/CategoriesPage';
import axios from 'axios';
import CategoryList from '../../components/CategoryList';
import { useDispatch, useSelector } from 'react-redux';
import ProductService from '../../../services/product';
import AuthManager from '../../components/AuthManager';
import { faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import { activer, desactiver, desactiverpanier, setSymbol, UpdateCurrencyCountry } from '../../../store/actions';
import { faHome, faSignOutAlt, faSignInAlt, faGlobe, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { Padding } from '@mui/icons-material';



const Header = () => {
    const currentPathRedux = useSelector(state => state.packReducer.currencyCurrentPath)
    console.log('currentPathRedux',currentPathRedux)
    const cartItems = useSelector((state) => state.__ct.quantity)
    const isActiveState = useSelector((state) => state.__ct.isActive)
    const navigate = useNavigate()
    const handlehome = () => {
        navigate('/')
    }
    const [currentBoolean, setCurrentBoolean] = useState(true)
    const currentPath = 'test1'

    useEffect(() => {
        if (currentPathRedux === currentPath ) {
            setCurrentBoolean(false);
        }else{setCurrentBoolean(true);}
    },[currentPathRedux])

    const dispacth = useDispatch();


    const handleclick = () => {
        isActiveState ?
            dispacth(desactiver())
            :
            dispacth(activer())
    }

    const active = useSelector((state) => state.__ct.Active)

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const storeduserInfo = localStorage.getItem('userInfo');
    console.log('storeduserInfo aaaaaaa', storeduserInfo);
    const parsedUserInfo = JSON.parse(storeduserInfo);
    console.log('parsedUserInfo', parsedUserInfo)
    // URL de base de ton serveur
    const baseUrl = "https://api.wemaza.com/";

    // URL complète de l'image
    const avatarUrl = parsedUserInfo != null ? baseUrl + parsedUserInfo.avatar : null
    const [showText, setShowText] = useState(false);

    // Fonction pour montrer le texte lorsque la souris entre dans l'élément
    const handleMouseEnter_ = () => {
       if(currentBoolean )setShowText(true);
    };

    // Fonction pour cacher le texte lorsque la souris quitte l'élément
    const handleMouseLeave_ = () => {
        setShowText(false);
    };
    const savedLocation = localStorage.getItem('currencyCode');
    console.log('savedLocation', savedLocation)
    const savedSymbol = localStorage.getItem('currencySymbol');
    console.log('savedLocation', savedSymbol)
    const currency = {
        "EUR": { symbol: "€" },
        "CAD": { symbol: "$" },
        "GBP": { symbol: "£" },
        "USD": { symbol: "$" },
    };
    const [formData, setFormData] = useState({
        location: savedLocation || "EUR",  // Valeur par défaut si savedLocation est vide
        symbol: savedSymbol || "€"         // Valeur par défaut si savedSymbol est vide
    });
    useEffect(() => {

        formData.location && localStorage.setItem('currencyCode', formData.location);
        formData.symbol && localStorage.setItem('currencySymbol', formData.symbol);
        dispacth(setSymbol(formData.symbol));
        dispacth(UpdateCurrencyCountry(formData.location));
    }, [formData.location, formData.symbol])

    console.log('savedLocation22', formData)

    const handleCurrencyChange = (e) => {
        const [selectedLocation, selectedSymbol] = e.target.value.split(" -(");
        setFormData({
            location: selectedLocation,
            symbol: selectedSymbol.replace(")", "") // Nettoyage des parenthèses
        });

    };
    // const handleChangeService = (e) => {
    //     const selectedValue = e.target.value.split(' -')[0]; // Extraire le code de la devise
    //     const selectedSymbol = e.target.value.split(' -(')[1].replace(')', ''); // Extraire le symbole de la devise
    //     setFormData({ location: selectedValue, symbol: selectedSymbol });
    //     // Sauvegarder la devise et le symbole dans le localStorage
    //     localStorage.setItem('currencyCode', selectedValue);
    //     localStorage.setItem('currencySymbol', selectedSymbol);

    // }

    return (
        <>
            <div className="header-top header-top1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12 d-none d-sm-block">
                            <div className="header-left d-table-cell align-middle">
                                <div className="phone-icon d-inline-block">
                                    <i className="fa-solid fa-phone-volume" />
                                </div>
                                <p className="free-consultation-text">
                                    Besoin d'aide ? Contactez-nous gratuitement :
                                    <a href="tel:+123456789" className="text-decoration-none">0800 123 45 67 890</a>
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="header-right">
                                <ul className="list-unstyled d-flex justify-content-center justify-content-md-end align-items-center">
                                    <li className="d-inline-block">
                                        <i className="fa-sharp fa-solid fa-envelope" />
                                        <a className="d-inline-block email-span text-decoration-none" href="mailto:info@sencare.com">info@sencare.com</a>
                                    </li>
                                    <li className="d-inline-block ml-3">
                                        <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#4267B2' }} />
                                    </li>
                                    <li className="d-inline-block ml-4">
                                        <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{ color: '#25D366' }} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <header className="header paddingheader" style={{ background: "white" }}>
                <div className="main-header" style={{ backgroundColor: "white" }}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light p-0" style={{ height: '70px', display: 'flex', alignItems: 'center' }}>

                            <Link className="navbar-brand mr-0" to={"/"}>
                                <img src="/assets/images/logo1.png" alt="Logo" className="imglogo" width={"100%"} />
                            </Link>
                            <div className='headerwitdh'>
                                <Link to={'/profil'} className='nonedispaly' style={{ marginRight: 10 }}>
                                    {
                                        parsedUserInfo != null ?

                                            (<img src={avatarUrl} className='imageProfil3' />) : (<img src="./assets/images/noprofil.jpg" width={100} height={100} style={{ display: 'none' }} />)

                                    }
                                </Link>

                                <button
                                    className={`navbar-toggler ${isMenuOpen ? '' : 'collapsed'}`}
                                    type="button"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded={isMenuOpen ? 'true' : 'false'}
                                    aria-label="Toggle navigation"
                                    onClick={toggleMenu}
                                    style={{ width: 48, height: 40 }}
                                >
                                    <span className="navbar-toggler-icon navbar-toggler-icon2" />
                                    <span className="navbar-toggler-icon navbar-toggler-icon2" />
                                    <span className="navbar-toggler-icon navbar-toggler-icon2" />
                                </button>
                            </div>
                            <div
                                style={{ borderRadius: '10px', width: 200 }}
                                className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item sancare-li-color active">
                                        <Link
                                            to={'/'}
                                            style={{ color: 'black', textAlign: 'left' }}
                                            onClick={handlehome}
                                            className="nav-link active"
                                            id="navbarDropdown"
                                            role="button"
                                            /* data-toggle="dropdown" */
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon icon={faHome} style={{ marginRight: 10, marginLeft: 10 }} />
                                            Accueil

                                        </Link>
                                    </li>
                                    <li className="nav-item sancare-li-color active">
                                        <div

                                            style={{ color: 'black', textAlign: 'left', position: 'relative' }}
                                            className="nav-link active"
                                            id="navbarDropdown"
                                            role="button"
                                            /* data-toggle="dropdown" */
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onMouseEnter={handleMouseEnter_}
                                            onMouseLeave={handleMouseLeave_}
                                        >
                                         
                                            <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginRight: 10, marginLeft: 10 }} />
                                            {formData.location}-({formData.symbol}) 

                                            

                                            {showText && (
                                                <div className='positionDescription1'
                                                    style={{
                                                        position: 'absolute',
                                                        top: '40px',
                                                        left: '100px',
                                                        transform: 'translateX(-50%)',
                                                        width: 'auto',
                                                        padding: 10,
                                                        fontSize: 16,
                                                        backgroundColor: 'white',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                        zIndex: 2000, // Assurer que cette div est au-dessus des autres éléments
                                                        textAlign: 'left'
                                                    }}
                                                > <div className="triangle"></div>
                                                    <p style={{ fontWeight: 'bold', fontSize: 15, textTransform: 'none', textAlign: 'center', paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 5 }}>Sélectionnez une devise</p>

                                                    <p style={{ fontSize: 14, textTransform: 'none', textAlign: 'center', lineHeight: '18px', padding: 5 }}>
                                                        Sélectionnez la devise de votre choix. Vous pouvez mettre à jour les paramètres à tout moment.
                                                    </p>

                                                    <select
                                                        className="form-select form-select-lg mb-3 form-control"
                                                        aria-label=".form-select-lg example"
                                                        style={{ height: 55, borderRadius: 5, background: 'transparent', padding: 0 }}
                                                        id="location"
                                                        name="location"
                                                        value={`${formData.location} -(${formData.symbol})`}
                                                        onChange={handleCurrencyChange}
                                                    >
                                                        {Object.keys(currency).map((key) => (
                                                            <option key={key} value={`${key} -(${currency[key].symbol})`}>
                                                                {key} - ({currency[key].symbol})
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                        </div>

                                    </li>


                                    {/* <li className="nav-item dropdown sancare-li-color">
                                        <div className="dropdown-menu sancare-drop-down sencare-drop-down2">
                                            <ul className="list-unstyled">
                                                <li className="nav-item">
                                                    <a className="dropdown-item nav-link" href="#">
                                                        Comming Soon
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="dropdown-item nav-link" href="#">
                                                        404 Page
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> */}
                                    <AuthManager>
                                        {(isLoggedIn, handleLogout) => (
                                            <ul className="navbar-nav ml-auto">
                                                {isLoggedIn ? (
                                                    <ul className='dflexcolum'>
                                                        <li>
                                                            <Link
                                                                to={'/'}
                                                                className="nav-link"
                                                                onClick={handleLogout}
                                                                style={{ textAlign: 'left' }}
                                                            >
                                                                <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 10, marginLeft: 10 }} />
                                                                Déconnexion

                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/profil'} className='displaynone1'>
                                                                {
                                                                    parsedUserInfo != null ? (<img src={avatarUrl} className='imageProfil3' />) : (<img src="./assets/images/noprofil.jpg" width={100} height={100} alt />)
                                                                }
                                                            </Link>
                                                            {/* <div
                                                                style={{ display: 'inline-block', marginRight: '20px' }}
                                                            >
                                                                {active !== false ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faShoppingCart}
                                                                        size="2x"
                                                                        style={{ color: '#4CAF50', cursor: 'pointer' }}
                                                                        onClick={handleclick}
                                                                    />
                                                                ) : null}
                                                                {cartItems > 0 && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '12px',
                                                                            color: 'red',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {cartItems}
                                                                    </span>
                                                                )}
                                                            </div> */}
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    <ul className='dflexcolum'>
                                                        {/*                                                         
                                                        <li className="nav-item list-unstyled btn-talk nav-btn2">
                                                            <Link to={'/login'} className="nav-link ">
                                                            
                                                            <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: 10, marginLeft: 10 }} />Connexion
                                                            </Link>




                                                        </li> */}
                                                        <li className='nav-item list-unstyled btn-talk nav-btn2'>
                                                            <Link
                                                                to={'/login'}
                                                                className="nav-link"
                                                                // onClick={handleLogout}
                                                                style={{ textAlign: 'left' }}
                                                            >
                                                                <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: 10, marginLeft: 10 }} />Connexion
                                                            </Link>
                                                        </li>






                                                        {/* <li>
                                                            <div
                                                                style={{ display: 'inline-block', marginRight: '20px' }}
                                                            >
                                                                {active !== false ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faShoppingCart}
                                                                        size="2x"
                                                                        style={{ color: '#4CAF50', cursor: 'pointer' }}
                                                                        onClick={handleclick}
                                                                    />
                                                                ) : null}
                                                                {cartItems > 0 && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '12px',
                                                                            color: 'red',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {cartItems}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                )}
                                            </ul>
                                        )}
                                    </AuthManager>
                                </ul>
                            </div>

                        </nav>

                    </div>
                </div >
            </header >
        </>
    );
};

export default Header;

