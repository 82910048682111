import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Bouton from '../../components/Bouton';
import AuthManager from '../../components/AuthManager';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Parametre from '../../components/Parametre';
import { setTimeRedux } from '../../../store/actions';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const IntermediaryPersonnalise = () => {
    const itemsReducer = useSelector((state) => state.__ct.items);
    const itemsStored = JSON.parse(localStorage.getItem('itemsstored'));
    console.log('itemsReducer oooooooooooooooooooooooo', itemsReducer);
    console.log('itmsStored tttttttttttttttttttttttt', itemsStored);

    const items = itemsReducer && itemsReducer.length > 0 ? itemsReducer : itemsStored;



    /* useEffect(() => {
       if (itemsReducer !== null) {     
           localStorage.setItem('itemsstored', JSON.stringify(itemsReducer));
       }
     }, [itemsReducer]);
 */


    const dispatch = useDispatch();
    const [time, setTime] = useState(useSelector((state) => state.__ct.timeRedux));
    const handleTimeChange = (event) => {

        const selectedTime = event.target.value; // Récupérer la nouvelle valeur sélectionnée
        setTime(selectedTime); // Mettre à jour l'état avec la nouvelle valeur
        dispatch(setTimeRedux(selectedTime));
    };
    const subTotalAnnual = items.reduce((total, pack) => {
        const price = pack.price.annual;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    const subTotalmonthly = items.reduce((total, pack) => {
        const price = pack.price.monthly;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);





    const theme = useTheme();

    // Breakpoints for responsiveness
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    return (
        <div style={{ backgroundColor: "#F1F2F4 !important" }}>
            <div style={{ marginBottom: 200, marginTop: 25, borderRadius: 15 }} className='d-flex flex-wrap justify-content-center'>
                <div className='mb-4 mydiv' style={{ borderRadius: 10, height: 439, background: 'white', boxShadow: " 0px 0px 15px #AEB1BE33" }}>
                    {/*  <div className='statisfait'> Satisfait ou <br/>remboursé 30J</div> */}
                    {/* <div>
                    <p style={{ fontSize: 23, fontWeight: 'bold' }}>1 - verifié votre offre choisi </p>
                    <p style={{ fontSize: 15, marginTop: 10, marginBottom: 20, color: 'rgb(102, 102, 102)' }}>Choisissez votre type d'offre:</p>
                    <div class="form-group">
                        {/* <label for="exampleFormControlSelect1">Example select</label> */}
                    {/* <select class="form-control" id="exampleFormControlSelect1" style={{ height: 50 }}>
                            <option >1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>  */}
                    <div>
                        <p style={{ fontSize: 23, fontWeight: 'bold' }} className='mb-4'>1 - Choisissez votre durée</p>
                        <FormControl style={{ minWidth: isSmallScreen ? "300px" : isMediumScreen ? "500px" : "725px" }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={time} onChange={handleTimeChange}
                            >
                                <div className='d-flex align-items-center justify-content-between divblue mb-4' style={{ height: 95 }}>
                                    <div className="form-check">
                                        <FormControlLabel id='testToot' value="1 mois" control={<Radio />} label="1 mois" />
                                    </div>
                                    <div style={{ marginTop: 5 }}>
                                        <p style={{ textAlign: 'end', color: 'black', fontWeight: 'bold' }}>{subTotalmonthly} €<br />
                                            <span style={{ color: 'rgb(102, 102, 102)', fontSize: 14, fontWeight: 'normal' }}> Prix renouvellement : {subTotalmonthly}€/mois</span></p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between divblue mb-4' style={{ height: 95 }}>
                                    <div className="form-check">
                                        <FormControlLabel id='testToot' value="1 an" control={<Radio />} label="1 an" />
                                    </div>
                                    <div>
                                        <p style={{ textAlign: 'end', color: 'black', fontWeight: 'bold' }}>{subTotalAnnual} €<br />
                                            <span style={{ color: 'rgb(102, 102, 102)', fontSize: 14, fontWeight: 'normal' }}> Prix renouvellement :{subTotalAnnual}€/mois</span></p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between divblue mb-4' style={{ height: 95 }}>
                                    <div className="form-check">
                                        <FormControlLabel id='testToot' value="parainnage" control={<Radio />} label="Parrainage" />
                                    </div>
                                    <div>
                                        <p style={{ textAlign: 'end', color: 'black', fontWeight: 'bold' }}>0,00 €<br />
                                            <span style={{ color: 'rgb(102, 102, 102)', fontSize: 14, fontWeight: 'normal' }}> Prix renouvellement : 0,00€/mois</span></p>
                                    </div>
                                </div>
                            </RadioGroup>
                        </FormControl>


                    </div>


                </div>
                {/* tableau des offres  */}
                <div className='mydiv mt-1'>
                    <div style={{ width: 400, height: 'auto', background: 'white' }}>
                        <div>
                            <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                            </div>
                            <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }} >

                                {
                                    items.length > 0 && items.map((offre, index) => (
                                        <Parametre key={index} offre={offre} time={time} />
                                    ))
                                }

                                <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                <div >
                                    <div className='d-flex justify-content-between'>
                                        <p style={{ fontSize: 15 }}>Total HT</p>
                                        {/* <p style={{ fontWeight: 'bold' }}>46€</p> */}
                                        {time == '1 an' ? (<p style={{ fontWeight: 'bold' }}>{subTotalAnnual} €</p>) : time == "1 mois" ? (<p style={{ fontWeight: 'bold' }}>{subTotalmonthly} €</p>) : null}
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} />      </p>
                                        <p style={{ fontWeight: 'bold' }}>0 €</p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC </p>
                                        {/* <p style={{ fontWeight: 'bold', color: '#00A74D' }}>46€</p> */}
                                        {time == '1 an' ? (<p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalAnnual} €</p>) : time == "1 mois" ? (<p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalmonthly} €</p>) : null}
                                    </div>
                                </div>
                                <AuthManager>
                                    {(isLoggedIn, handleLogout) => (



                                        <div>  {
                                            isLoggedIn ? (<Link to={'/connected-protector-beneficiary-account'}>     <div style={{ textAlign: 'center' }}>  <button style={{ width: '100%', fontSize: 17, height: 50, fontWeight: "bold" }} className='btn btn-success' >Continuer ma commande</button></div></Link>) :
                                                (<Link to={'/linked-protector-beneficiary-account'}>    <div style={{ textAlign: 'center' }}>  <button style={{ width: '100%', fontSize: 17, height: 50, fontWeight: "bold" }} className='btn btn-success' >Continuer ma commande<ArrowCircleRightOutlinedIcon style={{ marginLeft: 4 }}></ArrowCircleRightOutlinedIcon></button></div></Link>)

                                        }
                                        </div>
                                    )}
                                </AuthManager>
                                <p style={{ color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: 10, textDecoration: 'underline' }}> Vous avez un code promo</p>


                            </div>
                        </div>

                    </div>
                    <div style={{ background: 'white', borderRadius: '10px', marginTop: 10, paddingBottom: 10, paddingTop: 10 }}>
                        <div className='d-flex' >
                            <img src="/assets/images/30.png" alt="Logo" style={imagestyle} />
                            <p>Satisfait ou remboursé 30 jours</p>
                        </div>
                        <div className='d-flex' >
                            <img src="/assets/images/31.png" alt="Logo" style={imagestyle} />
                            <p>Support client français 7J/7</p>
                        </div>
                        <div className='d-flex' >
                            <img src="/assets/images/32.png" alt="Logo" style={imagestyle} />
                            <p>Paiement 100% sécurisé SSL</p>
                        </div>
                        <div className='d-flex mb-3'>
                            <img src="/assets/images/34.webp" alt="Logo" style={imagepaiement} />
                            <img src="/assets/images/35.webp" alt="Logo" style={imagepaiement} />
                            <img src="/assets/images/36.webp" alt="Logo" style={imagepaiement} />
                            <img src="/assets/images/37.webp" alt="Logo" style={imagepaiement} />
                            <img src="/assets/images/33.webp" alt="Logo" style={imagepaiement} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntermediaryPersonnalise;
const imageinfo = {
    height: 16,
    cursor: 'pointer',
    marginLeft: 1
}

const imagestyle = {
    marginTop: 7,
    marginLeft: 10,

    height: 16,
    marginRight: 10
}
const imagepaiement = {
    marginTop: 5,
    marginLeft: 10,

    height: 30,
    marginRight: 5
}