import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart,removeFromCart } from '../../store/actions';




const SousCardcart = ({product}) => {
 

    const dispatch = useDispatch();
   

    

    const handleRemoveFromCart = () => {
        //dispatch(clearCart());
       dispatch(removeFromCart(product.id));
       // dispatch(decrementQuantity()) ;// Envoie l'action pour retirer le produit
    };
    return (
        <div className="cart-item-1">
        <div className="img-name">
            <div className="thumbanil">
                <img src={product.image} alt />
            </div>
            <div className="details">
            <Link to={`/8/${product.id}`} key={product.real_id}>
                    <h5 className="title">{product.name}
                    </h5>
                </Link>
                <div className="number">
                    1 <i className="fa-regular fa-x" />
                    <span>${product.price}</span>
                </div>
            </div>
        </div>
        <div className="close-c1" onClick={()=>{handleRemoveFromCart()}}>
            <i className="fa-regular fa-x" />
        </div>
    </div>
    );
};

export default SousCardcart;


