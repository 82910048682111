import React, { useState } from 'react';

const Paiement = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const storeduserInfo = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(storeduserInfo);
   
    const nameUserinfo= userInfo.name
    console.log("userInfo tttttttttttt",nameUserinfo)

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div data-aos="fade-up" data-aos-duration={600} style={{ marginBottom: "50px" }}>
            <section className="login-form d-flex  justify-content-start align-items-center " >


                <div className='container paiement_page d-flex flex-wrap pb-5' >
                    {/* Section principale - Mode de paiement */}
                    <div className="col-12 col-lg-6 p-4" style={{ background: "rgba(56,115,184,0.1)",position:"relative", borderRadius: 10, border: "1px solid" }}>
                        <div style={{position:"absolute", top:'-80px'}} >
                            <div style={{ fontSize: '1rem' }}>Salut, {nameUserinfo}</div>
                            <div style={{ fontWeight: "bold", fontSize: "1.5rem", paddingBottom: "10px" }}>Choisissez un mode de paiement</div>
                        </div>
                        <div className='mb-5' style={{ padding: '1rem 3rem 0rem' }}>


                            {/* Option 1 - Paiement direct */}
                            <div className="form-check m-3">
                                <label className="form-check-label p-1" htmlFor="radio1" style={{cursor:'pointer'}}>Paiement direct</label>
                                <div className="d-flex align-items-center justify-content-between" style={radioStyle}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentMode"
                                        id="radio1"
                                        value="paiementDirect"
                                        checked={selectedValue === 'paiementDirect'}
                                        onChange={handleRadioChange}
                                    />
                                    <div style={{ minWidth: '50%', maxWidth: '70%' }}></div>
                                    <img src="./assets/images/paie.png" style={imageStyle} alt="Image de paiement" />
                                </div>
                            </div>

                            {/* Option 2 - Paiement par abonnement */}
                            <div className="form-check m-3">
                                <label className="form-check-label p-1" htmlFor="radio2"style={{cursor:'pointer'}}>Paiement par abonnement</label>
                                <div className="d-flex align-items-center justify-content-between" style={radioStyle}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentMode"
                                        id="radio2"
                                        value="paiementAbonnement"
                                        checked={selectedValue === 'paiementAbonnement'}
                                        onChange={handleRadioChange}
                                    />
                                    <div style={{ minWidth: '50%', maxWidth: '70%' }}></div>
                                    <img src="./assets/images/paie.png" style={imageStyle} alt="Image de paiement" />
                                </div>
                            </div>

                            {/* Option 3 - Parainage */}
                            <div className="form-check m-3">
                                <label className="form-check-label p-1" htmlFor="radio3"style={{cursor:'pointer'}}>Parainage</label>
                                <div className="d-flex align-items-center justify-content-between" style={radioStyle}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentMode"
                                        id="radio3"
                                        value="autre"
                                        checked={selectedValue === 'autre'}
                                        onChange={handleRadioChange}
                                    />
                                    <div style={{ minWidth: '50%', maxWidth: '70%' }}></div>
                                    <img src="./assets/images/paie.png" style={imageStyle} alt="Image de paiement" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Section - Résumé des articles */}
                    <div className="col-12 col-lg-6 p-4">
                        <table style={{ border: '1px solid #000', minHeight: "auto", borderRadius: 10, background: "rgba(56,115,184,0.1)", borderCollapse: 'initial', width: '65%' }}>
                            <tbody>
                                <tr>
                                    <td className='p-3'>Article</td>
                                    <td className='p-3'>50$</td>
                                </tr>
                                <tr>
                                    <td className='p-3'>Article 2</td>
                                    <td className='p-3'>60$</td>
                                </tr>
                                <tr>
                                    <td className='p-3'>Article 3</td>
                                    <td className='p-3'>15$</td>
                                </tr>
                                <tr>
                                    <td className='p-3'>Article 4</td>
                                    <td className='p-3'>15$</td>
                                </tr>
                                <tr style={{ textAlign: 'center' }}>
                                    <td colSpan="2">
                                        <button className='btn btn-success' style={{ margin: '10px' }}>
                                            Choisir un mode de paiement
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
};

// Styles pour les options de paiement et les images
const radioStyle = {
    width: '100%',
    height: '55px',
    backgroundColor: 'white',
    borderRadius: 10,
    border: '1px solid',
    paddingLeft:'50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const imageStyle = {
    width: '300px',
    height: '45px',
    marginRight:'30px',
};

export default Paiement;
