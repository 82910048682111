import React, { useState } from "react";

const AvatarUpload = () => {
    const [avatars, setAvatars] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [fileNames, setFileNames] = useState([]); // Nouvel état pour les noms de fichiers

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);

        // Préparer les aperçus des fichiers
        const filePreviews = files.map((file) => {
            if (file.type === "application/pdf") {
                return "./assets/images/pdf.png";
            } else if (file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                return "./assets/images/word.png";
            } else if (file.type.startsWith("image/")) {
                return URL.createObjectURL(file);
            } else {
                return null;
            }
        }).filter(Boolean);

        // Ajouter les nouveaux aperçus à l'existant
        setPreviews((prev) => [...prev, ...filePreviews]);

        // Lire les fichiers
        const fileReaders = files.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        });

        // Ajouter les noms des fichiers
        const newFileNames = files.map(file => file.name); // Stocker les noms des fichiers
        setFileNames((prev) => [...prev, ...newFileNames]);

        Promise.all(fileReaders).then((results) => {
            setAvatars((prev) => [...prev, ...results]);
        });

        // Réinitialiser la valeur de l'input
        event.target.value = ""; // Réinitialisation ici
    };

    const handleRemovePreview = (index) => {
        // Supprimer l'élément à l'index donné
        const newPreviews = previews.filter((_, i) => i !== index);
        const newAvatars = avatars.filter((_, i) => i !== index);
        const newFileNames = fileNames.filter((_, i) => i !== index); // Supprimer le nom de fichier correspondant
        setPreviews(newPreviews);
        setAvatars(newAvatars);
        setFileNames(newFileNames); // Mettre à jour les noms des fichiers
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (avatars.length > 0) {
            const data = { avatars };
            console.log("Données à envoyer à l'API : ", data);
        }
    };

    const buttonStyle = {
        position: 'relative',
        padding: '11px 16px',
        fontSize: '15px',
        lineHeight: '1.5',
        borderRadius: '3px',
        color: '#fff',
        backgroundColor: '#ffc100',
        border: '0',
        transition: '0.2s',
        overflow: 'hidden',
        width: '300px'
    };

    const fileInputStyle = {
        cursor: 'pointer',
        position: 'absolute',
        left: '0%',
        top: '0%',
        transform: 'scale(3)',
        opacity: 0,
    };

    const handleMouseEnter = (e) => {
        e.target.style.backgroundColor = '#d9a400';
    };

    const handleMouseLeave = (e) => {
        e.target.style.backgroundColor = '#ffc100';
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <p>telecharger vos documents </p>
            <form onSubmit={handleSubmit}>
                <div className="upload">
                    <button
                        type="button"
                        style={buttonStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <i className="fa fa-upload" /> Upload Files
                        <input
                            type="file"
                            style={fileInputStyle}
                            multiple
                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={handleFileChange}
                        />
                    </button>
                </div>

                {previews.length > 0 && (
                    <div>
                        <p>Aperçu des fichiers :</p>
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                            {previews.map((preview, index) => (
                                <div key={index} style={{ position: 'relative', display: 'inline-block', width: 150, height: 200, margin:10 }}>
                                    <img src={preview} alt={`Aperçu ${index}`} className="imageServiceCall" style={{}} />
                                    <p style={{ width: 150, fontSize: 13, wordWrap: 'break-word',lineHeight:'normal' }}>
                                        {fileNames[index]}
                                    </p>
                                    {/* Affichage du nom du fichier */}
                                    <button
                                        onClick={() => handleRemovePreview(index)}
                                        style={{
                                            position: 'absolute',
                                            top: '-16px',
                                            right: '-18px',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            color: 'red',
                                            fontSize: '20px',
                                            pointer:'cursor'
                                        }}
                                    >
                                        <i className="fa fa-times-circle" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <button type="submit" style={{ marginTop: '20px' }}>
                    Soumettre
                </button>
            </form>
        </div>
    );
};

export default AvatarUpload;
