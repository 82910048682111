import React, { useState } from 'react';

import styles from './register.module.css';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../../../services/auth/authService';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setEmail } from '../../../store/actions';
import Verify from '../verfiy/Verify';
import { Grid } from '@mui/material';



// --- Même QontoConnector et ColorlibConnector que dans votre code ---

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
        ...theme.applyStyles('dark', {
            borderColor: theme.palette.grey[800],
        }),
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[700],
    }),
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
};

// Les étapes du formulaire
const steps = ['Inscription protecteur', 'Inscription bénéficiaire'];

export default function Register() {
    const [isActive, setIsActive] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const dispatch = useDispatch()
    const [isVisible, setIsvisible] = useState(true)
    const [emailError, setEmailError] = useState('');

    const handleclick = () => {
        setIsvisible(true)
    }
    const handleclick_ = () => {
        setIsActive(true)
    }

    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        phone: '',
        location: '',
        role: 'protector'
    });
    const [formData_, setFormData_] = useState({
        name: '',
        sexe: '',
        id_User_protector: '',
        phone: '',
        location: '',
        role: 'beneficiary',
    });

    const handleChange = (e) => {
        /* setFormData({
            ...formData,
            [e.target.name]: e.target.value 
               
        } );
       */
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name === 'email') {
            dispatch(setEmail(value));
        }
    };
    const handleChange_ = (e) => {
        setFormData_({
            ...formData_,
            [e.target.name]: e.target.value
        });
    };
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière pour valider un email
        return regex.test(email);
    };
    const isFormValid = () => {
        return formData.name && formData.password && formData.email && formData.phone && formData.location;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid()) {
            console.log("Submitting form");
            console.log("Dispatching register action with credentials:", formData);
            console.log("Dispatching register action with credentials:", formData_);

            dispatch(register(formData, formData_)).then(() => { setIsActive(true) });

        };
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);  // Bascule entre "masqué" et "visible"
    };
    const [aide, setAide] = useState(false)
    const handlehelperText = () => { setAide(true) }





    // Formulaire d'inscription du protecteur
    const renderProtectorForm = () => (
        <div  >
            <TextField
                label="Nom"
                name="name"
                variant="outlined"
                required
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleChange}
            />
            <TextField
                label="Mot de passe"
                name="password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                required
                fullWidth
                margin="normal"
                value={formData.password}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            onClick={handleClickShowPassword} style={{ marginTop: 6 }} // Bascule la visibilité
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    )
                }}
            />

            {/* <TextField
                label="Email"
                name="email"
                variant="outlined"
                type="email" 
                required
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                 helperText={aide?"Exemple:utilisateur@gmail.com":null}
                 onClick={handlehelperText}
            /> */}

            <TextField
                label="Email"
                name="email"
                variant="outlined"
                type="email"
                required
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                helperText={aide ? "Exemple: utilisateur@gmail.com" : emailError}
                error={Boolean(emailError)}
                onClick={handlehelperText}
            />

            <TextField
                label="Numéro"
                name="phone"
                variant="outlined"
                required
                fullWidth
                margin="normal"
                value={formData.phone}
                onChange={handleChange}
            />
            <TextField
                label="Address"
                name="location"
                variant="outlined"
                required
                fullWidth
                margin="normal"
                value={formData.location}
                onChange={handleChange}
            />

        </div>
    );

    // Formulaire d'inscription du bénéficiaire
    /*  const renderBeneficiaryForm = () => (
         <div className='beneficiary-form'>
             <Grid container spacing={2} className='beneficiary-form d-flex flex-column' style={{ width: "calc(100% + 600px)" }}>
                 <Grid item xs={12} sm={6} md={6} lg={4}>
                     <TextField
                         label="Nom"
                         variant="outlined"
                         fullWidth
                         required
                         margin="normal"
                         name="name" // Le nom doit correspondre à la clé dans formData_
                         value={formData_.name}
                         onChange={handleChange_}
                         style={{ maxWidth: 400 }} // largeur fixe de base
                     />
                 </Grid>
                 <Grid item xs={12} sm={6} md={6} lg={4}>
                     <TextField
                         label="Numéro"
                         variant="outlined"
                         fullWidth
                         required
                         margin="normal"
                         name="phone" // Le nom doit correspondre à la clé dans formData_
                         value={formData_.phone}
                         onChange={handleChange_}
                         style={{ maxWidth: 400 }} // largeur fixe de base
                     />
                 </Grid>
                 <Grid item xs={12} sm={6} md={6} lg={4}>
                     <TextField
                         label="Adresse"
                         name="location"
                         variant="outlined"
                         required
                         fullWidth
                         margin="normal"
                         value={formData_.location}
                         onChange={handleChange_}
                         style={{ maxWidth: 400 }} // largeur fixe de base
                     />
                 </Grid>
                 <Grid item xs={12}>
                     <FormControl component="fieldset" margin="normal">
                         <FormLabel component="legend">Sexe</FormLabel>
                         <RadioGroup
                             aria-label="sexe" // Utiliser le même nom que dans formData_
                             name="sexe" // Assurez-vous que le nom correspond à la clé dans formData_
                             required
                             value={formData_.sexe}
                             onChange={handleChange_} // Utilise handleChange_ pour gérer l'état
                             row
                         >
                             <FormControlLabel value="male" control={<Radio />} label="Homme" />
                             <FormControlLabel value="female" control={<Radio />} label="Femme" />
                             <FormControlLabel value="other" control={<Radio />} label="Autre" />
                         </RadioGroup>
                     </FormControl>
                 </Grid>
             </Grid>
         </div>
     ); */
    const renderBeneficiaryForm = () => (

        <div>
            <TextField
                label="Nom"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                name="name"  // Le nom doit correspondre à la clé dans formData_
                value={formData_.name}
                onChange={handleChange_}
            />
            <TextField
                label="Numéro"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                name="phone"  // Le nom doit correspondre à la clé dans formData_
                value={formData_.phone}
                onChange={handleChange_}
            />
            <TextField
                label="Address"
                name="location"
                variant="outlined"
                required
                fullWidth
                margin="normal"
                value={formData_.location}
                onChange={handleChange_}
            />
            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Sexe</FormLabel>
                <RadioGroup
                    aria-label="sexe" // Utiliser le même nom que dans formData_
                    name="sexe"  // Assurez-vous que le nom correspond à la clé dans formData_
                    required
                    value={formData_.sexe}
                    onChange={handleChange_} // Utilise handleChange_ pour gérer l'état
                    row
                >
                    <FormControlLabel value="male" control={<Radio />} label="Homme" />
                    <FormControlLabel value="female" control={<Radio />} label="Femme" />
                    <FormControlLabel value="other" control={<Radio />} label="Autre" />
                </RadioGroup>
            </FormControl>
        </div>



    );


    const renderCarte = () => (


        <div className='register_card' style={{ margin: '0 auto' }}>
            <div className={styles.sectiondiv}>
                <div className='p-5'>
                    <div className={styles.containerCredit}>
                        <div className={`${styles.card} ${styles.frontface}`}>
                            <div>
                                <span style={{ background: "transparent" }}>
                                    <p className="titrecredit">Wemaza</p>


                                </span>

                            </div>
                            <div className={styles.carddetails}>
                                <div className={styles.namenumber}>
                                    <p className={styles.CardNumber}>Card Number</p>
                                    <p className={`${styles.number} ${styles.titrecredit}`}>8050 2030 3020 5040</p>
                                    {/* Correction ici pour accéder à `titrecredit` via styles */}
                                    <p className={`${styles.name} ${styles.titrecredit}`}>Prem Kumar Shahi</p>
                                </div>
                                <div className={styles.validdate}>
                                    <p className={styles.CardNumber}>Valid Thru</p>
                                    <p className={styles.titrecredit}>05/28</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.card} ${styles.backface}`} style={{ marginTop: -50 }}>
                            <p className={styles.CardNumber}>
                                For customer service call +977 4343 3433 or email at
                                mastercard@gmail.com
                            </p>
                            <span className={styles.magneticstrip} style={{ marginTop: -50, backgroundColor: "black" }} />
                            <div className={styles.signature} style={{ marginTop: 0 }}><i >005</i></div>
                            <p className={styles.titrecredit} >
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia
                                maiores sed doloremque nesciunt neque beatae voluptatibus doloribus.
                                Libero et quis magni magnam nihil temporibus? Facere consectetur
                                dolore reiciendis et veniam.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return renderProtectorForm();
            case 1:
                return (
                    <div className='d-flex'>
                        {renderBeneficiaryForm()}
                    </div>
                );
            default:
                return 'Étape inconnue';
        }
    };



    return (

        <section className="login-form d-flex  container-fluid al" style={{ marginBottom: 150 }} >
            {isActive ? <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
                <Verify />
            </div> : null}
            <div className='d-flex flex-wrap'>
                <Stack sx={{ width: 500, display: 'flex', flexDirection: 'column', alignItems: 'center' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={activeStep} style={{ width: 800 }} connector={<QontoConnector />}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel> {/* Utilisation de ColorlibStepIcon */}
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <h3>Formulaire terminé !</h3>
                                <Link to={'/Intermediary'}><button className='btn btn-primary m-3'>Continuez pour effectuer le paiement</button></Link>
                            </div>

                        ) : (
                            <div >paiement
                                {renderStepContent(activeStep)}
                                <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 2, mr: 2 }} >
                                    Retour
                                </Button>
                                {activeStep === steps.length - 1 ?

                                    <button className='btn btn-primary m-3' onClick={handleSubmit}>
                                        TERMINER
                                    </button>


                                    :
                                    <Button variant="contained" onClick={() => { handleNext(); handleclick() }} disabled={!isFormValid()} sx={{ mt: 2 }}>
                                        Suivant
                                    </Button>
                                }
                            </div>
                        )}
                    </div>
                </Stack>
                <div style={{ marginLeft: 39, marginTop: 28 }}>
                    {isVisible ? <div className='register_card' style={{ marginBottom: 215, marginRight: 100 }}>
                        <div className={styles.sectiondiv}>
                            <div className='p-5'>
                                <div className={styles.containerCredit}>
                                    <div className={`${styles.card} ${styles.frontface}`}>
                                        <div>
                                            <span style={{ background: "transparent" }}>
                                                <p className="titrecredit">Wemaza</p>


                                            </span>

                                        </div>
                                        <div className={styles.carddetails}>
                                            <div className={styles.namenumber}>
                                                <p className={styles.CardNumber}>Card Number</p>
                                                <p className={`${styles.number} ${styles.titrecredit}`}>8050 2030 3020 5040</p>
                                                {/* Correction ici pour accéder à `titrecredit` via styles */}
                                                <p className={`${styles.name} ${styles.titrecredit}`}>Prem Kumar Shahi</p>
                                            </div>
                                            <div className={styles.validdate}>
                                                <p className={styles.CardNumber}>Valid Thru</p>
                                                <p className={styles.titrecredit}>05/28</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.card} ${styles.backface}`} style={{ marginTop: -50 }}>
                                        <p className={styles.CardNumber}>
                                            For customer service call +977 4343 3433 or email at
                                            mastercard@gmail.com
                                        </p>
                                        <span className={styles.magneticstrip} style={{ marginTop: -50, backgroundColor: "black" }} />
                                        <div className={styles.signature} style={{ marginTop: 0 }}><i >005</i></div>
                                        <p className={styles.titrecredit} >
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia
                                            maiores sed doloremque nesciunt neque beatae voluptatibus doloribus.
                                            Libero et quis magni magnam nihil temporibus? Facere consectetur
                                            dolore reiciendis et veniam.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                </div>
            </div>
        </section>
    );
}
