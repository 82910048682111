import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTitle } from '../../../store/actions';


const NewPAck = ({ pack }) => { 
    // title
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handlenavigate = () => {
        navigate('ListeService')
        dispatch(setTitle(pack.title))
    }
    const handlenavigate_ = () => {
        navigate('ListeService-santé')
        dispatch(setTitle(pack.title))
    }
    return (
        // <div className='d-flex justify-content-center' style={{marginBottom:170}}>
        <>
            {pack.title === 'Documents & Administration' ?
                (<div className='d-flex align-items-center,justify-content-arround mr-3 packstyle' onClick={handlenavigate} style={{ cursor: 'pointer' }} >
                    <div style={{ position: 'relative' }}>
                        <img src={pack.image} className='imageAdministration' />
                        <div className='packresponsive-1'style={{ position: 'absolute', width: 449, height: 136, background: 'white', borderRadius: '20px', zIndex: 2, bottom: '20px', left: '25px', textAlign: 'center' }}>
                            <p style={{ fontSize: 24, fontWeight: 600, marginTop: 35 }} className='packresponsive'>{pack.title}</p>
                            <p style={{ marginTop: -12, color: 'rgb(102, 102, 102)', left: 20 }}>Cameroun</p>
                        </div>
                    </div>

                </div>) : pack.title === 'Santé & bien-être' ? (<div className='packstyle d-flex align-items-center,justify-content-arround ml-3' onClick={handlenavigate_} style={{ cursor: 'pointer' }} >
                    <div style={{ position: 'relative' }}>
                        <img src={pack.image} className='imageAdministration' />
                        <div className='packresponsive-1'style={{ position: 'absolute', width: 449, height: 136, background: 'white', borderRadius: '20px', zIndex: 2, bottom: '20px', left: '25px', textAlign: 'center' }}>
                            <p style={{ fontSize: 24, fontWeight: 600, marginTop: 35 }}  className='packresponsive'>{pack.title}</p>
                            <p style={{ marginTop: -12, color: 'rgb(102, 102, 102)', left: 20 }}>Cameroun</p>
                        </div>
                    </div>

                </div>) : null}

        </>

                    

        // </div>
    );
}



export default NewPAck;