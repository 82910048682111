import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceStatus } from '../../../services/auth/authService';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ServiceComposant from './ServiceComposant';
import correct from '../../../images/correct.png'
import annuler from '../../../images/annuler.png'
import synchroniser from '../../../images/synchroniser.png'
import attention from '../../../images/attention.png'

const Success = () => {
    const ref = useParams()
    const dispatch = useDispatch()
    const [invoice, setInvoice] = useState(null)
    const [amount,setAmount] = useState()
    const foundSymbol = localStorage.getItem('foundSymbol') ? localStorage.getItem('foundSymbol') : null;

    const test =  invoice 
    console.log('invoice', test)
    const [testcheck, setTestcheck] = useState(true)
    const [success, setSuccess] = useState(false)
    const [fail, setFail] = useState(false)
    const [pending, setPending] = useState(false)
    const [echec, setEches] = useState(false)


    useEffect(() => {
        registerComponent();
    }, [ref.id]);

    const registerComponent = () => {

        dispatch(serviceStatus(ref.id))
            .then((result) => {
                console.log('result ', result);
                setInvoice(result.data);
                const res = result.data
                setTestcheck(false);
                if (res.status === 'pending') { setPending(true) }
                if (res.status === 'paid') { setSuccess(true) }
                if (res.status === 'failed') { setFail(true) }
                if (res.status === 'cancelled') { setEches(true) }
                // if (res.status === 'paid') { setPending(true) }
                setAmount(res.amount)
            })
            .catch((e) => {
                console.log('errors ', e);
            });
    }

    const navigate = useNavigate();
    const handlenavige =()=>{
        navigate('/')
    }


    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', background: 'white' }} className='d-flex'>
            <div style={{ width: 800, height: 580, background: '#F6F6F6', alignItems: 'center', justifyContent: 'center', marginBottom: 150, marginTop: 50 }} className='d-flex flex-column widthrespon'>
                <div style={{ width: 60, height: 60, marginBottom: 30 }}>

                    {testcheck ? (<Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>) : (

                        <>
                            {success && <img src={correct} width={60} height={60} style={{ objectFit: 'cover' }} />}
                            {fail && <img src={annuler} width={60} height={60} style={{ objectFit: 'cover' }} />}
                            {pending && <img src={synchroniser} width={60} height={60} style={{ objectFit: 'cover' }} />}
                            {echec && <img src={attention}   width={60} height={60} style={{ objectFit: 'cover' }} />}
                        </>
                    )}
                </div> 
                <div style={{ marginBottom: 13 }}>
                    { success &&  <>
                        <p style={{ fontSize: 30, fontWeight: '500',textAlign:'center' }} className='fontSize2'>Merci pour votre achat !</p>
                        <p style={{ fontSize: 15, color: "#6C6C6C", textAlign: 'center', lineHeight: '20px' }} className='fontSize'>Votre service est désormais actif et sera disponible .<br />
                        Référence de votre commande :<span style={{fontWeight:'500'}}> {ref.id}</span>.<br/>
                        Nous vous remercions pour votre confiance ! 🚀
                        </p> </>
                    }
                     { pending &&  <>
                        <p style={{ fontSize: 30, fontWeight: '500',textAlign:'center' }}className='fontSize2'>⏳ Paiement en cours de traitement </p>
                        <p style={{ fontSize: 15, color: "#6C6C6C", textAlign: 'center', lineHeight: '20px' }} className='fontSize'>Votre commande a bien été reçue et est en attente de confirmation.<br />
                        Nous vous informerons dès que le paiement sera validé.<span style={{fontWeight:'500'}}> {ref.id}</span>.<br/>
                        Référence de votre commande : <span style={{fontWeight:'500'}}> {ref.id}</span>.
                        </p> </>
                    }
                     { echec &&  <>
                        <p style={{ fontSize: 30, fontWeight: '500',textAlign:'center' }} className='fontSize2'>⌛ Commande expirée </p>
                        <p style={{ fontSize: 15, color: "#6C6C6C", textAlign: 'center', lineHeight: '20px' }}className='fontSize'>Votre paiement n’a pas été effectué dans le délai imparti. Votre commande a donc expiré.<br />
                        Vous pouvez effectuer une nouvelle commande si nécessaire.<span style={{fontWeight:'500'}}> {ref.id}</span>.<br/>
                        Référence de votre commande : <span style={{fontWeight:'500'}}> {ref.id}</span>.
                        </p> </>
                    }
                    
                    {/* <p style={{ fontSize: 30, fontWeight: '500' }}>Thank your for your purchase</p>
                    <p style={{ fontSize: 15, color: "#6C6C6C", textAlign: 'center', lineHeight: '20px' }}>We've received your order will ship in 5-7 business days .<br />
                        Your order number is #B6CT3</p> */}
                </div>
                <div style={{ height: '230px', width: 438, borderRadius: '10px', background: 'white' }} className='width346M'>
                    <div style={{ padding: 17 }}>
                        <p style={{ fontSize: 19, fontWeight: 'bold', paddingBottom: 5 }}>Order Summary</p>
                        {invoice && invoice.servicesBeneficiaryId && invoice.servicesBeneficiaryId.services.length > 0 ? (
                            invoice.servicesBeneficiaryId.services.map((service) => (
                                <ServiceComposant key={service._id} service={service} />
                            ))
                        ) : (
                            <p>No service available</p>
                        )}

                        {/* <div style={{ fontSize: 13 }} className='d-flex'>
                            <div style={{ marginRight: 15 }}>
                                <img src="http://localhost:3000/assets/images/blog-image4.jpg" width={50} height={50} />
                            </div>
                            <div className='d-flex' style={{ alignItems: 'center', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ width: 250 }} >
                                    <p style={{ fontSize: 12, color: '#6C6C6C', lineHeight: '12px', padding: 0, margin: 0 }}>Half Sleeve 100% Cotton Shirts<br /> for woman</p>
                                </div>
                                <div style={{ width: 100 }}>
                                    <p style={{ fontSize: 12, margin: 0, textAlign: 'end', marginRight: 17 }}>$ 800</p>
                                </div>
                            </div>

                        </div>

                        <div style={{ borderBottom: '2px solid #F6F6F6', marginTop: 5, marginBottom: 5 }}></div> */}
                        <div style={{ justifyContent: 'space-around', }} className='d-flex'>
                            <p style={{ fontSize: 12, fontWeight: '500' }}>Total</p>
                            <p style={{ fontSize: 12, fontWeight: '700' }}>{foundSymbol} {amount}</p>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', margin: 0 }}>
                        <button onClick={handlenavige} type="button" className="btn btn-outline-dark" style={{ fontSize: 13, fontWeight: '500', borderRadius: '10px', border: '2px solid', margin: 0 }}>Back to Home</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;