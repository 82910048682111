import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PlaceIcon from '@mui/icons-material/Place';

const SearchBarH55 = ({ address, handleAddressGeocode, getProfileschedulesWithMyLocation, name, classNameVal }) => {
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      handleAddressGeocode(selectedAddress);

      const data = results[0].address_components;

      const filterAndReturn = (data, targetType) =>
        data.filter(item => item.types.includes(targetType))[0]?.short_name || null;

      const countryValue = filterAndReturn(data, "country");

      const coord = {
        userLat: lat,
        userLng: lng,
        address: selectedAddress,
        name,
        short_countryName: countryValue.toLowerCase(),
      };

      getProfileschedulesWithMyLocation(coord);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressGeocode}
        onSelect={handleSelect}
      // searchOptions={{
      //   componentRestrictions: { country: 'fr' }, // Remplacez 'cm' par votre code pays si nécessaire
      // }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              style={{height:55}}
              {...getInputProps({
                placeholder: 'Recherchez votre adresse...',
                className: classNameVal,
                name,
              })}
              
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.placeId}
                  style={{ padding: '5px', background: 'white', display: 'flex', flexDirection: 'row', fontSize: 16 }}
                >
                  <div style={{cursor:'pointer' ,borderBottom:'1px solid rgb(223, 219, 219)',marginTop:7,lineHeight:'25px' }}>
                    <PlaceIcon sx={{ fontSize: 20, color: '#28a745', margin: '0 5px' }} />
                    {suggestion.description}
                  </div>

                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default SearchBarH55;