import React from 'react';
import { useSelector } from 'react-redux';

const Parametre = ({service}) => {
    // const foundSymbol = localStorage.getItem('foundSymbol')?localStorage.getItem('foundSymbol'): null;
    const foundSymbol = useSelector(state =>state.packReducer.symbol)
    

    console.log('service', service)
    return (
        <div>
            <div className='d-flex justify-content-between'>
                <div>
                <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14 }}>{service.title}</p>
                <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14,marginTop:'-19px' }}>{service.date_of_completion}</p>
                </div>
                <p>{service.NewPriceConvert} {foundSymbol}</p>
            </div>
        </div>
    );
};

export default Parametre;

// import React from 'react';

// const Parametre = ({offre,time}) => {
//     return (
//         <div>
//             <p style={{ fontWeight: 'bold', fontSize: 15 }}>{offre.id_Pack.title}</p>
//             <div className='d-flex justify-content-between'>
//                 <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14 }}>{offre.title} ({offre.tier}) - {time}</p>
//                 {time == '1 an' ? (<p>{offre.price.annual} {foundSymbol}</p>) : time == "1 mois" ? (<p>{offre.price.monthly} {foundSymbol}</p>) : null}
//             </div>
//         </div>
//     );
// };

// export default Parametre;