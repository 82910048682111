import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { priceByCurrrency, registerB, serviceBeneficiairie } from '../../../services/auth/authService';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProfilBeneficiaire from '../../components/ProfilBeneficiaire';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import Parametre from '../../components/Parametre';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBarH55 from '../geolocation/SearchBarH55';
import { clearLocalStorageExcept, setbenificiaryid, setUserBeneficiaryId, updateServicesReducer } from '../../../store/actions';


const BenficiaryPaiment = () => {


    const services = useSelector(state => state.packReducer.services);
    const [testcheck, setTestcheck] = useState(true)

    const [address, setAddress] = useState("");
    const [shortCountryName, setShortCountryName] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phoneNumberBeneficiary, setphoneNumberBeneficiary] = useState('')
    const erreur = useSelector((state) => state.__ct.message)
    const [valid, setValid] = useState(true);



    const itemsReducer = useSelector((state) => state.__ct.items);
    const itemsStored = JSON.parse(localStorage.getItem('itemsstored'));
    const items = itemsReducer && itemsReducer.length > 0 ? itemsReducer : itemsStored;

    const titleRedux = useSelector((state) => state.packReducer.title)
    console.log('title redux', titleRedux)

    /* route */
    const locationRedux = useSelector((state) => state.__ct.location);
    const locationStored = JSON.parse(localStorage.getItem('currentpath'));
    const locationChoix = '/pack-sante';


    /* time */
    const timeStored = JSON.parse(localStorage.getItem('timeredux'));
    const timeredux = useSelector((state) => state.__ct.timeRedux);
    const time = timeredux !== null ? timeredux : timeStored;

    /*  offre */
    const offreRedux = useSelector((state) => state.__ct.pack);
    const offrestored = JSON.parse(localStorage.getItem('selectedOffreId'));
    const offre = offreRedux !== null ? offreRedux : offrestored;


    const handleAddressGeocode = (e) => {


        setAddress(e);
        // formValues.address = addr;
        // setFormValues({
        //   ...formValues
        // });
    }

    const getProfileschedulesWithMyLocation = (coord) => {

        setShortCountryName(coord.short_countryName);
    }
    useEffect(() => {
        if (address) {
            setFormData_(prevState => ({
                ...prevState,
                location: address
            }));
        }
    }, [address]);
    console.log('items sssssssssssssssssssssssssssssssssssssssss ', items);

    const subTotalAnnual = items !== null && items.reduce((total, pack) => {
        const price = pack.price.annual;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    const subTotalmonthly = items !== null && items.reduce((total, pack) => {
        const price = pack.price.monthly;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    const storedServices = JSON.parse(localStorage.getItem('servicesWithTrueStatus'));
    console.log('storedServices', storedServices)
    const title = JSON.parse(localStorage.getItem('title')) || null;
    const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
    const serviceFilter = servicesLocal1.filter(service => service.status === true);

      const foundSymbol = useSelector(state =>state.packReducer.symbol)
  
        useEffect(() => {
            if (serviceFilter.length >= 1) {
                // localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
                //    dispatch(updateServicesReducer(serviceFilter));
                registerComponent3(serviceFilter)
    
            }
        }, [foundSymbol]);
        const currencyCountry = useSelector((state) => state.packReducer.currencyCountry);
       const registerComponent3 = async (services) => {
        const newServiceList = await Promise.all(
            services.map(async (service) => {
                try {
                    const result = await dispatch(priceByCurrrency(currencyCountry, service.full_priceCFA));
                    const amount = result.data.amount;
    
                    return {
                        ...service,
                        NewPriceConvert: amount, // Ajoute la conversion de prix
                    };
                } catch (e) {
                    console.log('errors', e);
                    return service; // Renvoyer le service sans conversion en cas d'erreur
                }
            })
        );
    
        // console.log('newServiceList', newServiceList);
        localStorage.setItem('newServiceList', JSON.stringify(newServiceList));

        dispatch(updateServicesReducer(newServiceList));
        return newServiceList;
    };
        const [full_priceCFAcONVERT,setFull_priceCFAcONVERT]= useState(0)
        const arrondi = services
            .filter(service => service.status === true)
            .reduce((total, service) => total + parseFloat(service.full_priceCFA), 0);
      
         const registerComponent4 = () => {
                dispatch(priceByCurrrency(currencyCountry, arrondi))
                    .then((result) => {
                        const amount = result.data.amount;
                        setFull_priceCFAcONVERT(amount);
                    })
                    .catch((e) => {
                        console.log('errors ', e);
                    });
            }
            useEffect(()=>{
                if(currencyCountry && arrondi) {registerComponent4()}
            },[arrondi,currencyCountry])
    
        // localStorage.setItem('title', JSON.stringify(titleRedux));
        // const storedServices = JSON.parse(localStorage.getItem('totalAmount'));
        function arrondirSuperieurDeuxDecimales(nombre) {
            return Math.ceil(nombre * 100) / 100;
        }
        const totalAmount =Number( arrondirSuperieurDeuxDecimales(full_priceCFAcONVERT));
        localStorage.setItem('totalAmount', JSON.stringify(totalAmount));
    // useEffect(() => {
    //     if (serviceFilter.length >= 1) {
    //         localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
    //         dispatch(updateServicesReducer(serviceFilter));

    //     }
    // }, []);
    const storeduserInfo = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(storeduserInfo);
    console.log('sdfsdfsfdsdfsdf', userInfo)

    const idprotector = userInfo.protectorSpecificField.protector_id;
    console.log(idprotector, 'sdfsdfsfdsdfsdf')
    // const foundSymbol = localStorage.getItem('foundSymbol')?localStorage.getItem('foundSymbol'): null;


    const [formData_, setFormData_] = useState({
        name: '',
        sexe: '',
        id_User_protector: idprotector,
        phone: phoneNumberBeneficiary,
        location: address,
        role: 'beneficiary',
    });
    const [formErrors_, setFormErrors_] = useState({
        name: '',
        location: ''
    });


    const handleChange_ = (e) => {
        const { name, value } = e.target;
        setFormData_({
            ...formData_,
            [name]: value,
        });
        if (value.trim() === '') {
            setFormErrors_({
                ...formErrors_,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setFormErrors_({
                ...formErrors_,
                [name]: ''
            });
        }
    };
    const handleChangePhoneB = (value) => {
        setphoneNumberBeneficiary(value);
        setValid(validphonenumber(value));
        setFormData_((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const validphonenumber = (input) => {
        const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return patt.test(input);
    }
    const navigateTrueFalse = useSelector(state => state.packReducer.navigateTrueFalse)
    const valeurRecuperee = localStorage.getItem('maCle')
    console.log('valeurRecuperee aaaaaaaaa', valeurRecuperee)
    const handlesante = () => {
        if (valeurRecuperee === 'true') {
            navigate('/Intermediary')
        }
        else {
            navigate('/paiement')
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Début de handleSubmit");

        let formIsValid_ = true;
        const newErrors_ = {};
        Object.keys(formData_).forEach((field_) => {

            // Vérifier si le champ est vide
            if (formData_[field_].trim() === '') {
                newErrors_[field_] = 'Ce champ est obligatoire';
                formIsValid_ = false;
            }
        });
        setFormErrors_(newErrors_);

        // Logique de soumission
        console.log('Formulaire soumis', formData_);

        if (formIsValid_) {
            try {
                console.log("Avant le registerComponent");
                registerComponent(formData_);


            } catch (error) {
                console.error('Erreur lors de la création du bénéficiaire :', error);

            }
        }
    }

   


    
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const storedToken = localStorage.getItem('authToken');
    const [beneficiaries, setBeneficiaries] = useState(null)
    const config = {
        headers: {
            'Authorization': `Bearer ${storedToken}`  // Inclure le token dans le header
        }
    };
    useEffect(() => {

        getBeneficiaryByProtectorId();
        console.log('beneficiaires ajskdajhsd', beneficiaries)

    }, []);
    const getBeneficiaryByProtectorId = async () => {
        axios.get(`https://api.wemaza.com/api/userBeneficiaries/userProtector/${idprotector}`, config)
            .then((response) => {
                setBeneficiaries(response.data);  // Stocker les données dans l'état
                setTestcheck(false);
            })
            .catch((error) => {
                console.log('Erreur de connexion. Vérifiez votre réseau.');
            });
    };


    // Breakpoints for responsiveness
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

     const servicesLocal = JSON.parse(localStorage.getItem('servicesLocal'));
        // const serviceFilter = servicesLocal.filter(service => service.status === true);
        // console.log('serviceFilter ', serviceFilter);
        // const services = serviceFilter.map(service => ({
        //     serviceId: service._id,  
        //     cityId: service.cityId || '', 
        //     details: service.description || '', 
        //     date_of_completion: service.date_of_completion || new Date().toISOString(),  
        //     documentFiles: service.documentFiles || [] 
        //   }));
    
        // function retrieveServices(serviceFilter) {
        //     return serviceFilter.map(service => {
        //         const documentFilesKey = `selectedFiles_${service._id}`;
        //         const storedFiles = localStorage.getItem(documentFilesKey);
    
        //         // Désérialiser les fichiers stockés et recréer les instances de `File`
        //         const documentFiles = storedFiles ? JSON.parse(storedFiles).map(fileData => {
        //             return new File(
        //                 [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
        //                 fileData.name,
        //                 {
        //                     type: fileData.type,
        //                     lastModified: fileData.lastModified
        //                 }
        //             );
        //         }) : [];
    
        //         return {
        //             serviceId: service._id,
        //             cityId: service.cityId || '',
        //             details: service.description || '',
        //             date_of_completion: service.date_of_completion || new Date().toISOString(),
        //             documentFiles: documentFiles  // Fichiers reconstruits
        //         };
        //     });
        // }

        function retrieveServices(services) {
            return services.map(service => {
                const documentFilesKey = `selectedFiles_${service._id}`;
                const storedFiles = localStorage.getItem(documentFilesKey);
    
                let documentFiles = [];
                if (storedFiles) {
                    try {
                        const parsedFiles = JSON.parse(storedFiles);
    
                        // Vérifier si `parsedFiles` est un tableau ou un objet
                        if (Array.isArray(parsedFiles)) {
                            // Si c'est un tableau, on utilise `map` normalement
                            documentFiles = parsedFiles.map(fileData => {
                                return new File(
                                    [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
                                    fileData.name,
                                    {
                                        type: fileData.type,
                                        lastModified: fileData.lastModified
                                    }
                                );
                            });
                        } else if (typeof parsedFiles === 'object' && parsedFiles !== null) {
                            // Si c'est un objet, on l'encapsule dans un tableau
                            documentFiles = [new File(
                                [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
                                parsedFiles.name,
                                {
                                    type: parsedFiles.type,
                                    lastModified: parsedFiles.lastModified
                                }
                            )];
                        } else {
                            console.error("Les données stockées ne sont ni un tableau ni un objet valide:", parsedFiles);
                        }
                    } catch (error) {
                        console.error("Erreur lors du parsing des fichiers stockés:", error);
                    }
                }
    
                return {
                    serviceId: service._id,
                    cityId: service.cityId || '',
                    details: service.description || '',
                    price_to_pay:service.priceConvert || '',
                    date_of_completion: service.date_of_completion || new Date().toISOString(),
                    documentFiles: documentFiles  // Fichiers reconstruits
                };
            });
        }
    
    
        const retrievedServices = retrieveServices(serviceFilter);

        useEffect(() => {
            if (serviceFilter.length >= 1) {
                // localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
                //    dispatch(updateServicesReducer(serviceFilter));
        console.log('newServiceList88', retrievedServices);

                registerComponent5(retrievedServices)
    
            }
        }, [foundSymbol]);
        const [newList ,setNewList] = useState([])
        console.log('newServiceList66', newList);
       const registerComponent5 = async (services) => {
        const newServiceList2 = await Promise.all(
            services.map(async (service) => {
                try {
                    const result = await dispatch(priceByCurrrency(currencyCountry, service.full_priceCFA));
                    const amount = result.data.amount;
    
                    return {
                        ...service,
                        NewPriceConvert: amount, // Ajoute la conversion de prix
                    };
                } catch (e) {
                    console.log('errors', e);
                    return service; // Renvoyer le service sans conversion en cas d'erreur
                }
            })
        );
    
        console.log('newServiceList', newServiceList2);
        setNewList(newServiceList2);
        // dispatch(updateServicesReducer(newServiceList));
        return newServiceList2;
    };
    
        console.log('retrievedServices', retrievedServices);
    
        // const totalAmount = JSON.parse(localStorage.getItem('totalAmount'))
        console.log('totalAmount', totalAmount)
    
        // const savedUserBeneficiaryId = localStorage.getItem('savedUserBeneficiaryId');
        // const savedIdBeneficiary_id = localStorage.getItem('savedIdBeneficiary_id');
        const beneficiary_id = useSelector( state => state.packReducer.userBeneficiaryId)
        
        
        const [formData_1, setFormData_1] = useState({
            // userBeneficiaryId: benefID ? benefID : savedIdBeneficiary_id,
            // userBeneficiaryId:beneficiary_id,
    
            services: retrievedServices,
            totalAmount: totalAmount,
    
        });
        const registerComponent = (formData_) => {

            console.log('Form data submitted:', formData_);
    
            dispatch(registerB(formData_))
                .then((result) => {
                    console.log('Bénéficiaire créé avec succès:', result);
                    const beneficiary_id2 =result.data.beneficiary_id
                    registerComponent2  (result.data.beneficiary_id) 
                    // navigate('/Intermediary');
                    // handlesante();
                   
                
                })
                .catch((e) => {
                    console.log('errors ', e);
    
                });
        }
        const registerComponent2 = (beneficiary_id2) => { 
    
            retrievedServices && dispatch(serviceBeneficiairie(beneficiary_id2,totalAmount, newList,savedServiceList))
                .then((result) => {
                    console.log('result ', result);
                    const id1 = result.data._id;
                    dispatch(setUserBeneficiaryId(null)); 
    
                    if (valeurRecuperee === 'true') {
                        navigate('/Intermediary')
                    }
                    else {
                        navigate(`/paiement/${id1}`);
                        // handleClearStorage();
                    }
                    // console.log('id1 ', id1);
                    // // dispatch(setServiceBeneficiaryId(id1));
                    // console.log('id12 ', id1);
                    // localStorage.setItem('savedId', id1);
                    // console.log('id13 ', id1);
    
                })
                .catch((e) => {
                    console.log('errors ', e);
                });
        }

    useEffect(() => {
        if(beneficiary_id !== null) registerComponent1(formData_1);
        
    }, [beneficiary_id]); // Le hook s'exécute à chaque fois que formData_ change
    const savedServiceList = JSON.parse(localStorage.getItem('newServiceList'));
    const registerComponent1 = (formData_1) => { 

        dispatch(serviceBeneficiairie(beneficiary_id,totalAmount, newList,savedServiceList))
            .then((result) => {
                console.log('result ', result);
                const id1 = result.data._id;
                dispatch(setUserBeneficiaryId(null)); 

                if (valeurRecuperee === 'true') {
                    navigate('/Intermediary')
                }
                else {
                    navigate(`/paiement/${id1}`);
                    // handleClearStorage();
                }
                // console.log('id1 ', id1);
                // // dispatch(setServiceBeneficiaryId(id1));
                // console.log('id12 ', id1);
                // localStorage.setItem('savedId', id1);
                // console.log('id13 ', id1);

            })
            .catch((e) => {
                console.log('errors ', e);
            });
    }
    

    const handlesante22 = () => {
        if (valeurRecuperee === 'true') {
            navigate('/Intermediary')
        }
        else {
            navigate(`/paiement/${beneficiary_id}`)
        }
    }




    return (
        <div className='d-flex flex-column ' style={{ alignItems: 'center', justifyContent: 'center' }} >
{beneficiary_id && <p> l'id du benificaire:{beneficiary_id}</p>}
            <div className='container-fluid' role="presentation" style={{ marginTop: 25, marginLeft: 10 }} onClick={handleClick}>
                <div className="container mt-4" style={{ paddingBottom: '10px' }}>
                    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'rgb(30, 73, 155)' }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            <HomeIcon sx={{ mr: 1 }} className='breadcrumbs' />
                            Home
                        </Link>
                        {valeurRecuperee === 'false' ? <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link> : <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService-santé'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link>}
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/connected-protector-beneficiary-account'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}
                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Bénéficiaire
                        </Link>

                    </Breadcrumbs>
                </div>
            </div>


            <div className='d-flex flex-wrap justifyarround'>
                <div style={{ marginBottom: 50 }}>
                    <div className='container d-flex flex-wrap justify-content-center'>
                        <div className="p-4">
                            <div >
                                <div style={{ marginTop: 15, textAlign: 'center' }}>
                                    <p style={{ background: '#C5E1A5'/*  "rgba(56, 115, 184, 0.1)" */, padding: 10, fontSize: '18px' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: '25px' }}>Compte Bénéficiaire :</p>
                                        créez un nouveau profil en remplissant le formulaire </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label style={{ fontWeight: 'normal', color: formErrors_.name ? 'red' : 'black', }} htmlFor="name" className="form-label">Nom et Prénom</label>
                                        <input
                                            style={{ height: 55 }}
                                            type="text"
                                            className={`form-control ${formErrors_.name ? 'is-invalid' : ''}`}
                                            id="name"
                                            name="name"
                                            value={formData_.name}
                                            onChange={handleChange_}
                                            placeholder="Entrez votre nom"
                                        />
                                        {formErrors_.name && <small className="text-danger">Veuillez saisir votre nom et prénom</small>}
                                    </div>



                                    <div className="mb-3 ">
                                        <label style={{ fontWeight: "normal", marginRight: '10px', color: formErrors_.sexe ? 'red' : 'black', }} className="form-label">Sexe</label>
                                        <div className='d-flex'>
                                            <div className="form-check mr-2">
                                                <input
                                                    className={`form-check-input ${formErrors_.sexe ? 'is-invalid' : ''}`}
                                                    type="radio"
                                                    name="sexe"

                                                    id="sexeMale"
                                                    value="male"
                                                    checked={formData_.sexe === 'male'}
                                                    onChange={handleChange_}
                                                />
                                                <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeMale">Masculin</label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className={`form-check-input ${formErrors_.sexe ? 'is-invalid' : ''}`}
                                                    type="radio"
                                                    name="sexe"

                                                    id="sexeFemale"
                                                    value="female"
                                                    checked={formData_.sexe === 'female'}
                                                    onChange={handleChange_}
                                                />
                                                <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeFemale">Féminin</label>
                                            </div>
                                        </div>
                                        {formErrors_.sexe && <small className="text-danger">Veuillez sélectionner votre sexe</small>}
                                    </div>


                                    <div className="mb-3">
                                        <label style={{ fontWeight: "normal", color: formErrors_.phone ? 'red' : 'black', }} htmlFor="phone" className="form-label">Téléphone</label>
                                        <PhoneInput
                                            country={'cm'}
                                            type="tel"
                                            className="phoneClass"
                                            id="phone"
                                            name="phone"
                                            value={phoneNumberBeneficiary}
                                            onChange={handleChangePhoneB}
                                            placeholder="Entrez votre numéro de téléphone"
                                        />
                                        {formErrors_.phone && <small className="text-danger">Veuillez saisir votre numéro de teléphone</small>}
                                    </div>

                                    <div  >
                                        <label style={{ fontWeight: "normal", color: formErrors_.location ? 'red' : 'black', }} htmlFor="location" className="form-label">Adresse</label>
                                        <SearchBarH55
                                            name="address"
                                            address={address}
                                            handleAddressGeocode={handleAddressGeocode}
                                            getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
                                            classNameVal={`input-field form-control ${formErrors_.location ? 'is-invalid' : ''}`}
                                        />
                                        {formErrors_.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                    </div>
                                    {erreur != "" ? (
                                        <p
                                            style={{
                                                color: "#721c24",
                                                textAlign: "center",
                                                backgroundColor: "#f8d7da",
                                                padding: 10,
                                            }}
                                        >
                                            {erreur}
                                        </p>) : null}
                                    <button type="submit" style={{ background: '#4CAF50', borderColor: '#4CAF50' }} className="btn btn-success w-100">Soumettre</button>
                                </form>
                            </div>
                        </div>

                        <div className="p-4  d-flex flex-column align-items-center justify-content-center" >




                            {locationChoix === "/pack-personnalise" ? (
                                <div style={{ minWidth: isSmallScreen ? "320px" : isMediumScreen ? "400px" : "400px", height: 'auto', background: 'white' }}>
                                    <div>
                                        <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                        </div>
                                        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                            {items.length > 0 ? items.map((offre, index) => (
                                                <Parametre key={index} offre={offre} time={time} />
                                            )) : (
                                                <p>Aucun pack disponible</p>
                                            )}
                                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Total HT</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                    <p style={{ fontWeight: 'bold' }}>0 {foundSymbol}</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                locationChoix === "/pack-sante" ||
                                locationChoix === "/pack-gestion-du-domicile" ||
                                locationChoix === "/pack-besoin-informatique"
                            ) ? (
                                <div >
                                    <div style={{ minWidth: isSmallScreen ? "320px" : isMediumScreen ? "400px" : "400px", height: 'auto', background: 'white' }}>
                                        <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                        </div>
                                        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 15 }}>{title}</p>
                                            {services.length > 0 ? services.map((service, index) => (
                                                <Parametre key={index} service={service} />
                                            )) : (
                                                <p>Aucun pack disponible</p>
                                            )}
                                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Total HT</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                    <p style={{ fontWeight: 'bold' }}>0 {foundSymbol}</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>

                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{totalAmount} {foundSymbol}</p>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ) : null}




                            {/* <div className='d-flex flex-column align-items-center justify-content-center'>
                                <h2 className="mt-5" style={{ fontSize: 25 }}>Prototype de carte du Bénéficiaire ci-dessous</h2>
                                <div className="credit-card p-3" style={{ border: '1px solid #ccc', borderRadius: 10 }}>
                                    <div className='d-flex justify-content-between'>
                                        <img src="/assets/images/logo1.png" alt="Logo" className='avatar' style={{ width: 50 }} />
                                        <IconButton>
                                            <AccountCircle style={{ fontSize: 40, color: '#1976d2' }} />
                                        </IconButton>
                                    </div>
                                    <div style={{ paddingLeft: 12 }}>
                                        <div className="number mb-1">1234 5678 9012 3456</div>
                                        <div className="name">John Doe</div>
                                        <div className="expiration">Exp: 12/24</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>




                </div >


                <div className=" container widthprofilbnef" style={{
                    width: isMobile ? '360px' : 'auto',
                    textAlign: "center",
                    background: "transparent",
                    height: "auto",
                    border: "2px solid #E0E0E0",
                    borderRadius: 20,
                    marginBottom: 70
                }}>
                    <p style={titleStyle} className='withtext'><span style={{ fontWeight: 'bold', lineHeight: '1.3' }}>Liste des Bénéficiaires existants :</span> Choisissez-en un pour cette offre</p>
                    <div style={flexStyle}>
                    {testcheck ? (<Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>):<>
                        {beneficiaries !== null ? (beneficiaries.map((beneficiaire) => (<ProfilBeneficiaire key={beneficiaire.id} beneficiaire={beneficiaire} />)))
                            : (<p>null</p>)}</>}
                        {/* <div  ><ProfilBeneficiaire /></div>
                        <div  ><ProfilBeneficiaire /></div>
                        <div  ><ProfilBeneficiaire /></div>
                        <div  ><ProfilBeneficiaire /></div>
                        <div  ><ProfilBeneficiaire /></div>
                        <div  ><ProfilBeneficiaire /></div> */}
                    </div>
                    <style>{`${responsiveStyles}`}</style>
                </div>
            </div>
        </div>
    );
};

export default BenficiaryPaiment;

const Responsive = {
    '@media (width < 1035px)': {
        'reponsivetest': {
            flexDirection: 'column'
        }
    },
}


const titleStyle = {
    fontSize: "25px"
};

const flexStyle = {
    marginTop: 20,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
};

const profilBeneficiaireStyle = {
    /*  flex: "1 1 0px", // Responsive behavior
     margin: "10px" */
};

const responsiveStyles = {
    '@media (max-width: 1200px)': {
        '.d-flex': {
            justifyContent: "space-around"
        }
    },
    '@media (max-width: 768px)': {
        '.d-flex': {
            flexDirection: "row"
        },
        '.m-5': {
            margin: "20px"
        }
    },
    '@media (max-width: 576px)': {
        '.d-flex': {
            flexDirection: "column"
        },
        'p': {
            fontSize: "20px"
        }
    }
};
const imageinfo = {
    height: 16,
    cursor: 'pointer',
    marginLeft: 1
}