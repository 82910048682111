import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Services from './Services';
import ServiceCall from './ServiceCall';

const ListeService = () => {
    const [activeService, setActiveService] = useState(null); // Garde l'ID du service actif
    const [active, setActive] = useState(false); // Garde l'ID du service actif
    const handleActive = () => {

        setActive(true)// Met à jour l'ID du service sélectionné
    }

    const services = [
        { id: 1, nom: 'Nettoyage', prix: 15000 },
        { id: 2, nom: 'Consultation médicale', prix: 30000 },
        { id: 3, nom: 'Installation de logiciels', prix: 5000 },
        { id: 4, nom: 'Réparation informatique', prix: 20000 },
        { id: 5, nom: 'Cours de programmation', prix: 25000 },
        { id: 6, nom: 'Consultation juridique', prix: 40000 },
        { id: 7, nom: 'Coaching sportif', prix: 15000 },
        { id: 8, nom: 'Développement web', prix: 60000 },
        { id: 9, nom: 'Service de comptabilité', prix: 35000 },
        { id: 10, nom: 'Graphisme', prix: 18000 },
        { id: 11, nom: 'Marketing digital', prix: 45000 },
        { id: 12, nom: 'Photographie', prix: 22000 },
        
    ];

    return (
        <div style={{ marginBottom: 200 }}>
            {active ? <ServiceCall /> : null}
            <div className='d-flex flex-column align-items-center justify-content-center' onClick={handleActive}>
                <div style={{ width: 1400, height: 950, background: 'transparent', }} className='d-flex flex-wrap align-items-center justify-content-center'>
                    {services.map(service => (
                        <Services key={service.id} service={service} /> // Passe l'ID du service au clic
                    ))}
                </div>
                <div style={{ height: 150, background: 'white',width:1400 ,justifyContent:'space-around',borderRadius:20 }} className='d-flex align-items-center justify-content-arround '>
                    <div>
                        <p style={{ fontSize: 13, color:'rgb(123, 123, 122)' }}>Le prix est indicatif. Pour une discussion plus détaillée de tous les services dont vous avez<br /> besoin, utilisez le formulaire de contact ou demandez à être rappelé</p>
                    </div>
                    <div>
                        <p style={{ fontSize: 13, fontWeight: 'bold' }}>Total price: $<span style={{ fontSize: 20 }}> 0.00</span></p>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary buttoyellow1">commander un appel<img src="./assets/images/next1.png" className='nexticon' /></button>
                    </div>
                </div>


            </div>

        </div>
    );
}

ListeService.propTypes = {
    service: PropTypes.object,
    OnClick: PropTypes.func,
};

export default ListeService;
