import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { activer, activerpanier, setSelectedPack } from '../../store/actions';

const Pack = ({pack}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    
    const handleclick =()=>{ dispatch(activerpanier())}
 


    
    
    const goToPage = () => {
        localStorage.setItem('selectedPackId', pack._id);
        console.log(localStorage.getItem('selectedPackId'),'sssssssssssssssssssss');
        dispatch(setSelectedPack(pack._id));
        navigate(`/${pack.slug}`);
    }; 
    return (

      
        <div className="col-lg-3 col-md-6 col-sm-6 mb-3" style={{ cursor: "pointer" }} onClick={goToPage}>
        {pack.title === "Pack santé" ? (
          <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
            <div className="offer-section-inner">
              <figure>
                <img src={pack.image ? pack.image : "./assets/images/9.png"} alt="" className="img-fluid-img1" />
              </figure>
            </div>
            <div>
              <p style={{ paddingBottom: 5, fontSize: '20px', fontWeight: "bold" }}>{pack.title}</p>
            </div>
            {/* <p>{pack.description}</p> */}
            <p style={{color: '#666'}}>Garantissez la santé et le bien-être de vos proches avec un accompagnement médical complet et fiable</p>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
              <div style={{ cursor: "pointer" }} className="btn btn-success">Découvrir ce pack </div>
            </div>
          </div>
        ) : pack.title === "Pack gestion du domicile" ? (
          <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
            <div className="offer-section-inner">
              <figure>
                <img src={pack.image ? pack.image : "./assets/images/10.png"} alt="" className="img-fluid-img1" />
              </figure>
            </div>
            <div>
              <p style={{ paddingBottom: 5, fontSize: '20px', fontWeight: "bold" }}>{pack.title}</p>
            </div>
            {/* <p>{pack.description}</p> */}
            <p style={{color: '#666'}}> Assistance pour l’organisation et la gestion des tâches domestiques, pour un quotidien simplifié</p>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
              <div style={{ cursor: "pointer" }} className="btn btn-success">Découvrir ce pack </div>
            </div>
          </div>
        ) : pack.title === "Pack besoin informatique"?(
            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }}>
            <div className="offer-section-inner">
              <figure>
                <img src={pack.image ? pack.image : "./assets/images/11.png"} alt="" className="img-fluid-img1" />
              </figure>
            </div>
            <div>
              <p style={{ paddingBottom: 5, fontSize: '20px', fontWeight: "bold" }}>{pack.title}</p>
            </div>
            <p style={{color: '#666'}}>Accompagnement pour les démarches administratives et le soutien informatique de vos proches</p>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
              <div style={{ cursor: "pointer" }} className="btn btn-success">Découvrir ce pack </div>
            </div>
          </div>
        ): pack.title === "Pack personnalisé"?(
            <div className="offer-section-box d-flex flex-column" style={{ height: '100%' }} onClick={handleclick}>
            <div className="offer-section-inner">
              <figure>
                <img src={pack.image ? pack.image : "./assets/images/12.png"} alt="" className="img-fluid-img1" />
              </figure>
            </div>
            <div>
              <p style={{ paddingBottom: 5, fontSize: '20px', fontWeight: "bold" }}>{pack.title}</p>
            </div>
            <p style={{color: '#666'}}>Composez un pack sur-mesure en fonction des besoins spécifiques de vos proches pour un service parfaitement adapté</p>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center' }}>
              <div style={{ cursor: "pointer" }} className="btn btn-success">Découvrir ce pack </div>
            </div>
          </div>):null
    }
      </div>
      
    
    );
};

export default Pack;