import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useSelector } from 'react-redux';
import Parametre from '../../components/Parametre';
import Breadcrumbs from '@mui/material/Breadcrumbs';
/* import Link from '@mui/material/Link'; */
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NewIntermediary = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    // Breakpoints for responsiveness
    const [active, setActive] = useState(false)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const handleNavigate = ()=>{
        navigate('/paiement')
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const valeurRecuperee = localStorage.getItem('maCle')
    console.log('valeurRecuperee aaaaaaaaa',valeurRecuperee)
    return (
        <div style={{ marginBottom: 300 }}>
            <div className='container-fluid' role="presentation" style={{ marginTop: 25, marginLeft: 10 }} onClick={handleClick}>
                <div className="container mt-4" style={{ paddingBottom: '10px' }}>
                    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'rgb(30, 73, 155)' }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            <HomeIcon sx={{ mr: .5 }} className='breadcrumbs' />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService-santé'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/connected-protector-beneficiary-account'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Bénéficiaire
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/Intermediary'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Intermediaire
                        </Link>
                        

                    </Breadcrumbs>
                </div>
            </div>
            <div>

                <main className='container'>
                    {active ? (<> <h2 className="offer-heading" ><span className="support">Gestion de la collecte et du dépôt de votre document</span></h2>
                        <div className='d-flex justify-content-arround' style={{ height: 450, width: 1200, borderRadius: 5, background: 'white', boxShadow: " 0px 0px 15px #AEB1BE33", padding: "22px 30px 30px 30px" }}>
                            {/*  <div className='statisfait'> Satisfait ou <br/>remboursé 30J</div> */}

                            <div className='mr-4'>
                                <p style={{ fontSize: 23, fontWeight: 'bold', marginLeft: 10 }}>Préfériez-vous :</p>
                                <FormControl style={{ minWidth: isSmallScreen ? "300px" : isMediumScreen ? "500px" : "725px" }}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                    >
                                        {/* Option de paiement par carte bancaire */}
                                        <div className="d-flex flex-wrap align-items-center justify-content-between divblue1 mb-4" style={{ height: 115 }}>
                                            <FormControlLabel
                                                id="testToot"
                                                value="carte bancaire"
                                                checked
                                                control={<Radio />}
                                                label="Gérer vous-même le retrait et le dépôt de votre document
"
                                            />
                                            <div style={{ marginTop: 5 }}>
                                                {/* <img src="/assets/images/38.webp" alt="Logo Carte Bancaire" style={imagepaiement} /> */}
                                            </div>
                                        </div>

                                        {/* Option de paiement PayPal */}
                                        <div className="d-flex flex-wrap align-items-center justify-content-between divblue1 mb-4" style={{ height: 115 }}>
                                            <FormControlLabel
                                                id="testToot1"
                                                value="paypal"
                                                control={<Radio />}
                                                label="Nous déléguer la récupération et la livraison de votre document (service payant)"
                                            />
                                            <div style={{ marginTop: 5 }}>
                                                {/* <img src="/assets/images/39.webp" alt="Logo PayPal" style={imagepaiement} /> */}
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </FormControl>



                            </div>
                            <div >
                                <div style={{ minWidth: isSmallScreen ? "320px" : isMediumScreen ? "400px" : "400px", height: 'auto', background: 'white' }}>
                                    <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                    </div>
                                    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                        <div>
                                            {/* <p style={{ fontWeight: 'bold', fontSize: 15 }}>{offre.id_Pack.title}</p> */}
                                            <p style={{ fontWeight: 'bold', fontSize: 15 }}>Documents et Administration</p>
                                            <div className='d-flex justify-content-between'>
                                                {/* <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14 }}>{offre.title} ({offre.tier}) - {time}</p>
                                                        {time === '1 an' ? (
                                                            <p>{offre.price.annual} €</p>
                                                        ) : time === "1 mois" ? (
                                                            <p>{offre.price.monthly} €</p>
                                                        ) : null} */}

                                                <p style={{ color: 'rgb(102, 102, 102)', fontSize: 14 }}>Services 1 mois</p>
                                                <p>50 €</p>

                                            </div>
                                        </div>
                                        <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ fontSize: 15 }}>Total HT</p>
                                                {/* {time === '1 an' ? (
                                                            <p style={{ fontWeight: 'bold' }}>{offre.price.annual} €</p>
                                                        ) : time === "1 mois" ? (
                                                            <p style={{ fontWeight: 'bold' }}>{offre.price.monthly} €</p>
                                                        ) : null} */}
                                                <p style={{ fontWeight: 'bold' }}>50 €</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" /></p>
                                                <p style={{ fontWeight: 'bold' }}>6 €</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>
                                                {/* {time === '1 an' ? (
                                                            <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{offre.price.annual} €</p>
                                                        ) : time === "1 mois" ? (
                                                            <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{offre.price.monthly} €</p>
                                                        ) : null} */}
                                                <p style={{ fontWeight: 'bold', color: '#00A74D' }}>56 €</p>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center' }} onClick={handleNavigate}>  <button style={{ width: '100%', fontSize: 17, height: 50, fontWeight: "bold" }} className='btn btn-success' >Continuer ma commande</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    ) : (<>  <h2 className="offer-heading " style={{ margin: 20 }}>  <span className="support">Voici les hôpitaux les plus proches de chez vous</span></h2>

                        {/* <h2>Nos Services</h2> */}
                        <div className="service-cards offer-section offer-section1 about-offer-section" data-aos="fade-up" style={{ marginTop: 75 }}>

                            <div className="service-card " style={{ position: 'relative', height: 510 }}>
                                <img src="assets/img/pharmacie.jpeg" alt="Pharmacie" />
                                <h4 className='mt-2'>Hopital 1</h4>
                                <p>Localisation : Douala , Ndogpassi III (zone de recasement)</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/paiement"} style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "50%",
                                    textAlign: "center",
                                    marginBottom: '10px'
                                }}>
                                    Suivant
                                </Link>
                            </div>
                            <div className="service-card" style={{ position: 'relative', height: 510 }}>
                                <img src="assets/img/Hopital.jpeg" alt="Hôpital" style={{ width: "100%", height: "auto" }} />
                                <h4 className='mt-2'>Hopital 2</h4>
                                <p>Localisation : Douala akwa, immeuble fayadort</p>
                                <p>Heures d'ouverture : 8h - 18h</p>
                                <Link to={"/paiement"} style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "50%",
                                    textAlign: "center",
                                    marginBottom: '10px'
                                }}>
                                    Suivant
                                </Link>
                            </div>


                        </div></>)}


                </main>
            </div>
        </div>
    );
}

export default NewIntermediary;
const imagepaiement = {
    marginTop: 5,
    marginLeft: 10,

    height: 30,
    marginRight: 5
}
