import { blue, green } from '@mui/material/colors';
import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Offre from '../../components/Offre';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation, setSelectedPack, setTimeRedux } from '../../../store/actions';
import Personnalise from './Personnalise';
import Page404 from '../page404/Page404';

const Pricecing2 = () => {
    const [checked, setChecked] = useState(false);
    const [offres, setOffres] = useState([]);
    const location = useLocation();
    const currentPath = location.pathname;
    const dispatch = useDispatch();
    const packid = useSelector(state => state.packReducer.selectedPackId);
    const { slug } = useParams(); // Récupérer le slug de l'URL


      useEffect(() => {
        if (checked === true) {
            dispatch(setTimeRedux('1 an'));
          } else if(checked === false) {
            dispatch(setTimeRedux('1 mois')); // Note que j'ai aussi corrigé l'appel à dispatch ici
          }
    }, [checked]);
    useEffect(() => {
        if (currentPath !== null) {
            localStorage.setItem('currentpath', JSON.stringify(currentPath));
        }
    }, [currentPath]);

    // useEffect(() => {
        
    //     dispatch(setLocation(currentPath));
    //     const storedPackId = localStorage.getItem('selectedPackId');
    //     if (storedPackId && !packid) {
    //         dispatch(setSelectedPack(storedPackId));
    //     } else if (packid) {
    //         localStorage.setItem('selectedPackId', packid);
    //     }
    //     if (packid) {
    //         getOffresByPackId();
    //     }
    // }, [packid, dispatch]);

    useEffect(() => {
        
        getOffresByPackId();

    }, []);

    const getOffresByPackId = async () => {
        try {
            const response = await fetch('https://api.wemaza.com/api/offres/pack/676ec8a92010f89e0b7c29c8');
            /* const response = await fetch(`https://api.wemaza.com/api/offres/pack/${packid}`); */
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur inconnue');
            }
            const datas = await response.json();
            console.log('ggggg', datas);
            setOffres(datas);
        } catch (err) {
            console.log('Erreur de connexion. Vérifiez votre réseau.');
        }
    };

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(21px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    // Conditionner l'affichage du composant selon le slug
   /*  if (slug === 'pack-personnalise') {
        return <Personnalise />;
    }

    if (
        slug === 'pack-besoin-informatique' ||
        slug === 'pack-sante' ||
        slug === 'pack-gestion-du-domicile'
    ) */ 
        return (
            <div style={{ fontFamily: "Poppins" }}> 
                {/* <div className='d-flex justify-content-center m-5' style={{ color: 'black' }}>
                <img src="./assets/images/crown.png"  height={100} className='imageCrown'/>
                <p style={{fontSize:59,marginTop:30,fontWeight:'bold'}}>Tarifs</p>
                </div> */}
               {/*  <p style={{textAlign:'center'}}>Accédez à des services de santé personnalisés, à des solutions énergétiques fiables <br/>et à des options éducatives adaptées, le tout pour simplifier <br/>la vie de vos proches.</p> */}
               <p style={{textAlign:'center'}}>Accédez à vos offres personnalisées, avec des services conçus pour répondre aux besoins de vos proches.</p>
                <div className="container-fluid">
                    <div style={{ textAlign: "center",fontWeight:'bold' }} className='d-flex justify-content-center'>
                        {/* Je m’engage et économise jusqu'à <span style={{ color: "#e8ab03", paddingRight: 5 }}>4€/mois</span> */}
                       <p style={{marginRight:10 ,marginTop:15}}>1 mois</p> 
                        <FormControlLabel style={{ paddingTop: 9 }}
                            control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={handleSwitchChange} />}
                        />
                        <p style={{marginLeft:-10,marginTop:15}}>1 an</p> 
                        <p style={{color:'rgb(123, 123, 122)',marginTop:15,marginLeft:10}}>Jusqu'à 4€ de réduction par an</p>
                    </div>
                    <div className='container-fluid d-flex flex-wrap justify-content-center mt-3' style={{ marginTop: 50 }}>
                        {offres.length > 0 ? (
                            offres.map((offre) => (
                                <Offre key={offre.id} pack={offre} checked={checked} setChecked={setChecked} />
                            ))
                        ) : (
                            <p>No offre available</p>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    // Afficher Page404 si le slug ne correspond à aucune route
   /*  return <Page404 />; */


export default Pricecing2;

const imageCrown ={
   width:'20px'
}

