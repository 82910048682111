import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
    return (
        <div style={{margin:250}}>
            <h2>not found</h2>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default Page404;