import axios from 'axios'
import store from './store'
import * as actions from './store/actions'

axios.defaults.baseURL = 'https://api.wemaza.com/api';
// axios.defaults.baseURL = 'http://192.168.100.136:8080/v1';
// axios.defaults.baseURL = 'http://192.168.100.223:8080/v1';
// axios.defaults.baseURL = 'http://192.168.100.136:8083/v1';
// axios.defaults.baseURL = 'http://192.168.37.61:8080/v1';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';


if (localStorage.getItem('jwt_token_user')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token_user')}`;
} else {
  localStorage.removeItem('isVerify');
  localStorage.removeItem('jwt_token_presta');
  localStorage.removeItem('jwt_token_pres');
  localStorage.removeItem('jwt_token');
}

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(actions.authLogout())
      // window.location.reload();
      window.location.href = `/login`;
    }
    return Promise.reject(error);
  }
);

// if (localStorage.getItem('mso') && localStorage.getItem('mso') === true) {
//     axios.interceptors.response.use(
//     response => response,
//     (error) => {
//         if(error.response.status === 401 ) {
//             return axios
//         .post("/clients/web/admin/refresh/", {
//           refresh_token: localStorage.getItem('refresh_token'),
//         })
//         .then((res) => {
//           store.dispatch(actions.authLogin(res.data));
//         }) .catch((error) => {
//           store.dispatch(actions.authLogin({access_token: "", refresh_token: "" }));

//           store.dispatch(actions.authLogout())
//           Promise.reject(error);
//         });

//         }
//         return Promise.reject(error);
//     }
//   );
// } else {

//     axios.interceptors.response.use(
//     response => response,
//     (error) => {
//         if(error.response.status === 401 ) {
//             store.dispatch(actions.authLogout())
//             window.location.reload();
//         }
//         return Promise.reject(error);
//     }
//   );
// }


export default axios;
