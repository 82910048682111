import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { login, priceByCurrrency, serviceBeneficiairie, verification } from '../../../services/auth/authService';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { verificationModalF } from '../../../store/actions';

const MySwal = withReactContent(Swal);
const Verify = (props) => {
  const location = useLocation();
  const currentPathVerify = location.pathname;
  const [verificationCode_, setverificationCode] = useState(new Array(6).fill(''));
  const [userInfo, setUserInfo] = useState(null);
  const inputRefs = useRef([]);
  const mdp = useSelector((state) => state.__ct.mdp)
  const email = useSelector((state) => state.__ct.email)
  console.log('email ttttttttt', email)
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleSubmit = (event) => {
    event.preventDefault();
    const verificationCode = verificationCode_.join('');
    console.log('Code de vérification:', verificationCode);
    console.log('Email de l\'utilisateur:', email);

    const credentials = { email, verificationCode };
    const credential = { email, password: mdp };
    console.log('currentpath llllllllllllll', props.currentPath);
    const currentPath = props.currentPath;

    dispatch(verification(credentials, navigate))
      .then(() => {
        if (currentPath === '/register') {
          dispatch(verificationModalF());
          handleVerificationcodeok(navigate)
        } else if (currentPath === "/linked-protector-beneficiary-account") {
          dispatch(verificationModalF());
          handleVerification(credential, navigate);
        } else if (currentPathVerify === "/verify") {
          handleVerificationcodeok(navigate);
        }
        // else{
        //   handleVerificationcodeok(navigate)
        // }
      })

      .catch((error) => {
        console.error("Erreur lors de la connexion", error);
        dispatch(verificationModalF());
        // navigate('/verify');
        // window.location.reload();
      });
  };
  // const beneficiary_id = localStorage.getItem('savedIdBeneficiary_id');
  const navigateTrueFalse = useSelector(state => state.packReducer.navigateTrueFalse)
  const valeurRecuperee = localStorage.getItem('maCle')
  console.log('valeurRecuperee aaaaaaaaa', valeurRecuperee)
  // const handlesante = (navigate) => {
  //     if (valeurRecuperee === 'false') {
  //         navigate(`/paiement/${beneficiary_id}`) 
  //     }
  //     else if (valeurRecuperee === 'true'){
  //         navigate('/Intermediary')
  //     }
  // }
  const handleVerificationcodeok = (navigate) => {
    MySwal.fire({
      title: "Votre compte a été vérifié",
      text: "Veuillez vous reconnecter",
      icon: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: true,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
    }).then((result) => {
      if (result.isConfirmed) {

        navigate("/login");
      }
    });
  };

  // const handleVerificationcode = (e) => {
  //   e.preventDefault();
  //   MySwal.fire({
  //     title: "Votre code est incorrect",
  //     text: "Veuillez reessayer",
  //     icon: "error",
  //     confirmButtonText: "Ok",
  //     allowOutsideClick: false,
  //   }).then((result) => {});
  // };
  const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
  const serviceFilter = servicesLocal1.filter(service => service.status === true);
  // const totalAmount = JSON.parse(localStorage.getItem('totalAmount')) || null;

  const handleVerification = (credential, navigate) => {
    MySwal.fire({
      title: "Votre compte a été vérifié",
      text: "Veuillez vous reconnecter",
      icon: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: true,
    }).then((result) => {
      if (result.isConfirmed) {

        loginFoction(credential, navigate);

      }
    })
  };

  function retrieveServices(serviceFilter) {
    return serviceFilter.map(service => {
      const documentFilesKey = `selectedFiles_${service._id}`;
      const storedFiles = localStorage.getItem(documentFilesKey);

      let documentFiles = [];
      if (storedFiles) {
        try {
          const parsedFiles = JSON.parse(storedFiles);

          // Vérifier si `parsedFiles` est un tableau ou un objet
          if (Array.isArray(parsedFiles)) {
            // Si c'est un tableau, on utilise `map` normalement
            documentFiles = parsedFiles.map(fileData => {
              return new File(
                [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
                fileData.name,
                {
                  type: fileData.type,
                  lastModified: fileData.lastModified
                }
              );
            });
          } else if (typeof parsedFiles === 'object' && parsedFiles !== null) {
            // Si c'est un objet, on l'encapsule dans un tableau
            documentFiles = [new File(
              [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
              parsedFiles.name,
              {
                type: parsedFiles.type,
                lastModified: parsedFiles.lastModified
              }
            )];
          } else {
            console.error("Les données stockées ne sont ni un tableau ni un objet valide:", parsedFiles);
          }
        } catch (error) {
          console.error("Erreur lors du parsing des fichiers stockés:", error);
        }
      }

      return {
        serviceId: service._id,
        cityId: service.cityId || '',
        details: service.description || '',
        price_to_pay: service.priceConvert || '',
        date_of_completion: service.date_of_completion || new Date().toISOString(),
        documentFiles: documentFiles  // Fichiers reconstruits
      };
    });
  }


  const retrievedServices = retrieveServices(serviceFilter);

  console.log('retrievedServices', retrievedServices);

  // const totalAmount = JSON.parse(localStorage.getItem('totalAmount'))
  // console.log('totalAmount', totalAmount)

  // const beneficiary_id = useSelector( state => state.packReducer.userBeneficiaryId)
  const beneficiary_id = localStorage.getItem('savedIdBeneficiary_id');
  const currencyCountry = useSelector((state) => state.packReducer.currencyCountry);
  const savedServiceList = JSON.parse(localStorage.getItem('newServiceList'));

  useEffect(() => {
    if (serviceFilter.length >= 1) {
      // localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
      //    dispatch(updateServicesReducer(serviceFilter));
      console.log('newServiceList88', retrievedServices);

      registerComponent5(retrievedServices)

    }
  }, []);
  const [newList, setNewList] = useState([])
  console.log('newServiceList66', newList);
  const registerComponent5 = async (services) => {
    const newServiceList2 = await Promise.all(
      services.map(async (service) => {
        try {
          const result = await dispatch(priceByCurrrency(currencyCountry, service.full_priceCFA));
          const amount = result.data.amount;

          return {
            ...service,
            NewPriceConvert: amount, // Ajoute la conversion de prix
          };
        } catch (e) {
          console.log('errors', e);
          return service; // Renvoyer le service sans conversion en cas d'erreur
        }
      })
    );

    console.log('newServiceList', newServiceList2);
    setNewList(newServiceList2);
    // dispatch(updateServicesReducer(newServiceList));
    return newServiceList2;
  };
  // const [formData_1, setFormData_1] = useState({
  //   // userBeneficiaryId: benefID ? benefID : savedIdBeneficiary_id,
  //   userBeneficiaryId: beneficiary_id,

  //   services: retrievedServices,
  //   totalAmount: totalAmount,

  // });

  // useEffect(() => {
  //     if(beneficiary_id !== null) registerComponent1(formData_1);

  // }, [beneficiary_id]); // Le hook s'exécute à chaque fois que formData_ change
  const totalAmount = JSON.parse(localStorage.getItem('totalAmount'));
  const registerComponent1 = () => {

    dispatch(serviceBeneficiairie(beneficiary_id, totalAmount, newList, savedServiceList))
      .then((result) => {
        console.log('result ', result);
        const id1 = result.data._id;
        // dispatch(setUserBeneficiaryId(null)); 

        if (valeurRecuperee === 'true') {
          navigate('/Intermediary')
          window.location.reload();

        }
        else {
          navigate(`/paiement/${id1}`)
          window.location.reload();
        }
        // console.log('id1 ', id1);
        // // dispatch(setServiceBeneficiaryId(id1));
        // console.log('id12 ', id1);
        // localStorage.setItem('savedId', id1);
        // console.log('id13 ', id1);

      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }
  const loginFoction = (credential, navigate) => {
    dispatch(login(credential))
      .then(() => {
        registerComponent1()
        //  handlesante(navigate);

      })
      .catch((error) => {
        // handleVerification();
        console.error("Erreur lors de la connexion", error);
        navigate('/login');
      });
  }

  const handleInputChange = (value, index) => {
    const newCode = [...verificationCode_];
    newCode[index] = value;

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    setverificationCode(newCode);
  };

  const styles = {
    verificationBox: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      width: '100%',
    },
    headerIcon: {
      fontSize: '2em',
      color: '#8bc53e',
    },
    title: {
      color: '#8bc53e',
      textAlign: 'center',
      marginBottom: '0',
      marginTop: '10px',
    },
    paragraph: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    inputGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    input: {
      width: '40px',
      height: '40px',
      textAlign: 'center',
      fontSize: '1.2em',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxSizing: 'border-box',
    },
    errorMessage: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '15px',
    },
    errorIcon: {
      marginRight: '10px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#8bc53e',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#7bb12e',
    }
  };

  return (
    <div className='d-flex justify-content-center' style={{ marginTop: 75, marginBottom: 200 }}>
      <div style={{ width: 500 }} className='d-flex align-items-center justify-content-center'>
        <div style={styles.verificationBox}>
          <div className="header">
            <i className="fas fa-envelope" style={styles.headerIcon} />
            <h1 style={styles.title}>Vérifiez votre Email</h1>
          </div>
          <p style={styles.paragraph}>
            Veuillez saisir le code de vérification envoyé à votre adresse électronique{' '}
            <span style={{ fontWeight: 'bold' }}> {email} :</span>
          </p>
          <form id="verificationForm" onSubmit={handleSubmit}>
            <div style={styles.inputGroup}>
              {verificationCode_.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  required
                  style={styles.input}
                  value={value}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  ref={el => inputRefs.current[index] = el}
                />
              ))}
            </div>
            <button type="submit" style={styles.button}>
              Confirmer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verify;