import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const VerifiedAlert = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Simuler une vérification d'email déjà utilisé
    const existingEmail = "test@example.com";
    if (email === existingEmail) {
      setError(true);  // Activer l'erreur si l'email est déjà utilisé
    } else {
      setError(false); // Désactiver l'erreur si l'email est valide
      // Soumettre le formulaire ou autre logique
      console.log("Email soumis:", email);
    }
  };
  const navigate =useNavigate()
  const handleVerification = () => {
    MySwal.fire({
      title: "Votre compte a été vérifié",
      text: "Veuillez vous reconnecter",
      icon: "success",
      confirmButtonText: "Ok",
      allowOutsideClick: false,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
    }).then((result) => {
      if (result.isConfirmed) {
        // Rediriger vers la page de connexion après confirmation
       navigate("/login");  // Remplacez '/login' par l'URL de votre page de connexion
      }
    });
  };

  return (
    <div style={{margin:200}}>
      <button onClick={handleVerification}>Vérifier le compte</button>

      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className={`form-control ${error ? "is-invalid" : ""}`} // Classe "is-invalid" pour rouge en cas d'erreur
          id="email"
          placeholder="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <small className={`form-text ${error ? "text-danger" : "text-muted"}`}>
          {error ? "Email existe déjà" : "Veuillez entrer un email valide"}
        </small>
      </div>
      <button type="submit" className="btn btn-primary">Soumettre</button>
    </form>
    </div>
  );
};

export default VerifiedAlert;
