import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    selectedPackId: null,
    services: [],
    serviceId: null,
    servicecheck: false,
    isActive: false,
    btndisabled: false,
    navigateTrueFalse: false,
    serviceCal: false,
    currencyCountry: 'EUR',
    totalAmount: 0,
    userBeneficiaryId: '',
    title: '',
    documentFiles: [],
    date_of_completion: '',
    currentPath: null,
    beneficiary_id: null,
    serviceBenifiaryID: '',
    keptKeys: [],
    symbol: '',
    currencyCurrentPath: '',

};

export const packReducer = (state = initialState, action) => {
    switch (action.type) {
        // case 'SET_SELECTED_PACK':
        //     return {
        //         ...state,
        //         selectedPackId: action.payload,
        //     };
        // case 'ADD_SERVICES':
        //     const serviceexist = state.services.some(service => service.id === action.payload.id);
        //     if (serviceexist) {
        //         return state;
        //     } else {
        //         return {
        //             ...state,
        //             services: [...state.services, action.payload],
        //         };
        //     };
        case 'UPDATE_SERVICE_STATUS_PACk':
            return {
                ...state,
                services: state.services.map(service =>
                    service._id === action.payload.serviceId
                        ? {
                            ...service, status: action.payload.status
                        }  // Utilise le statut envoyé
                        : service
                )
            };
        // case 'UPDATE_SERVICE_USER_BENEFICIARY':
        //     return {
        //         ...state,
        //         services: state.services.map(service =>
        //             service._id === action.payload.serviceId
        //                 ? {
        //                     ...service, userBeneficiaryId: action.payload.userBeneficiaryId
        //                 }  // Utilise le statut envoyé
        //                 : service
        //         )
        //     };
        case 'UPDATE_SERVICE_CITY':
            return {
                ...state,
                services: state.services.map(service =>
                    service._id === action.payload.serviceId
                        ? {
                            ...service, cityId: action.payload.cityId
                        }
                        : service
                )
            };
        case 'UPDATE_SERVICE_DATE_OF_COMPLETION':
            return {
                ...state,
                services: state.services.map(service =>
                    service._id === action.payload.serviceId
                        ? {
                            ...service, date_of_completion: action.payload.date_of_completion
                        }  // Utilise le statut envoyé
                        : service
                )
            };
        case 'UPDATE_SERVICE_DOCUMENT_FILES':
            return {
                ...state,
                services: state.services.map(service =>
                    service._id === action.payload.serviceId
                        ? {
                            ...service, documentFiles: action.payload.documentFiles
                        }  // Utilise le statut envoyé
                        : service
                )
            };
        case 'UPDATE_SERVICE_PRICE_CONVERT':
            return {
                ...state,
                services: state.services.map(service =>
                    service._id === action.payload.serviceId
                        ? {
                            ...service, priceConvert: action.payload.priceConvert
                        }  // Utilise le statut envoyé
                        : service
                )
            };
        case 'DOCUMENT_FILES':
            return {
                ...state,
                documentFiles: action.payload
            };
        case 'CURRENCYCURRENTPATH':
            return {
                ...state,
                currencyCurrentPath: action.payload
            };
        case 'SYMBOL':
            return {
                ...state,
                symbol: action.payload
            };
        case 'BENEFICIARY_ID':
            return {
                ...state,
                beneficiary_id: action.payload
            };
        case 'CURRENT_PATH':
            return {
                ...state,
                currentPath: action.payload
            }
        case 'DATE_OF_COMPLETION':
            return {
                ...state,
                date_of_completion: action.payload
            };
        case 'UPDATE_CURRENCY_COUNTRY':
            return {
                ...state,
                currencyCountry: action.payload
            };
        case 'SERVICECALA':
            return {
                ...state,
                serviceCal: true,
            };
        case 'SERVICECALD':
            return {
                ...state,
                serviceCal: false,
            };
        case 'SET_TOTAL_AMOUNT':
            return {
                ...state,
                totalAmount: action.payload
            };
        case 'SET_USER_BENEFICIARY_ID':
            return {
                ...state,
                userBeneficiaryId: action.payload
            };

        case 'SET_SERVICE_BENEFICIARY_ID':
            return {
                ...state,
                serviceBenifiaryID: action.payload
            };
        case 'SET_TITLE':
            return {
                ...state,
                title: action.payload
            };
        case 'SET_SERVICE':
            return {
                ...state,
                serviceId: action.payload,
            };
        case 'REMOVE_SERVICE':
            return {
                ...state,
                services: state.services.filter(service => service.id !== action.payload),
            };
        case 'SETISACTIVEVERIFICATIONT':
            return {
                ...state,
                isActive: true,
            };
        case 'UPDATE_SERVICES':
            return {
                ...state,
                services: action.payload // On remplace les services actuels par les nouveaux services
            };
        case 'SETISACTIVEVERIFICATIONF':
            return {
                ...state,
                isActive: false,
            };
        case 'SETISACTIVEBTNT':
            return {
                ...state,
                btndisabled: true,
            };
        case 'SETISACTIVEBTNF':
            return {
                ...state,
                btndisabled: false,
            };
        case 'NAVIGATETRUE':
            return {
                ...state,
                navigateTrueFalse: true,
            };
        case 'NAVIGATEFALSE':
            return {
                ...state,
                navigateTrueFalse: false,
            };
        case 'SET_LISTES_Pack':
            return {
                ...state,
                services: action.payload.map(item => ({
                    ...item,
                    status: false, // Ajoute la propriété status avec la valeur false
                    priceConvert: 0,
                    cityId: '',
                    date_of_completion: '',
                    documentFiles: []
                }))
            };
        case "CLEAR_LOCAL_STORAGE_EXCEPT":
            return {
                ...state,
                keptKeys: action.payload, // Met à jour la liste des clés conservées
            };
        default:
            return state;
    }
};
