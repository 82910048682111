import React from 'react';

const Services = () => {
  const buttonStyle = {
    position: 'relative',
    padding: '11px 16px',
    fontSize: '15px',
    lineHeight: '1.5',
    borderRadius: '3px',
    color: '#fff',
    backgroundColor: '#ffc100',
    border: '0',
    transition: '0.2s',
    overflow: 'hidden',
  };

  const fileInputStyle = {
    cursor: 'pointer',
    position: 'absolute',
    left: '0%',
    top: '0%',
    transform: 'scale(3)',
    opacity: 0,
  };

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = '#d9a400';
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = '#ffc100';
  };

  return (
    <div style={{margin:300}}>
      <div className="upload">
        <button
          type="button"
          style={buttonStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fa fa-upload" /> Upload File
          <input type="file" style={fileInputStyle} />
        </button>
      </div>
    </div>
  );
};

export default Services;
