import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    selectedPackId: null,
    services: [],
    serviseSante: [],
    serviceId: null,
    servicecheck: false,
    isActive: false,
    btndisabled: false,
    navigateTrueFalse: false,
    serviceCal: false,
};

export const santeReducer = (state = initialState, action) => {
    switch (action.type) {
        // case 'SET_SELECTED_PACK':
        //     return {
        //         ...state,
        //         selectedPackId: action.payload,
        //     };
        case 'ADD_SERVICES_SANTE':
            const serviceexist = state.services.some(service => service._id === action.payload._id);
            if (serviceexist) {
                return state;
            } else {
                return {
                    ...state,
                    services: [...state.services, action.payload],
                };
            };
        case 'SERVICECALLA':
            return {
                ...state,
                serviceCal: true,
            };
        case 'SERVICECALLD':
            return {
                ...state,
                serviceCal: false,
            };
        case 'UPDATE_SERVICE_SANTE':
            return {
                // ...state,
                // services: state.services.map(service =>
                //     service._id === action.payload._id
                //         ? { ...service, ...action.payload }
                //         : service
                // ),
                // ...state,
                // services: state.services.map(service =>
                //   service._id === action.payload
                //     ? { ...service, status: true } // Mettre le status à true pour le service correspondant
                //     : service
                // ),
                ...state,
                serviseSante: state.serviseSante.map(service =>
                    service._id === action.payload ? { ...service, status: true } : service
                )
            };
            case 'UPDATE_SERVICE_STATUS':
                return {
                    ...state,
                    serviseSante: state.serviseSante.map(service =>
                        service._id === action.payload.serviceId
                            ? { ...service, status: action.payload.status }  // Utilise le statut envoyé
                            : service
                    )
                };           
                case 'SET_LISTES':
                    return {
                        ...state,
                        serviseSante: action.payload.map(item => ({
                            ...item,
                            status: false  // Ajoute la propriété status avec la valeur false
                        }))
                    };      
        case 'SET_SERVICE_SANTE':
            return {
                ...state,
                serviceId: action.payload,
            };
        case 'REMOVE_SERVICE_SANTE':
            return {
                ...state,
                services: state.services.filter(service => service._id !== action.payload),
            };
        case 'SETISACTIVEVERIFICATIONT':
            return {
                ...state,
                isActive: true,
            };
        case 'SETISACTIVEVERIFICATIONF':
            return {
                ...state,
                isActive: false,
            };
        case 'SETISACTIVEBTNT':
            return {
                ...state,
                btndisabled: true,
            };
        case 'SETISACTIVEBTNF':
            return {
                ...state,
                btndisabled: false,
            };
        case 'NAVIGATETRUE':
            return {
                ...state,
                navigateTrueFalse: true,
            };
        case 'NAVIGATEFALSE':
            return {
                ...state,
                navigateTrueFalse: false,
            };
        default:
            return state;
    }
};
