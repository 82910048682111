"use client";
import * as React from "react";
import loadingpoint from "../../images/loadingpoint_wemaza.gif";
import wemaza_logo from "../../images/logo1.png";

function Splashscreen() {
  return (
    <main className="splashscreen-sp">
      <section className="container-sp">
        <figure className="imageWrapper-sp">
          <img
            src={wemaza_logo}
            alt="Primary splash screen image"
            className="primaryImage-sp"
          />
        </figure>
        <div class="loading-container-sp">
        <div class="dot-sp"></div>
        <div class="dot-sp"></div>
        <div class="dot-sp"></div>
        <div class="dot-sp"></div>
    </div>
      </section>
    </main>
  );
}

export default Splashscreen;