import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Parametre from '../../components/Parametre';
import Breadcrumbs from '@mui/material/Breadcrumbs';
/* import Link from '@mui/material/Link'; */
import HomeIcon from '@mui/icons-material/Home';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { order, priceByCurrrency, serviceBeneficiairie, transaction } from '../../../services/auth/authService';
import { clearLocalStorageExcept, setCurrentPathCurrency, setServiceBeneficiaryId, updateServicesReducer } from '../../../store/actions';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const NewPaiement = () => {
  const totalAmount = JSON.parse(localStorage.getItem('totalAmount'));

    const benefID = useParams();
    const currentPath = 'test1'
    const navigate = useNavigate();
    console.log('benefID', benefID)
    const itemsReducer = useSelector((state) => state.__ct.items);
    const idbenificiary = useSelector(state => state.packReducer.beneficiary_id);
    console.log('idbenificiary', idbenificiary)
    const itemsStored = JSON.parse(localStorage.getItem('itemsstored'));
    const currencyValue = localStorage.getItem('currencyValue');

    const items = itemsReducer && itemsReducer.length > 0 ? itemsReducer : itemsStored;
    const dispatch = useDispatch();
    const title = JSON.parse(localStorage.getItem('title')) || null;
    const services = useSelector(state => state.packReducer.services);
    const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
    const serviceFilter = servicesLocal1.filter(service => service.status === true);

    useEffect(() => {
        if (serviceFilter.length >= 1) {
            localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
            dispatch(updateServicesReducer(serviceFilter));

        }
    }, []);

    useEffect(()=>{
        dispatch(setCurrentPathCurrency(currentPath));
    },[currentPath])

    /* route */
    const locationRedux = useSelector((state) => state.__ct.location);
    const locationStored = JSON.parse(localStorage.getItem('currentpath'));
    // const locationChoix = locationRedux !== null? locationRedux:locationStored;
    const locationChoix = '/pack-sante';


    /* time */
    const timeStored = JSON.parse(localStorage.getItem('timeredux'));
    const timeredux = useSelector((state) => state.__ct.timeRedux);
    const time = timeredux !== null ? timeredux : timeStored;

    /*  offre */
    const offreRedux = useSelector((state) => state.__ct.pack);
    const offrestored = JSON.parse(localStorage.getItem('selectedOffreId'));
    const offre = offreRedux !== null ? offreRedux : offrestored;


    console.log('items sssssssssssssssssssssssssssssssssssssssss ', items);



    const subTotalAnnual = items !== null && items.reduce((total, pack) => {
        const price = pack.price.annual;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    const subTotalmonthly = items !== null && items.reduce((total, pack) => {
        const price = pack.price.monthly;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);


    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const theme = useTheme();

    // Breakpoints for responsiveness
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const navigateTrueFalse = useSelector(state => state.packReducer.navigateTrueFalse)
    const valeurRecuperee = localStorage.getItem('maCle')
    console.log('valeurRecupereekkjkkkkjjjjjjjj', valeurRecuperee)

    // const userBeneficiaryId = useSelector(state => state.packReducer.userBeneficiaryId)
    const servicesLocal = JSON.parse(localStorage.getItem('servicesLocal'));
    // const serviceFilter = servicesLocal.filter(service => service.status === true);
    // console.log('serviceFilter ', serviceFilter);
    // const services = serviceFilter.map(service => ({
    //     serviceId: service._id,  
    //     cityId: service.cityId || '', 
    //     details: service.description || '', 
    //     date_of_completion: service.date_of_completion || new Date().toISOString(),  
    //     documentFiles: service.documentFiles || [] 
    //   }));

    // function retrieveServices(serviceFilter) {
    //     return serviceFilter.map(service => {
    //         const documentFilesKey = `selectedFiles_${service._id}`;
    //         const storedFiles = localStorage.getItem(documentFilesKey);

    //         // Désérialiser les fichiers stockés et recréer les instances de `File`
    //         const documentFiles = storedFiles ? JSON.parse(storedFiles).map(fileData => {
    //             return new File(
    //                 [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
    //                 fileData.name,
    //                 {
    //                     type: fileData.type,
    //                     lastModified: fileData.lastModified
    //                 }
    //             );
    //         }) : [];

    //         return {
    //             serviceId: service._id,
    //             cityId: service.cityId || '',
    //             details: service.description || '',
    //             date_of_completion: service.date_of_completion || new Date().toISOString(),
    //             documentFiles: documentFiles  // Fichiers reconstruits
    //         };
    //     });
    // }
    // function retrieveServicesasd(serviceFilter) {
    //     return serviceFilter.map(service => {
    //         const documentFilesKey = `selectedFiles_${service._id}`;
    //         const storedFiles = localStorage.getItem(documentFilesKey);

    //         let documentFiles = [];
    //         if (storedFiles) {
    //             try {
    //                 const parsedFiles = JSON.parse(storedFiles);

    //                 // Vérifier si `parsedFiles` est un tableau ou un objet
    //                 if (Array.isArray(parsedFiles)) {
    //                     // Si c'est un tableau, on utilise `map` normalement
    //                     documentFiles = parsedFiles.map(fileData => {
    //                         return new File(
    //                             [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
    //                             fileData.name,
    //                             {
    //                                 type: fileData.type,
    //                                 lastModified: fileData.lastModified
    //                             }
    //                         );
    //                     });
    //                 } else if (typeof parsedFiles === 'object' && parsedFiles !== null) {
    //                     // Si c'est un objet, on l'encapsule dans un tableau
    //                     documentFiles = [new File(
    //                         [new Blob()],  // Le contenu du fichier est vide car non sauvegardé dans le localStorage
    //                         parsedFiles.name,
    //                         {
    //                             type: parsedFiles.type,
    //                             lastModified: parsedFiles.lastModified
    //                         }
    //                     )];
    //                 } else {
    //                     console.error("Les données stockées ne sont ni un tableau ni un objet valide:", parsedFiles);
    //                 }
    //             } catch (error) {
    //                 console.error("Erreur lors du parsing des fichiers stockés:", error);
    //             }
    //         }

    //         return {
    //             serviceId: service._id,
    //             cityId: service.cityId || '',
    //             details: service.description || '',
    //             date_of_completion: service.date_of_completion || new Date().toISOString(),
    //             documentFiles: documentFiles  // Fichiers reconstruits
    //         };
    //     });
    // }


    // const retrievedServices = retrieveServices(serviceFilter);

    // console.log('retrievedServices', retrievedServices);

    // const totalAmount = JSON.parse(localStorage.getItem('totalAmount'))
    // console.log('totalAmount', totalAmount)

    // const savedUserBeneficiaryId = localStorage.getItem('savedUserBeneficiaryId');
    // const savedIdBeneficiary_id = localStorage.getItem('savedIdBeneficiary_id');
    // const [formData_, setFormData_] = useState({
    //     // userBeneficiaryId: benefID ? benefID : savedIdBeneficiary_id,
    //     userBeneficiaryId: benefID.id,

    //     services: retrievedServices,
    //     totalAmount: totalAmount,

    // });
    // useEffect(() => {
    //     registerComponent(formData_);
    // }, []); // Le hook s'exécute à chaque fois que formData_ change

    // const registerComponent = (formData_) => {

    //     dispatch(serviceBeneficiairie(formData_.userBeneficiaryId, formData_.totalAmount, formData_.services))
    //         .then((result) => {
    //             console.log('result ', result);
    //             const id1 = result.data._id;
    //             console.log('id1 ', id1);
    //             dispatch(setServiceBeneficiaryId(id1));
    //             console.log('id12 ', id1);
    //             localStorage.setItem('savedId', id1);
    //             console.log('id13 ', id1);

    //         })
    //         .catch((e) => {
    //             console.log('errors ', e);
    //         });
    // }
    // const foundSymbol = localStorage.getItem('foundSymbol') ? localStorage.getItem('foundSymbol') : null;

    const currency = localStorage.getItem('currencyCode')
    const serviceBenifiaryID = useSelector(state => state.packReducer.serviceBenifiaryID);
    const [id, setId] = useState(serviceBenifiaryID || ''); // Initialiser avec serviceBenifiaryID si disponible
const foundSymbol = useSelector(state =>state.packReducer.symbol)
  
        useEffect(() => {
            if (serviceFilter.length >= 1) {
                // localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
                //    dispatch(updateServicesReducer(serviceFilter));
                registerComponent3(serviceFilter)
    
            }
        }, [foundSymbol]);
        const currencyCountry = useSelector((state) => state.packReducer.currencyCountry);
       const registerComponent3 = async (services) => {
        const newServiceList = await Promise.all(
            services.map(async (service) => {
                try {
                    const result = await dispatch(priceByCurrrency(currencyCountry, service.full_priceCFA));
                    const amount = result.data.amount;
    
                    return {
                        ...service,
                        NewPriceConvert: amount, // Ajoute la conversion de prix
                    };
                } catch (e) {
                    console.log('errors', e);
                    return service; // Renvoyer le service sans conversion en cas d'erreur
                }
            })
        );
    
        // console.log('newServiceList', newServiceList);
        localStorage.setItem('newServiceList', JSON.stringify(newServiceList));

        dispatch(updateServicesReducer(newServiceList));
        return newServiceList;
    };
        const [full_priceCFAcONVERT,setFull_priceCFAcONVERT]= useState(0)
        const arrondi = services
            .filter(service => service.status === true)
            .reduce((total, service) => total + parseFloat(service.full_priceCFA), 0);
      
         const registerComponent4 = () => {
                dispatch(priceByCurrrency(currencyCountry, arrondi))
                    .then((result) => {
                        const amount = result.data.amount;
                        setFull_priceCFAcONVERT(amount);
                    })
                    .catch((e) => {
                        console.log('errors ', e);
                    });
            }
            useEffect(()=>{
                if(currencyCountry && arrondi) {registerComponent4()}
            },[arrondi,currencyCountry])
    
        // localStorage.setItem('title', JSON.stringify(titleRedux));
        // const storedServices = JSON.parse(localStorage.getItem('totalAmount'));
        function arrondirSuperieurDeuxDecimales(nombre) {
            return Math.ceil(nombre * 100) / 100;
        }
        const totalAmount22 =Number( arrondirSuperieurDeuxDecimales(full_priceCFAcONVERT));
        // localStorage.setItem('totalAmount', JSON.stringify(totalAmount));
    useEffect(() => {
        if (serviceBenifiaryID) {
            setId(serviceBenifiaryID);
        }
    }, [serviceBenifiaryID]);

    console.log('id2222', id);

    const savedIdLocal = localStorage.getItem('savedId');
    console.log('savedIdLocal', savedIdLocal)

    // useEffect(
    //     () => {
    //         if (serviceBenifiaryID != null) {
    //             setId(serviceBenifiaryID)
    //         }
    //     }, [serviceBenifiaryID]
    // )

    const [formDataTransaction, setFormDataTransaction_] = useState({
        servicesBeneficiaryId: benefID.id,
        amount: totalAmount,
        currency: currency,
        description: "Message de description",
        redirectUrl: 'https://wemaza.com'


    });


    const registerComponentTransaction = (data) => {
        console.log('data', data)

        if (benefID.id) {
            dispatch(transaction(data))
                .then((result) => {
                    handleClearStorage();
                    window.location.href = result.data.checkout;
                    console.log('result ', result);
                })
                .catch((e) => {
                    console.log('errors ', e);
                    handleVerification(navigate)
                });
        }
    }
    const handleClearStorage = () => {
        const keysToKeep = ["authToken", "userInfo", "isAuthenticated", "isVerify", "loglevel", "trust:cache:timestamp", "i18nextLng", "ethereum-http://localhost:3000", "foundSymbol"]; // Les clés à garder
        dispatch(clearLocalStorageExcept(keysToKeep));
    };
    const handleVerification = (navigate) => {
        MySwal.fire({
            title: `${currencyValue} non prise en charge`,
            text: `Madame, Monsieur,
Veuillez nous excuser, le ${currencyValue} n'est pas encore supporté par notre plateforme.`,
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
        }).then((result) => {
            if (result.isConfirmed) {

                navigate('/ListeService')

            }
        })
    };


    return (
        <>
            <div className='container-fluid' role="presentation" style={{ marginTop: 25, marginLeft: 10 }} onClick={handleClick}>
                <div className="container mt-4" style={{ paddingBottom: '10px' }}>
                    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'rgb(30, 73, 155)' }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            <HomeIcon sx={{ mr: .5 }} className='breadcrumbs' />
                            Home
                        </Link>
                        {valeurRecuperee === 'true' ? <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService-santé'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link> : <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link>}
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/connected-protector-beneficiary-account'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Bénéficiaire
                        </Link>
                        {valeurRecuperee === "true" ? <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/Intermediary'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Intermediaire
                        </Link> : null}
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/paiement'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            Paiement
                        </Link>

                    </Breadcrumbs>
                </div>
            </div>
            <div className='container-fluid d-flex justify-content-center'>
                <div className='marginbottom130' >
                    <div style={{ marginBottom: 200, marginTop: 50, background: 'white' }} className='d-flex dflexresposive ' >

                        <div style={{ height: 360, borderRadius: 5, background: 'white', padding: "22px 30px 30px 30px" }}>
                            <p style={{ fontSize: 23, fontWeight: 'bold', marginLeft: 10 }}>Choisissez votre moyen de paiement</p>
                            {/* <button onClick={() => handleClearStorage()}> test </button> */}
                            <FormControl style={{ width: isSmallScreen ? "300px" : isMediumScreen ? "500px" : "725px" }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    {/* <div className='d-flex flex-wrap  align-items-center justify-content-between divblue1 mb-4' style={{ height: 115 }}  >
                                        <div className="form-check">
                                            <FormControlLabel id='testToot' value="carte-bancaire" control={<Radio />} label="Carte bancaire"  />
                                        </div>
                                        <div style={{ marginTop: 5 }}>
                                            <img src="/assets/images/38.webp" alt="Logo Carte Bancaire" style={imagepaiement} />
                                        </div>
                                    </div> */}

                                    {/* Option de paiement PayPal */}

                                    <div className='d-flex flex-wrap  align-items-center justify-content-between divblue1 mb-4' style={{ height: 115 }}>
                                        <div className="form-check">
                                            <FormControlLabel id='testToot' value="Carte-bancaire/Paypal" control={<Radio onClick={() => registerComponentTransaction(formDataTransaction)} />} label="Carte-bancaire / Paypal" />
                                        </div>
                                        <div style={{ marginTop: 5 }}>
                                            <img src="/assets/images/39.webp" alt="Logo PayPal" style={imagepaiement} />
                                        </div>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className='mt-4' >
                            {locationChoix === "/pack-personnalise" ? (
                                <div style={{ width: 300, height: 'auto', background: 'white' }}>
                                    <div>
                                        <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                        </div>
                                        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                            {items.length > 0 ? items.map((offre, index) => (
                                                <Parametre key={index} offre={offre} time={time} />
                                            )) : (
                                                <p>Aucun pack disponible</p>
                                            )}
                                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Total HT</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                    <p style={{ fontWeight: 'bold' }}>0 {foundSymbol}</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                locationChoix === "/pack-sante" ||
                                locationChoix === "/pack-gestion-du-domicile" ||
                                locationChoix === "/pack-besoin-informatique"
                            ) ? (
                                <div >
                                    <div style={{ minWidth: isSmallScreen ? "320px" : isMediumScreen ? "400px" : "400px", height: 'auto', background: 'white' }}>
                                        <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                        </div>
                                        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 15 }}>{title}</p>
                                            {services.length > 0 ? services.map((service, index) => (
                                                <Parametre key={index} service={service} />
                                            )) : (
                                                <p>Aucun pack disponible</p>
                                            )}
                                            <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Total HT</p>
                                                    {time === '1 an' ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalAnnual} {foundSymbol}</p>
                                                    ) : time === "1 mois" ? (
                                                        <p style={{ fontWeight: 'bold' }}>{subTotalmonthly} {foundSymbol}</p>
                                                    ) : null}
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                    <p style={{ fontWeight: 'bold' }}>0 {foundSymbol}</p>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>

                                                    <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{totalAmount} {foundSymbol}</p>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ) : null}



                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default NewPaiement;
const imageinfo = {
    height: 16,
    cursor: 'pointer',
    marginLeft: 1
}

const imagestyle = {
    marginTop: 7,
    marginLeft: 10,

    height: 16,
    marginRight: 10
}
const imagepaiement = {
    marginTop: 5,
    marginLeft: 10,

    height: 30,
    marginRight: 5
}
