import React, { useState, useEffect } from 'react';

function LocationInput() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [location, setLocation] = useState({
    country: '',
    city: '',
    neighborhood: '',
  });

  // Fonction pour obtenir la position géographique de l'utilisateur
  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Erreur de géolocalisation :", error);
        }
      );
    } else {
      alert('Votre navigateur ne supporte pas la géolocalisation.');
    }
  };

  // Utiliser l'API GeoDB ou Google Maps pour obtenir les détails de la position
  useEffect(() => {
    if (latitude && longitude) {
      fetch(`https://geocode.xyz/${latitude},${longitude}?geoit=json`)
        .then((response) => response.json())
        .then((data) => {
          setLocation({
            country: data.country,
            city: data.city,
            neighborhood: data.staddress || 'Inconnu',
          });
        })
        .catch((error) => console.error('Erreur lors de la récupération de la localisation:', error));
    }
  }, [latitude, longitude]);

  return (
    <div style={{margin:300}}>
      <button onClick={getGeolocation}>Obtenir ma localisation</button>
      
      <div>
        <label>Pays:</label>
        <input type="text" value={location.country} readOnly />
      </div>
      
      <div>
        <label>Ville:</label>
        <input type="text" value={location.city} readOnly />
      </div>
      
      <div>
        <label>Quartier:</label>
        <input type="text" value={location.neighborhood} readOnly />
      </div>
    </div>
  );
}

export default LocationInput;

