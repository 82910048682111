import { combineReducers } from 'redux';
// import __Ct from './Cstate';

import __iA from './Auth';
import __ct from './cardReducer';
import { packReducer } from './packReducer';
import { santeReducer } from './santeReducer';
import wishlistReducer from './wishlistReducer';


// const RootReducer = combineReducers({__iA,__Ct,__pt});
const RootReducer = combineReducers({__iA,__ct,packReducer,wishlistReducer,santeReducer});

export default RootReducer;