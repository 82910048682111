import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Verify from '../verfiy/Verify';
import { useDispatch, useSelector } from 'react-redux';
import { priceByCurrrency, register } from '../../../services/auth/authService';
import { emailexiste, setCurrentPath, setEmail, setMdp, updateServicesReducer, verificationModalT } from '../../../store/actions';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Parametre from '../../components/Parametre';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBar from '../geolocation/SearchBar';
import SearchBarH55 from '../geolocation/SearchBarH55';



const SecondPaiement = () => {
    const [address, setAddress] = useState("");
    const services = useSelector(state => state.packReducer.services);
    const [address_, setAddress_] = useState("");
    const [shortCountryName, setShortCountryName] = useState(null);
    const [shortCountryName_, setShortCountryName_] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [showCgu, setShowCgu] = useState(false);
    const [showCgv, setShowCgv] = useState(false);
    const [isShowBtn, setIsShowBtn] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberBeneficiary, setphoneNumberBeneficiary] = useState('')
    const [valid, setValid] = useState(true)

    const currentPath = location.pathname;
    useEffect(() => { dispatch(setCurrentPath(currentPath)) }, [currentPath]);
    const errors = useSelector((state) => state.__ct.erroremail)
    const erreur_ = useSelector((state) => state.__ct.message)

    const itemsReducer = useSelector((state) => state.__ct.items);
    const itemsStored = JSON.parse(localStorage.getItem('itemsstored'));
    const items = itemsReducer && itemsReducer.length > 0 ? itemsReducer : itemsStored;
    console.log('items kkkkkkkkkkkk', items)
    const foundSymbol = useSelector(state =>state.packReducer.symbol)
    // const foundSymbol = localStorage.getItem('foundSymbol') ? localStorage.getItem('foundSymbol') : null;




    // useEffect(() => {
    //     if (serviceFilter.length >= 1) {
    //         localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
    //     //    dispatch(updateServicesReducer(serviceFilter));
    //        registerComponent1(serviceFilter)

    //     }
    // },[]);

    const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
    const serviceFilter = servicesLocal1.filter(service => service.status === true);
    useEffect(() => {
        if (serviceFilter.length >= 1) {
            // localStorage.setItem('servicesWithTrueStatus', JSON.stringify(serviceFilter));
            //    dispatch(updateServicesReducer(serviceFilter));
            registerComponent1(serviceFilter)

        }
    }, [foundSymbol]);
    const currencyCountry = useSelector((state) => state.packReducer.currencyCountry);
   const registerComponent1 = async (services) => {
    const newServiceList = await Promise.all(
        services.map(async (service) => {
            try {
                const result = await dispatch(priceByCurrrency(currencyCountry, service.full_priceCFA));
                const amount = result.data.amount;

                return {
                    ...service,
                    NewPriceConvert: amount, // Ajoute la conversion de prix
                };
            } catch (e) {
                console.log('errors', e);
                return service; // Renvoyer le service sans conversion en cas d'erreur
            }
        })
    );

    console.log('newServiceList', newServiceList);
    localStorage.setItem('newServiceList', JSON.stringify(newServiceList));
    dispatch(updateServicesReducer(newServiceList));
    return newServiceList;
};
    const [full_priceCFAcONVERT,setFull_priceCFAcONVERT]= useState(0)
    const arrondi = services
        .filter(service => service.status === true)
        .reduce((total, service) => total + parseFloat(service.full_priceCFA), 0);
  
     const registerComponent2 = () => {
            dispatch(priceByCurrrency(currencyCountry, arrondi))
                .then((result) => {
                    const amount = result.data.amount;
                    setFull_priceCFAcONVERT(amount);
                })
                .catch((e) => {
                    console.log('errors ', e);
                });
        }
        useEffect(()=>{
            if(currencyCountry && arrondi) {registerComponent2()}
        },[arrondi,currencyCountry])

    // localStorage.setItem('title', JSON.stringify(titleRedux));
    // const storedServices = JSON.parse(localStorage.getItem('totalAmount'));
    function arrondirSuperieurDeuxDecimales(nombre) {
        return Math.ceil(nombre * 100) / 100;
    }
    const totalAmount =Number( arrondirSuperieurDeuxDecimales(full_priceCFAcONVERT));
    localStorage.setItem('totalAmount', JSON.stringify(totalAmount));




    //   const registerComponent1 = () => {
    //             dispatch(priceByCurrrency(currencyCountry,Full_priceCFA))
    //                 .then((result) => {
    //                     const amount = result.data.amount;

    //                 })
    //                 .catch((e) => {
    //                     console.log('errors ', e);
    //                 });
    //         }


    const storedServices = JSON.parse(localStorage.getItem('servicesWithTrueStatus')) ? JSON.parse(localStorage.getItem('servicesWithTrueStatus')) : serviceFilter;
    // const totalAmount = JSON.parse(localStorage.getItem('totalAmount')) ? JSON.parse(localStorage.getItem('totalAmount')) : null;
    const title = JSON.parse(localStorage.getItem('title')) ? JSON.parse(localStorage.getItem('title')) : null;

    const locationRedux = useSelector((state) => state.__ct.location);
    const locationStored = JSON.parse(localStorage.getItem('currentpath'));
    /*   const locationChoix = locationRedux !== null ? locationRedux : locationStored; */
    const locationChoix = '/pack-sante';
    const [avatar, setAvatar] = useState(null);
    const [preview, setPreview] = useState(null);


    const handleAddressGeocode = (addr) => {

        setAddress(addr);
        // formValues.address = addr;
        // setFormValues({
        //   ...formValues
        // });
    }


    const getProfileschedulesWithMyLocation = (coord) => {

        setShortCountryName(coord.short_countryName);
    }
    const handleAddressGeocode_ = (addr) => {

        setAddress_(addr);
        // formValues.address = addr;
        // setFormValues({
        //   ...formValues
        // });
    }
    const getProfileschedulesWithMyLocation_ = (coord) => {

        setShortCountryName_(coord.short_countryName);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileURL = URL.createObjectURL(file);
            setPreview(fileURL); // Pour prévisualiser l'image localement
            setAvatar(file); // Stocker le fichier pour l'envoi au serveur
        }
    };
    useEffect(() => {
        if (avatar) {
            setFormData(prevState => ({
                ...prevState,
                avatar: avatar
            }));
        }
    }, [avatar]);
    useEffect(() => {
        if (address) {
            setFormData(prevState => ({
                ...prevState,
                location: address
            }));
        }
    }, [address]);
    useEffect(() => {
        if (address_) {
            setFormData_(prevState => ({
                ...prevState,
                location: address_
            }));
        }
    }, [address_]);


    const handleCGVChange = () => {
        if (showCgu === true && !showCgv === true) {
            setIsShowBtn(true);
        } else {
            setIsShowBtn(false);
        }
        setShowCgv(!showCgv);
    };

    const handleCGUChange = () => {
        if (!showCgu === true && showCgv === true) {
            setIsShowBtn(true);
        } else {
            setIsShowBtn(false);
        }
        setShowCgu(!showCgu);
    };


    console.log('items sssssssssssssssssssssssssssssssssssssssss ', items);
    const offreRedux = useSelector((state) => state.__ct.pack);
    const offrestored = JSON.parse(localStorage.getItem('selectedOffreId'));
    const timeStored = JSON.parse(localStorage.getItem('timeredux'));
    console.log('ooooooooooooooooooooooooooooo', offrestored)
    console.log('ttttttttttttttttttttttttttttt', offreRedux)
    console.log('yyyyyyyyyyyyyyyyyyyyyyyyyyyyy', timeStored)

    const offre = offreRedux !== null ? offreRedux : offrestored;
    const timeredux = useSelector((state) => state.__ct.timeRedux);

    const time = timeredux !== null ? timeredux : timeStored;
    /*  const handleTimeChange = (event) => { 
       
         const selectedTime = event.target.value; // Récupérer la nouvelle valeur sélectionnée
         setTime(selectedTime); // Mettre à jour l'état avec la nouvelle valeur
         
     }; */
    const subTotalAnnual = items !== null && items.reduce((total, pack) => {
        const price = pack.price.annual;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);

    const subTotalmonthly = items !== null && items.reduce((total, pack) => {
        const price = pack.price.monthly;
        return total + (price || 0); // On ajoute soit le prix annuel, soit le prix mensuel
    }, 0);


    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        avatar: avatar,
        phone: phoneNumber,
        location: address,
        role: 'protector',
    });
    const [formData_, setFormData_] = useState({
        name: '',
        sexe: '',
        id_User_protector: '',
        phone: phoneNumberBeneficiary,
        location: address_,
        role: 'beneficiary',
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        password: '',
        email: '',
        location: '',
    });
    const [formErrors_, setFormErrors_] = useState({
        name: '',
        location: ''
    });


    const handleChange_ = (e) => {
        const { name, value } = e.target;
        setFormData_({
            ...formData_,
            [name]: value
        });

        // Validation simple
        if (value.trim() === '') {
            setFormErrors_({
                ...formErrors_,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setFormErrors_({
                ...formErrors_,
                [name]: ''
            });
        }
    };



    const handleChangePhone = (value) => {
        setPhoneNumber(value);
        setValid(validphonenumber(value));
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const handleChangePhoneB = (value) => {
        setphoneNumberBeneficiary(value);
        setValid(validphonenumber(value));
        setFormData_((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const validphonenumber = (input) => {
        const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return patt.test(input);
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'email') {
            dispatch(setEmail(value));
        }
        // Validation simple
        if (value.trim() === '') {
            setFormErrors({
                ...formErrors,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setFormErrors({
                ...formErrors,
                [name]: ''
            });
        }
    };
    const navigateTrueFalse = useSelector(state => state.packReducer.navigateTrueFalse)
    const handlesante = () => {
        if (navigateTrueFalse === 'false') {
            navigate('/paiement')
        }
        else {
            navigate('/Intermediary')
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);
        console.log('Form data submitted:', formData_);

        dispatch(setMdp(formData.password));

        // Validation pour formData
        let formIsValid = true;
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if (field === 'avatar') {
                return;
            }

            if (formData[field].trim() === '') {
                newErrors[field] = 'Ce champ est obligatoire';
                formIsValid = false;
            }
        });

        setFormErrors(newErrors);  // Mettre à jour les erreurs pour formData

        // Validation pour formData_
        let formIsValid_ = true;
        const newErrors_ = {};
        Object.keys(formData_).forEach((field_) => {
            // Ignorer l'attribut id_User_protector
            if (field_ === 'id_User_protector') {
                return;
            }

            // Vérifier si le champ est vide
            if (formData_[field_].trim() === '') {
                newErrors_[field_] = 'Ce champ est obligatoire';
                formIsValid_ = false;
            }
        });
        setFormErrors_(newErrors_);  // Mettre à jour les erreurs pour formData_


        if (formIsValid) {
            console.log('is true 1')
        } else { console.log('is false 1') }
        if (formIsValid_) {
            console.log('is true 2')
        } else { console.log('is false 2') }

        if (formIsValid && formIsValid_) {
            try {
                console.log('ulrich 1');
                registerComponent(formData, formData_);
            } catch (error) {
                console.error("Erreur lors de la connexion", error);
            }
        } else {
            // Tu peux ajouter un message d'erreur ou une notification ici pour informer l'utilisateur que le formulaire n'est pas valide
            console.log("Le formulaire contient des erreurs");
        }
    };
    const registerComponent = (formData, formData_) => {
        console.log('ulrich 2')
        console.log('Form data submitted:', formData);
        console.log('Form data submitted:', formData_);
        dispatch(register(formData, formData_))
            .then((result) => {
                dispatch(verificationModalT());

            })
            .catch((e) => {
                console.log('errors ', e);
                if (e.error === "Ce mail existe déjà") {
                    dispatch(emailexiste());
                }
            });
    }

    /*  const handleregister = () => {
         try {
             dispatch(register(formData, formData_)).then(() => { setIsActive(true) })
         }
         catch (error) {
             dispatch(emailexiste());
             console.error("Erreur lors de la connexion", error);
         };
     } */
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    const theme = useTheme();

    // Breakpoints for responsiveness
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const isActive = useSelector((state) => state.packReducer.isActive)

    const valeurRecuperee = localStorage.getItem('maCle')
    console.log('valeurRecuperee aaaaaaaaa', valeurRecuperee)
   

    return (
        <>


            <section className="login-form container-fluid" style={{ marginBottom: 150, marginTop: "-30px" }}>
                <div className='container-fluid' role="presentation" style={{ marginTop: 25, marginLeft: '-15px' }} onClick={handleClick}>
                    <div className="container mt-4" style={{ paddingTop: '20px' }}>
                        <Breadcrumbs aria-label="breadcrumb" style={{ color: 'rgb(30, 73, 155)' }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={'/'}
                                style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                            >
                                <HomeIcon sx={{ mr: 1 }} className='breadcrumbs' />
                                Home
                            </Link>
                            {valeurRecuperee === 'true' ? <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={'/ListeService-santé'}
                                style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                            >
                                {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                                ListeService
                            </Link> : <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={'/ListeService'}
                                style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                            >
                                {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                                ListeService
                            </Link>}
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                to={'/linked-protector-beneficiary-account'}
                                style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                            >
                                {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                                protecteur et son bénéficiaire
                            </Link>

                        </Breadcrumbs>
                    </div>
                </div>

                {isActive ? (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
                        <Verify
                            currentPath={currentPath}
                        />
                    </div>
                ) : null}

                <div className="container mt-4" style={{ background: 'white', marginBottom: 200 }}>
                    <Link to={'/login'}
                        style={{ paddingTop: '10px', display: 'block' }}>
                        <p className="p-2" style={{ background: '#D7CCC8' /*  'rgba(56, 115, 184, 0.1)' */ }}><span style={{ color: '#333' }}>Déjà client ? </span>  <span style={{ fontSize: 16, color: 'rgb(30, 73, 155)' }}> Cliquez ici pour vous connecter </span> </p>
                    </Link>

                    <div className="row flex-column-reverse flex-lg-row">
                        {/* Left Section */}
                        <div className="col-12 col-lg-6 p-4">
                            <p className=" p-2" style={{ background: '#C5E1A5', fontWeight: 'bold' }}>Créez votre compte Protecteur </p>
                            <form onSubmit={handleSubmit}>

                                {/* <div className="upload">
                                    {preview != null ? (<img src={preview} width="100" height="100" alt="" />
                                    ) : <img src="./assets/images/noprofil.jpg" width={100} height={100} alt />}

                                    <div className="round">
                                        <input type="file" onChange={handleFileChange} />
                                        <i className="fa fa-camera" style={{ color: '#fff' }} />
                                    </div>
                                </div> */}
                                <div className="upload">
                                    <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                        {preview != null ? (
                                            <img src={preview} width="100" height="100" alt="" />
                                        ) : (
                                            <img src="./assets/images/noprofil.jpg" width={100} height={100} alt="" />
                                        )}

                                        <div className="round">
                                            <i className="fa fa-camera" style={{ color: '#fff' }} />
                                        </div>
                                    </label>

                                    <input
                                        type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange}
                                    />
                                </div>


                                <div>
                                    <label style={{ fontWeight: "normal", color: formErrors.name ? 'red' : 'black' }} htmlFor="name" className="form-label">Nom et Prénom</label>
                                    <input
                                        style={{ height: 55 }}
                                        type="text"
                                        className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                        id="name"

                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Entrez votre nom"
                                    />
                                    {formErrors.name && <small className="text-danger">Veuillez saisir votre nom et prénom</small>}
                                </div>
                                <div>
                                    <label style={{ fontWeight: "normal", color: formErrors.password ? 'red' : 'black' }} htmlFor="password" className="form-label">Mot de passe</label>
                                    <input
                                        style={{ height: 55 }}
                                        type="password"
                                        className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                                        id="password"
                                        name="password"

                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Entrez votre mot de passe"
                                    />
                                    {formErrors.password && <small className="text-danger">Veuillez saisir votre mot de passe</small>}
                                </div>
                                <div>
                                    <label style={{ fontWeight: "normal", color: formErrors.email ? 'red' : 'black' }} htmlFor="email" className="form-label">
                                        Email
                                    </label>
                                    <input
                                        style={{ height: 55 }}
                                        type="email"
                                        className={`form-control ${formErrors.email || errors ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"

                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Entrez votre email"
                                    />
                                    {errors && (
                                        <small className="text-danger" style={{ display: 'block', marginTop: 8 }}>
                                            Email existe déjà
                                        </small>
                                    )}
                                    {formErrors.email && (
                                        <small className="text-danger" style={{ display: 'block', marginTop: 8 }}>
                                            Veuillez saisir votre email
                                        </small>
                                    )}
                                </div>

                                <div  >
                                    <label style={{ fontWeight: "normal", color: formErrors_.phone ? 'red' : 'black' }} htmlFor="phone" className="form-label">Téléphone</label>
                                    <PhoneInput
                                        country={'fr'}
                                        className="phoneClass"
                                        type="tel"
                                        id="phone"
                                        name="phone"

                                        value={phoneNumber}
                                        onChange={handleChangePhone}
                                        placeholder="Entrez votre numéro de téléphone"
                                    />
                                    {formErrors_.phone && <small className="text-danger">Veuillez saisir votre numéro de teléphone</small>}
                                </div>
                                <div>
                                    <label style={{ fontWeight: "normal", color: formErrors.location ? 'red' : 'black' }} htmlFor="location1" className="form-label">Adresse</label>
                                    {/* <input
                                        style={{ height: 55 }}
                                        type="text"
                                        className={`form-control ${formErrors.location ? 'is-invalid' : ''}`}
                                        id="location1"
                                        name="location"

                                        value={formData.location}
                                        onChange={handleChange}
                                        placeholder="Entrez votre adresse"
                                    /> */}
                                    <SearchBarH55
                                        name="address"
                                        address={address}
                                        handleAddressGeocode={handleAddressGeocode}
                                        getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
                                        classNameVal={`input-field form-control ${formErrors.location ? 'is-invalid' : ''}`}
                                    />
                                    {formErrors.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                </div>
                                {/* <button type="submit" className="btn btn-success">Soumettre</button> */}
                                {/*  </form> */}
                                {/*  </div>
                    <div className=" mt-4">
                        <div style={{ padding: 10 }}> */}
                                <div style={{ marginTop: 15 }}>
                                    <p style={{ background: "#C5E1A5", padding: 10, fontWeight: 'bold' }}>Créez le compte de votre bénéficiaire </p>
                                </div>
                                {/* <form onSubmit={handleSubmit}> */}
                                <div  >
                                    <label style={{ fontWeight: 'normal', color: formErrors_.name ? 'red' : 'black' }} htmlFor="name1" className="form-label">Nom et Prénom</label>
                                    <input
                                        style={{ height: 55 }}
                                        type="text"
                                        className={`form-control ${formErrors_.name ? 'is-invalid' : ''}`}
                                        id="name1"
                                        name="name"
                                        value={formData_.name}
                                        onChange={handleChange_}
                                        placeholder="Entrez votre nom"
                                    />
                                    {formErrors_.name && <small className="text-danger">Veuillez saisir votre nom et prénom</small>}
                                </div>
                                <div className="mb-3 ">
                                    <label style={{ fontWeight: "normal", color: formErrors_.sexe ? 'red' : 'black' }} className="form-label mr-4">Sexe</label>
                                    <div className='d-flex'>
                                        <div className="form-check mr-2">
                                            <input
                                                className={`form-check-input ${formErrors_.sexe ? 'is-invalid' : ''}`}
                                                type="radio"
                                                name="sexe"

                                                id="sexeMale"
                                                value="male"
                                                checked={formData_.sexe === 'male'}
                                                onChange={handleChange_}
                                            />
                                            <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeMale">
                                                Masculin
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className={`form-check-input ${formErrors_.sexe ? 'is-invalid' : ''}`}
                                                type="radio"
                                                name="sexe"

                                                id="sexeFemale"
                                                value="female"
                                                checked={formData_.sexe === 'female'}
                                                onChange={handleChange_}
                                            />
                                            <label style={{ fontWeight: "normal" }} className="form-check-label" htmlFor="sexeFemale">
                                                Féminin
                                            </label>
                                        </div>
                                    </div>
                                    {formErrors_.sexe && <small className="text-danger">Veuillez sélectionner votre sexe</small>}
                                </div>

                                <div  >
                                    <label style={{ fontWeight: "normal", color: formErrors_.phone ? 'red' : 'black' }} htmlFor="phone" className="form-label">Téléphone</label>

                                    <PhoneInput
                                        country={'cm'}
                                        type="tel"
                                        className="phoneClass"
                                        id="phone"
                                        name="phone"

                                        value={phoneNumberBeneficiary}
                                        onChange={handleChangePhoneB}
                                        placeholder="Entrez votre numéro de téléphone"
                                    />
                                    {formErrors_.phone && <small className="text-danger">Veuillez saisir votre numéro de teléphone</small>}
                                </div>
                                <div  >
                                    <label style={{ fontWeight: "normal", color: formErrors_.location ? 'red' : 'black' }} htmlFor="location" className="form-label">Adresse</label>
                                    {/* <input
                                        style={{ height: 55 }}
                                        type="text"
                                        className={`form-control ${formErrors_.location ? 'is-invalid' : ''}`}
                                        id="location"
                                        name="location"

                                        value={formData_.location}
                                        onChange={handleChange_}
                                        placeholder="Entrez votre adresse"
                                    /> */}
                                    <SearchBarH55
                                        name="address"
                                        address={address_}
                                        handleAddressGeocode={handleAddressGeocode_}
                                        getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation_}
                                        classNameVal={`form-control ${formErrors_.location ? 'is-invalid' : ''}`}
                                    />
                                    {formErrors_.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                </div>
                                {/*  <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showCGVCheckbox"

                                        checked={showCgv}
                                        onChange={handleCGVChange}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="showCGVCheckbox"
                                    >
                                        Condition generale de vente (CGV)
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showCguBox"

                                        checked={showCgu}
                                        onChange={handleCGUChange}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="showCguBox"
                                    >
                                        Condition generale d'utilisation (CGU)
                                    </label>
                                </div> */}
                                <div className="form-check checkboxclass1">
                                    <input
                                        className="form-check-input "
                                        type="checkbox"
                                        id="showCGVCheckbox"
                                        checked={showCgv}
                                        onChange={handleCGVChange}
                                    />
                                    <label className="form-check-label labelInscr"
                                        /*  htmlFor="showCGVCheckbox" */
                                        style={{ fontSize: 14 }}

                                    >
                                        J'accepte les  <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}>  conditions générales de vente </span> de Wemaza et la   <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}> politique données personnelles </span>
                                    </label>
                                </div>

                                <div className="form-check checkboxclass">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showCguBox"

                                        checked={showCgu}
                                        onChange={handleCGUChange}
                                    />
                                    <label className="form-check-label labelInscr ml-1"
                                        /*  htmlFor="showCguBox" */
                                        style={{ fontSize: 14 }}
                                    >
                                        J'accepte les <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}>  conditions générales d'utilisation  </span> de Wemaza
                                    </label>
                                </div>
                                {erreur_ != "" ? (
                                    <p
                                        style={{
                                            color: "#721c24",
                                            textAlign: "center",
                                            backgroundColor: "#f8d7da",
                                            padding: 10,
                                        }}
                                    >
                                        {erreur_}
                                    </p>) : null}

                                {isShowBtn ?
                                    <button type="submit" className="btn btn-success" >Soumettre</button>

                                    :
                                    <button type="submit" className="btn btn-success" disabled style={{ cursor: 'not-allowed' }}>Soumettre</button>

                                }

                            </form>
                        </div>

                        {/* Right Section */}
                        <div /* style={{marginTop: '-410px'}} */ className="col-12 col-lg-6  d-flex flex-column align-items-center">

                            <div className=' mt-1'>

                                {locationChoix === "/pack-personnalise" ? (
                                    <div className='widthRecapitulatif' style={{ height: 'auto', background: 'white' }}>
                                        <div>
                                            <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                                <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                            </div>
                                            <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                                {items.length > 0 ? items.map((offre, index) => (
                                                    <Parametre key={index} offre={offre} time={time} />
                                                )) : (
                                                    <p>Aucun pack disponible</p>
                                                )}
                                                <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontSize: 15 }}>Total HT</p>
                                                        {time === '1 an' ? (
                                                            <p style={{ fontWeight: 'bold' }}>{subTotalAnnual}{foundSymbol}</p>
                                                        ) : time === "1 mois" ? (
                                                            <p style={{ fontWeight: 'bold' }}>{subTotalmonthly}{foundSymbol}</p>
                                                        ) : null}
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                        <p style={{ fontWeight: 'bold' }}>0{foundSymbol}</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>
                                                        {time === '1 an' ? (
                                                            <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalAnnual}{foundSymbol}</p>
                                                        ) : time === "1 mois" ? (
                                                            <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{subTotalmonthly}{foundSymbol}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    locationChoix === "/pack-sante" ||
                                    locationChoix === "/pack-gestion-du-domicile" ||
                                    locationChoix === "/pack-besoin-informatique"
                                ) ? (
                                    <div >
                                        <div style={{ minWidth: isSmallScreen ? "320px" : isMediumScreen ? "400px" : "400px", height: 'auto', background: 'white' }}>
                                            <div style={{ borderRadius: '10px 10px 0px 0px ', background: '#1e499b' }}>
                                                <p style={{ fontWeight: 'bold', fontSize: 18, padding: 10, color: 'white' }}>Récapitulatif</p>
                                            </div>
                                            <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
                                                <p style={{ fontWeight: 'bold', fontSize: 15 }}>{title}</p>
                                                {
                                                    services.map((service, index) => (
                                                        <Parametre key={index} service={service} />
                                                    ))
                                                }

                                                <div style={{ height: "1px", backgroundColor: "#ddd", flexGrow: 1 }}></div>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontSize: 15 }}>Total HT</p>
                                                        {time === '1 an' ? (
                                                            <p style={{ fontWeight: 'bold' }}>{subTotalAnnual}{foundSymbol}</p>
                                                        ) : time === "1 mois" ? (
                                                            <p style={{ fontWeight: 'bold' }}>{subTotalmonthly}{foundSymbol}</p>
                                                        ) : null}
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontSize: 15 }}>Taxes<img src="/assets/images/41.svg" alt="Logo" style={imageinfo} /></p>
                                                        <p style={{ fontWeight: 'bold' }}>0{foundSymbol}</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>Total TTC</p>

                                                        <p style={{ fontWeight: 'bold', color: '#00A74D' }}>{totalAmount}{foundSymbol}</p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}



                            </div>

                            {/* <div className='d-flex flex-column' style={{ alignItems: 'center' }}>
                                <h2 className="mt-5" style={{ fontSize: 25 }}>Prototype de carte du Bénéficiaire ci-dessous</h2>
                                <div className="credit-card p-3" style={{ border: '1px solid #ccc', borderRadius: 5 }}>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <img src="/assets/images/logo1.png" alt="Logo" className="avatar" />
                                        <AccountCircle style={{ fontSize: 40, color: '#1976d2' }} />
                                    </div>
                                    <div>
                                        <div className="number mb-1">1234 5678 9012 3456</div>
                                        <div className="name">John Doe</div>
                                        <div className="expiration">Exp: 12/24</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default SecondPaiement;

const imageinfo = {
    height: 16,
    cursor: 'pointer',
    marginLeft: 1
}