import React from 'react';

const ServiceComposant = (service) => {
    const foundSymbol = localStorage.getItem('foundSymbol') ? localStorage.getItem('foundSymbol') : null;
    const test = service.service
    console.log('test5675', test)
    const dateString = service.service.date_of_completion;
    const dateOnly = dateString.split('T')[0];
    // console.log(dateOnly); // Résultat : "2025-03-20"

    return (
        <div>
            <div style={{ fontSize: 13 }} className='d-flex'>
                <div style={{ marginRight: 15 }}>
                    <img src="http://localhost:3000/assets/images/blog-image4.jpg" width={50} height={50} />
                </div>
                <div className='d-flex' style={{ alignItems: 'center', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: 250 }} className='width220'>
                        <p style={{ fontSize: 12, color: '#6C6C6C', lineHeight: '12px', padding: 0, margin: 0 }}>{service.service.serviceId.title}</p>
                        <p className='mt-1' style={{ fontSize: 12, color: '#6C6C6C', lineHeight: '12px', padding: 0, margin: 0 }}>{dateOnly}</p>
                    </div>
                    <div style={{ width: 100 }} className='width0'>
                        <p style={{ fontSize: 12, margin: 0, textAlign: 'end', marginRight: 17,whiteSpace:'nowrap' }}>{foundSymbol} {service.service.price_to_pay}</p>
                    </div>
                </div>

            </div>
            <div style={{ borderBottom: '2px solid #F6F6F6', marginTop: 5, marginBottom: 5 }}></div>
        </div>
    );
};

export default ServiceComposant;