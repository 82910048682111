import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { btnModalF, btnModalT, serviceCallD, setDateOfCompletion, setDocumentFiles, UpdateCurrencyCountry, updateServiceCity, UpdateServiceDateOfCompletion, UpdateServiceDocumentFiles, updateServicePriceConvert, updateServiceStatusNew } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { priceByCurrrency } from '../../../services/auth/authService';



const MySwal = withReactContent(Swal);
const ServiceCallSante = ({ modificationStore, supprimerService }) => {
    const [finalDate2, setFinalDate2] = useState('')
    console.log('date_of_completion3', finalDate2)
    const [amount2, setAmount2] = useState()
    const [nbrejrs, setNbrejrs] = useState('');
    console.log('date_of_completion2', nbrejrs)
    const [currencyValue, setCurrencyValue] = useState('');

    const servicesLocal = JSON.parse(localStorage.getItem('servicesLocalSante')) || [];
    console.log('servicesLocalSante', servicesLocal)
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cities, setCities] = useState(null);
    const [citiesMap, setCitiesMap] = useState([])
    const [serviceLocal, SetServiceLocal] = useState(null);
    const [amount, setAmount] = useState(0);
    console.log('serviceLocal', serviceLocal)
    const priceLocal = serviceLocal && serviceLocal.cityId ? serviceLocal.cityId : 0;
    console.log('priceLocal', priceLocal)
    const documentFileLocal = serviceLocal && serviceLocal.documentFiles != null ? serviceLocal.documentFiles : null;
    console.log('documentFileLocal', documentFileLocal)
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Ajouter un zéro devant le mois si nécessaire
        const day = String(today.getDate()).padStart(2, '0'); // Ajouter un zéro devant le jour si nécessaire
        return `${year}-${month}-${day}`;
    };
    useEffect(() => {
        // Sauvegarder dans localStorage chaque fois que currencyValue change
        localStorage.setItem('currencyValue', currencyValue);
    }, [currencyValue]);


    const [ville, setVille] = useState('');
    const [selectedOption, setSelectedOption] = useState('option1');

    useEffect(() => {
        getCities();

    }, []);
    useEffect(() => {
        test(priceLocal)

    }, [priceLocal, currencyValue]);

    const test = (priceLocal) => {
        getPriceforService(priceLocal)
    }

    const getCities = async () => {
        try {
            const response = await axios.get(`/service-pricings/service/${service._id}`);


            const datas = response.data;
            setCities(datas);
            // console.log('gggggTT', datas[0].cityId.name)
        } catch (err) {
            if (err.response) {
                // La réponse du serveur avec un code d'erreur
                console.log(err.response.data.message || 'Erreur inconnue');
            } else if (err.request) {
                // Pas de réponse du serveur
                console.log('Pas de réponse du serveur.');
            } else {
                // Autre erreur
                console.log('Erreur de connexion. Vérifiez votre réseau.');
            }
        }
    };
    const [decimalPart, setDecimalPart] = useState(0);
    const hours = Math.floor(decimalPart * 24);  // Partie entière des heures


    const minutes = Math.floor((decimalPart * 24 % 1) * 60);  // Partie entière des minutes


    const seconds = Math.floor((minutes % 1) * 60);  // Partie entière des secondes


    const [full_price, setFull_price] = useState(0);

    const getPriceforService = async (cityVal) => {
        try {
            const response = await axios.get(`/service-pricings/${cityVal}/${service._id}`);
            const datas = response.data;

            setFull_price(datas[0].full_price);
            registerComponent(currencyValue, datas[0].full_price);


            const durationInHours = datas[0].duration;
            const days = Math.floor(durationInHours / 24);

            const dateInit = () => {
                console.log('test 23')
                if (date) {
                    // dispatch(UpdateServiceDateOfCompletion(service._id, finalDate2))
                    // hanldefinalDate(date)
                    const selectedDate = new Date(date);
                    // Ajouter le nombre de jours
                    selectedDate.setDate(selectedDate.getDate() + days);
                    console.log('vaaaaa3', selectedDate)
                    // Mettre à jour l'état avec la nouvelle date formatée
                    setFinalDate2(formatDate(selectedDate));

                }
            }
            if (durationInHours / 24 === days) {
                setNbrejrs(days);
                setDecimalPart(0);
            } else {
                setNbrejrs(days + 1);

            }
            // setNbrejrs(days);
            // setDecimalPart((durationInHours / 24) - days);


        } catch (err) {
            if (err.response) {
                // La réponse du serveur avec un code d'erreur
                console.log(err.response.data.message || 'Erreur inconnue');
            } else if (err.request) {
                // Pas de réponse du serveur
                console.log('Pas de réponse du serveur.');
            } else {
                // Autre erreur
                console.log('Erreur de connexion. Vérifiez votre réseau.');
            }
        }
    };

    const serviceReduxid = useSelector(state => state.packReducer.serviceId);

    // const serviceRedux_ = services.find(service => service._id === serviceReduxid);


    // console.log('serviceRedux aaaaaaaaaaaa', serviceRedux_)
    const service = useSelector(state =>
        serviceReduxid ? state.packReducer.services.find(service => service._id === serviceReduxid) : null
    );
    // const description = service.description;
    const id = service ? service._id : null;


    const currentDate = new Date();
    const currentDate2 = new Date();

    const formatDate = (date) => {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };

    //     const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     const hours = String(date.getHours()).padStart(2, '0');
    //     const minutes = String(date.getMinutes()).padStart(2, '0');
    //     const seconds = String(date.getSeconds()).padStart(2, '0');

    //     return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    // };



    currentDate.setDate(currentDate.getDate());
    const formattedDate = formatDate(currentDate);
    currentDate2.setDate(currentDate2.getDate() + nbrejrs);
    const finalDate1 = formatDate(currentDate2);
    console.log('date_of_completion4', finalDate1)

    const date_of_completion = service && service.date_of_completion ? service && service.date_of_completion : finalDate1;
    // const date_of_completion= finalDate2 ? finalDate2 : service && service.date_of_completion;
    console.log('date_of_completion', date_of_completion)
    const handleUpdateServices = (price) => {
        // dispatch(UpdateServicesSante(service._id, formData.location, formData.date, formData.w3review));

        // const newStatus = true; // ou !currentStatus pour inverser
        // dispatch(updateServiceStatusNew(id, newStatus)); // Dispatch de l'action depuis ce composant
        dispatch(serviceCallD());

        modificationStore(price, service.cityId, date_of_completion);
        // handleUpdateService();
    };
    const handleUpdateService = () => {
        const newStatus = true; // ou !currentStatus pour inverser
        dispatch(updateServiceStatusNew(id, newStatus)); // Dispatch de l'action depuis ce composant
    }
    const handleDeleteService = () => {
        // dispatch(removeServiceSante(id));
        const newStatus = false; // ou !currentStatus pour inverser
        dispatch(updateServiceStatusNew(id, newStatus)); // Dispatch de l'action depuis ce composant
        dispatch(serviceCallD());
        // deleteItemById(service._id);
    }
    // const deleteItemById = (idToDelete) => {

    //    console.log('idToDelete',idToDelete)
    //     const items= JSON.parse(localStorage.getItem('servicesLocal'))
    //     const updatedItems = items.filter(item => item._id !== idToDelete);
    //     localStorage.setItem('servicesLocal', JSON.stringify(updatedItems));

    //     console.log(`L'élément avec l'id ${idToDelete} a été supprimé`);
    // };

    // Fonction pour gérer le changement de radio
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const btndisabled = useSelector(state => state.packReducer.btndisabled);

    useEffect(
        () => {
            SetServiceLocal(servicesLocal.find(service => service._id === serviceReduxid))
        }, [serviceReduxid]
    )





    const verite = service && service.status ? service.status : (serviceLocal && serviceLocal.status ? serviceLocal.status : false);


    useEffect(() => {
        console.log("useEffect triggered with verite:", verite);
        if (verite) {
            console.log("Dispatching action btnModalF");
            dispatch(btnModalF());
        }
    }, [verite]); // Ajoutez verite comme dépendance

    const dispatch = useDispatch();

    const initialFormData = () => {
        // Vérifier si 'service' est défini et a un '_id'
        if (service && service._id) {
            const storedData = localStorage.getItem(`formData_${service._id}`);
            return storedData ? JSON.parse(storedData) : { location: '', w3review: '', date: getTodayDate() };
        }
        // Retourner des valeurs par défaut si 'service' n'est pas défini
        return { location: '', w3review: '', date: '' };
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        // Check if service is defined and has an _id before saving to localStorage
        if (service && service._id) {
            localStorage.setItem(`formData_${service._id}`, JSON.stringify(formData));
        }
    }, [formData, service]); // Include 'service' in the dependency array
    

    const handleCloseCallService = () => {
        dispatch(serviceCallD())
    }
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange_ = (event) => {
        setAge(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const [formErrors, setFormErrors] = useState({
        location: '',
        w3review: '',
        date: '',

    });
    const [valid, setValid] = useState(true)
    const handleChangePhone = (value) => {
        setPhoneNumber(value);
        setValid(validphonenumber(value));
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

  



    const date = getTodayDate()


    const dateInit = () => {
        // console.log('test 23')
        // if (date) {
        //     // dispatch(UpdateServiceDateOfCompletion(service._id, finalDate2))
        //     // hanldefinalDate(date)
        //     const selectedDate = new Date(date);
        //     // Ajouter le nombre de jours
        //     selectedDate.setDate(selectedDate.getDate() + nbrejrs);
        //     console.log('vaaaaa3', selectedDate)
        //     // Mettre à jour l'état avec la nouvelle date formatée
        //     setFinalDate2(formatDate(selectedDate));

        }
    



    useEffect(() => {
        console.log('')
        if( service && service._id){
            dispatch(UpdateServiceDateOfCompletion(service._id, finalDate2))
        }
        
    }, [finalDate2])

    const handleChangeDate = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        hanldefinalDate(value)
    }
    const hanldefinalDate = (value) => {

        // Récupérer la date sélectionnée et ajouter des heures manuellement (par exemple 00:00:00)
        // const selectedDate = new Date(value + 'T00:00:00');  // Ajouter une heure par défaut si non spécifiée

        // Ajouter les heures, minutes et secondes calculées
        // selectedDate.setHours(selectedDate.getHours() + Math.floor(hours));
        // selectedDate.setMinutes(selectedDate.getMinutes() + Math.floor(minutes));
        // selectedDate.setSeconds(selectedDate.getSeconds() + Math.floor(seconds));
        const selectedDate = new Date(value);
        console.log('vaaaaa', selectedDate)
        console.log('vaaaaa1', value)
        console.log('vaaaaa2', value + nbrejrs)
        // Ajouter le nombre de jours
        selectedDate.setDate(selectedDate.getDate() + nbrejrs);
        console.log('vaaaaa3', selectedDate)
        // Mettre à jour l'état avec la nouvelle date formatée
        setFinalDate2(formatDate(selectedDate));
    };

    const handleChangeService = (e) => {
        const { name, value } = e.target;
        console.log('name', name)
        console.log('value', value)


        dispatch(updateServiceCity(service._id, value));
        setFormData({
            ...formData,
            [name]: value,
        });
        // setCityId(value);
        getPriceforService(value);
        dateInit()
        // const today = getTodayDate();
        // hanldefinalDate(today); 
    }
    // console.log('cityidservice', service.cityId)


    const registerComponent = (currencyValue, full_price) => {
        dispatch(priceByCurrrency(currencyValue, full_price))
            .then((result) => {
                const amount = result.data.amount;

                setAmount(amount);

            })
            .catch((e) => {
                console.log('errors ', e);
            });
    }
    const validphonenumber = (input) => {
        const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return patt.test(input);
    }
    const serviceId = useSelector(state => state.packReducer.serviceId)
    const handleSubmit = (e) => {


        e.preventDefault();
        if (avatars.length > 0) {
            const data = { avatars };
            console.log("Données à envoyer à l'API : ", data);
        }
        console.log('Form data submitted:', formData);

        // Validation pour formData
        let formIsValid = true;
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if (formData[field].trim() === '') {
                newErrors[field] = 'Ce champ est obligatoire';
                formIsValid = false;
            }
        });

        setFormErrors(newErrors);
        console.log('formIsValid', formatDate)
        if (formIsValid) {

            try {
                dispatch(priceByCurrrency(currencyValue, full_price, serviceId)).then((res) => {
                    dispatch(updateServicePriceConvert(serviceId, res.data.amount));
                    // dispatch(UpdateServices(formData.location,formData.date,formData.location))
                    handleUpdateServices(res.data.amount)

                    dispatch(btnModalF())
                })
            } catch (error) {
                console.error('Erreur lors de l\'inscription', error);
            }
        }
    }

    const [avatars, setAvatars] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    console.log('selected aaa', selectedFiles)
    const [previews, setPreviews] = useState([]);
    console.log('previews', previews)
    const [fileNames, setFileNames] = useState([]); // Nouvel état pour les noms de fichiers
    // useEffect(() => {
    //     if (selectedFiles) {
    //         console.log('selectedFiles', selectedFiles);
    //         dispatch(UpdateServiceDocumentFiles(service._id, selectedFiles));

    //     }
    // }, [selectedFiles])

    // Au chargement de la page, récupérer les fichiers stockés dans le localStorage
    const serializeFile = (file) => {
        return {
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified
        };
    };
    const saveSelectedFilesToLocalStorage = (selectedFiles) => {
        // Vérifie s'il y a un ou plusieurs fichiers
        if (selectedFiles.length === 1) {
            // Cas d'un seul fichier
            const serializedFile = serializeFile(selectedFiles[0]);
            localStorage.setItem(`selectedFiles_${service._id}`, JSON.stringify(serializedFile));
        } else if (selectedFiles.length > 1) {
            // Cas de plusieurs fichiers
            const serializedFiles = Array.from(selectedFiles).map(serializeFile);
            localStorage.setItem(`selectedFiles_${service._id}`, JSON.stringify(serializedFiles));
        } else {
            console.log('No files selected');
        }
    };
    useEffect(() => {
        const storedselectedFiles = JSON.parse(localStorage.getItem(`selectedFiles_${service._id}`)) || [];
        const storedPreviews = JSON.parse(localStorage.getItem(`previews_${service._id}`)) || [];
        const storedAvatars = JSON.parse(localStorage.getItem(`avatars_${service._id}`)) || [];
        const storedFileNames = JSON.parse(localStorage.getItem(`fileNames_${service._id}`)) || [];

        setSelectedFiles(storedselectedFiles)
        setPreviews(storedPreviews);
        setAvatars(storedAvatars);
        setFileNames(storedFileNames);
    }, []);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        console.log('files', files)

        // setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        setSelectedFiles((prevFiles) => {
            const updatedSelectedFiles = [...(Array.isArray(prevFiles) ? prevFiles : []), ...files];
            console.log('updatedSelectedFiles', updatedSelectedFiles)
            saveSelectedFilesToLocalStorage(updatedSelectedFiles)
            // Sauvegarder dans le localStorage
            return updatedSelectedFiles;
        });

        // Préparer les aperçus des fichiers
        const filePreviews = files.map((file) => {
            if (file.type === "application/pdf") {
                return "./assets/images/pdf.png";
            } else if (file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                return "./assets/images/word.png";
            } else if (file.type.startsWith("image/")) {
                return URL.createObjectURL(file);
            } else {
                return null;
            }
        }).filter(Boolean);

        // Ajouter les nouveaux aperçus à l'existant
        setPreviews((prev) => {
            const updatedPreviews = [...prev, ...filePreviews];
            localStorage.setItem(`previews_${service._id}`, JSON.stringify(updatedPreviews)); // Sauvegarder dans le localStorage
            return updatedPreviews;
        });

        // Lire les fichiers
        const fileReaders = files.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        });

        // Ajouter les noms des fichiers
        const newFileNames = files.map(file => file.name);
        setFileNames((prev) => {
            const updatedFileNames = [...prev, ...newFileNames];
            localStorage.setItem(`fileNames_${service._id}`, JSON.stringify(updatedFileNames)); // Sauvegarder dans le localStorage
            return updatedFileNames;
        });

        Promise.all(fileReaders).then((results) => {
            setAvatars((prev) => {
                const updatedAvatars = [...prev, ...results];
                localStorage.setItem(`avatars_${service._id}`, JSON.stringify(updatedAvatars)); // Sauvegarder dans le localStorage
                return updatedAvatars;
            });
        });

        // Réinitialiser la valeur de l'input
        event.target.value = "";
    };

    const handleRemovePreview = (index) => {

        // Supprimer l'élément à l'index donné dans chaque tableau
        const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
        const newPreviews = previews.filter((_, i) => i !== index);
        const newAvatars = avatars.filter((_, i) => i !== index);
        const newFileNames = fileNames.filter((_, i) => i !== index);

        // Mettre à jour les états
        setSelectedFiles(newSelectedFiles);
        setPreviews(newPreviews);
        setAvatars(newAvatars);
        setFileNames(newFileNames);

        // Mettre à jour le localStorage
        // localStorage.setItem(`selectedFiles_${service._id}`, JSON.stringify(newSelectedFiles));
        saveSelectedFilesToLocalStorage(newSelectedFiles)
        localStorage.setItem(`previews_${service._id}`, JSON.stringify(newPreviews));
        localStorage.setItem(`fileNames_${service._id}`, JSON.stringify(newFileNames));
        localStorage.setItem(`avatars_${service._id}`, JSON.stringify(newAvatars));
        // } else {
        //     console.error("Index invalide lors de la suppression.");
        // }
    };




    const buttonStyle = {
        position: 'relative',
        // padding: '11px 16px',
        fontSize: '15px',
        lineHeight: '1.5',
        borderRadius: '3px',
        color: '#fff',
        backgroundColor: 'white',
        border: '0',
        transition: '0.2s',
        overflow: 'hidden',
        margin: '5px'
    };
    const imageinfo = {
        height: 19,
        cursor: 'pointer',
        marginLeft: '7px'
    }
    const fileInputStyle = {
        cursor: 'pointer',
        position: 'absolute',
        left: '0%',
        top: '0%',
        transform: 'scale(3)',
        opacity: 0,
    };
    const [showText, setShowText] = useState(false);

    // Fonction pour montrer le texte lorsque la souris entre dans l'élément
    const handleMouseEnter_ = () => {
        setShowText(true);
    };

    // Fonction pour cacher le texte lorsque la souris quitte l'élément
    const handleMouseLeave_ = () => {
        setShowText(false);
    };
    // Fonction pour montrer le texte lorsque la souris entre dans l'élément
    const handleMouseEnter = () => {
        setShowText(true);
    };

    // Fonction pour cacher le texte lorsque la souris quitte l'élément
    const handleMouseLeave = () => {
        setShowText(false);
    };

    const descriptions = service && service.description ? service.description.split(',') : [];



    const [country, setCountry] = useState('');
    const [country3, setCountry3] = useState('');
    const [ip, setIp] = useState('');
    const [name, setName] = useState('');

    console.log('currencyValue', currencyValue)

    const [formDataCurrency, setFormDataCurrency] = useState({
        amount: full_price,
        currency: currencyValue,

    });
    console.log('formDataCurrency', formDataCurrency)


    useEffect(() => {
        const fetchGeoData = async () => {
            try {
                const response = await axios.get('/geo');
                const location = response.data[0];
                setCountry(location.country);
                setCountry3(location.country_3);
                setIp(location.ip);
                setName(location.name);

                let foundCurrency = '';
                let foundSymbol = '';
                // for (const [currencyCode, countries] of Object.entries(currency)) {
                //     if (countries.includes(location.name)) {
                //         foundCurrency = currencyCode;
                //         break;
                //     }
                // }
                // for (const [symboleCurrencyCode, countries] of Object.entries(currency)) {
                //     if (countries.includes(location.name)) {
                //         foundCurrency = symboleCurrencyCode;
                //         break;
                //     }
                // }
                for (const [currencyCode, data] of Object.entries(currency)) {
                    if (data.countries.includes(location.name)) {
                        foundCurrency = currencyCode;
                        foundSymbol = data.symbol;
                        break;
                    }
                }
                setCurrencyValue(foundCurrency || 'Devise non trouvée');
                dispatch(UpdateCurrencyCountry(foundCurrency));
                localStorage.setItem('foundSymbol', foundSymbol);


            } catch (err) {
                if (err.response) {
                    console.log(err.response.data.message || 'Erreur inconnue');
                } else if (err.request) {
                    console.log('Pas de réponse du serveur.');
                } else {
                    console.log('Erreur de connexion. Vérifiez votre réseau.');
                }
            }
        };

        fetchGeoData();
    }, []);

    // useEffect(() => {
    //     if(amount!= null){
    //         setAmount2(amount)
    //     }else if(serviceLocal.priceConvert != null){
    //         setAmount2(serviceLocal.priceConvert )
    //     }
    //     }, [amount]);

    const handleremovetout =()=>{
        // localStorage.removeItem(`currencyValue`);
        localStorage.removeItem(`avatars_${service._id}`);
        localStorage.removeItem(`fileNames_${service._id}`);
        localStorage.removeItem(`formData_${service._id}`);
        localStorage.removeItem(`previews_${service._id}`);
        localStorage.removeItem(`selectedFiles_${service._id}`);    
    }
    const handleremove =()=>{
        localStorage.removeItem(`avatars_${service._id}`);
        localStorage.removeItem(`fileNames_${service._id}`);
        localStorage.removeItem(`formData_${service._id}`);
        localStorage.removeItem(`previews_${service._id}`);
        localStorage.removeItem(`selectedFiles_${service._id}`);    
    }


    return (

        <div style={{ position: 'fixed', inset: 0, width: '100%', height: "100vh", backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'poppins' }}>
            {/* <button className='btn btn-primary'onClick={() => handltrue(serviceRedux.id)}>check</button> */}
            <div className='container-fluid d-flex  ' style={{ position: 'relative', justifyContent: 'center' }} >



                <div className='d-flex width400' style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', right: '-29px ', top: '-32px', cursor: 'pointer' }} onClick={() => { handleCloseCallService() }} >
                        <span style={{ display: 'flex', justifyContent: 'center', marginTop: '7px', alignItems: 'center', marginRight: '10px', width: '35px', height: '35px', padding: '7px', backgroundColor: "#000", borderRadius: '50%' }}>
                            <CloseRoundedIcon style={{ color: '#ffc107', fontSize: '20px' }} />
                        </span>
                    </div>
                    {/* alignItems: 'stretch'  */}
                    <div className='widhheight' style={{ width: 600, height: "700px", border: '1px solid white', borderRadius: '10px 0 0 10px', padding: 10, background: 'white', paddingTop: 25, overflow: 'auto' }}>
                        <div>
                            <p className='fontSizetext' style={{ fontSize: 28, fontWeight: 'bold' }}>Service choisi : {service && service.title}.<span> </span>
                                <span style={{ color: 'rgb(30, 73, 155)', fontSize: 14 }}>Description / documents requis</span>
                                <img src="/assets/images/41.svg" alt="Logo" style={imageinfo} onMouseEnter={handleMouseEnter_} onMouseLeave={handleMouseLeave_} />

                            </p>
                        </div>
                        {showText && (
                            <div className='positionDescription'
                                style={{
                                    position: 'absolute',
                                    top: '90px',
                                    left: '320px',
                                    transform: 'translateX(-50%)',
                                    width: 'auto',
                                    fontSize: 16,
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    zIndex: 2000, // Assurer que cette div est au-dessus des autres éléments
                                    textAlign: 'left'
                                }}
                            ><p style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'left', padding: 15, background: '#E8ECF5' }}>Description du service</p>
                                <ul style={{ padding: '10px 25px' }}>
                                    {descriptions.map((item, index) => (
                                        <li key={index} style={{ color: '#707274', lineHeight: '25px' }}>{item.trim()}</li> // trim() pour enlever les espaces en début et fin de chaque élément
                                    ))}
                                </ul>
                            </div>
                        )}
                        <form onSubmit={handleSubmit} style={{ marginLeft: 30, marginRight: 30, marginTop: 40 }}>

                            <div className='d-flex flex-wrap' style={{ width: 'auto', fontSize: '16px' }}>
                                <div className='mr-2' style={{ width: 275 }}>
                                    <label
                                        style={{ fontWeight: "normal", color: formErrors.location ? 'red' : 'black' }}
                                        htmlFor="location"
                                        className="form-label"
                                    >
                                        Ville de la course
                                    </label>
                                    <select
                                        className="form-select form-select-lg mb-3 form-control"
                                        aria-label=".form-select-lg example"
                                        style={{ height: 55, borderRadius: 5, background: 'transparent', padding: 0 }}
                                        id="location"
                                        name="location"

                                        value={formData.location}
                                        onChange={handleChangeService}
                                    >
                                        <option value="">Selectionner</option>
                                        {cities && cities.map((city) => (
                                            <option key={city.cityId._id} value={city.cityId._id}>
                                                {city.cityId.name}
                                            </option>
                                        ))}
                                    </select>
                                    {formErrors.location && <small className="text-danger">Veuillez selectionner une ville</small>}
                                </div>
                                <div className='inputcss1 width300M marginTop10' style={{ width:200, marginLeft: 20 }}>
                                    <label
                                        style={{ fontWeight: "normal", color: formErrors.date ? 'red' : 'black', whiteSpace: 'nowrap' }}
                                        htmlFor="date"
                                        className="form-label"
                                    >
                                        coût du service:
                                    </label>
                                    <p style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: 'bold', marginTop: 15 }}> {amount}<span style={{ color: 'black' }}><span> </span> {currencyValue}</span> </p>
                                </div>


                            </div>

                            <label
                                style={{ fontWeight: "normal", color: formErrors.w3review ? 'red' : 'black', fontSize: '16px' }}
                                htmlFor="w3review"
                                className="form-label"
                            >
                                Détail de la course
                            </label>
                            <textarea
                                style={{ marginTop: '-14px', height: 100 }}
                                id="w3review"
                                name="w3review"
                                rows="8"
                                cols="50"
                                placeholder='Description...'
                                value={formData.w3review}
                                onChange={handleChange}
                            />
                            {formErrors.w3review && <small className="text-danger">Veuillez faire une description</small>}



                            <div className='d-flex flexwrap mt-3'>
                                <div className='inputcss1 width300M' style={{ width: '300px' }}>
                                    <label
                                        style={{ fontWeight: "normal", color: formErrors.date ? 'red' : 'black', fontSize: '16px', whiteSpace: 'nowrap' }}
                                        htmlFor="date"
                                        className="form-label"
                                    >
                                        Date de début de réalisation :
                                    </label>
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChangeDate}
                                        style={{ marginTop: '-14px', height: 40, width: '100%', borderRadius: 5, padding: 5, marginTop: 10 }}
                                        min={new Date().toISOString().split("T")[0]}
                                    />
                                    {formErrors.date && <small className="text-danger">Veuillez sélectionner une date</small>}
                                </div>

                                <div className='inputcss1 width300M marginTop10' style={{ width: '200px', marginLeft: 20 }}>
                                    <label
                                        style={{ fontWeight: "normal", color: formErrors.date ? 'red' : 'black', whiteSpace: 'nowrap' }}
                                        htmlFor="date"
                                        className="form-label"
                                    >
                                        Date de finalisation/<br />livraison :
                                    </label>
                                    <p style={{ fontSize: 16, color: 'rgb(236, 54, 84)', fontWeight: 'bold', marginTop: 15 }}> {finalDate2 ? finalDate2 : finalDate1}</p>

                                </div>
                            </div>
                            <div>

                                <label
                                    style={{ fontWeight: "normal", color: formErrors.date ? 'red' : 'black', fontSize: 16 }}
                                    htmlFor="date"
                                    className="form-label"
                                >
                                    Documents requis pour ce service :
                                </label>
                                <div className='d-flex  flexwrap1'>
                                    <div className="form-check mt-1 ">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked={selectedOption === 'option1'} onChange={handleOptionChange} defaultChecked />
                                        <label className="form-check-label" htmlFor="exampleRadios1" style={{ fontSize: 15, fontWeight: 'normal', lineHeight: '18px' }}>
                                            Aucun document
                                        </label>
                                    </div>
                                    <div className="form-check mt-1">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked={selectedOption === 'option2'} onChange={handleOptionChange} />
                                        <label className="form-check-label" htmlFor="exampleRadios2" style={{ fontSize: 15, fontWeight: 'normal', lineHeight: '18px' }}>
                                            Documents originaux
                                        </label>
                                    </div>
                                    <div className="form-check mt-1">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" checked={selectedOption === 'option3'} onChange={handleOptionChange} />
                                        <label className="form-check-label" htmlFor="exampleRadios3" style={{ fontSize: 15, fontWeight: 'normal', lineHeight: '18px' }}>
                                            Documents numériques,<br />images etc.
                                        </label>
                                    </div>
                                    <div className="form-check mt-1">
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option4" checked={selectedOption === 'option4'} onChange={handleOptionChange} />
                                        <label className="form-check-label" htmlFor="exampleRadios4" style={{ fontSize: 15, fontWeight: 'normal', lineHeight: '18px' }}>
                                            Les deux types de documents
                                        </label>
                                    </div>
                                </div>

                            </div>

                            {
                                selectedOption === 'option2' && <div style={{ border: '1px dashed rgb(0, 119, 130)' }} className='p-2'>
                                    <p style={{ fontSize: 16 }}> Merci de bien vouloir déposer vos<span style={{ fontWeight: 'bold' }}> documents originaux</span> à la <span style={{ fontWeight: 'bold' }}>Direction Générale de Wemaza</span>, située à 100 mètres de l'immeuble Fayadort, Douala, Cameroun.<br /> Pour toute information supplémentaire, veuillez nous contacter au <span style={{ fontWeight: 'bold' }}>+237 6999990000</span>.</p>
                                </div>
                            }
                            {selectedOption === 'option3' && <div className="d-flex flex-column align-items-center mt-2" style={{ maxWidth: 510, height: 'auto', border: '1px dashed #007782' }}>
                                <label
                                    style={{ fontWeight: "normal", color: formErrors.Télécharger ? 'red' : 'black', fontSize: '16px' }}
                                    htmlFor="Télécharger"
                                    className="form-label p-2"
                                >
                                    Télécharger vos documents scannés ou vos images
                                </label>

                                {/* 
                                <div className='d-flex flex-column justify-content-center mb-4'>
                                    <div className="d-flex flex-column flex-sm-row">
                                        <div className='d-flex flex-wrap justify-content-start' style={{ width: '100%', overflowX: 'auto' }}>
                                            {previews.length > 0 && (
                                                <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
                                                    {previews.map((preview, index) => (
                                                        <div key={index} style={{ position: 'relative', display: 'inline-block', width: 110, height: 130, margin: 5 }}>
                                                            <img src={preview} alt={`Aperçu ${index}`} className="imageServiceCall" />
                                                            <p style={{ fontSize: 13, wordWrap: 'break-word', lineHeight: 'normal', textAlign: 'center' }}>
                                                                {fileNames[index]}
                                                            </p>
                                                            <button
                                                                onClick={() => handleRemovePreview(index)}
                                                                type='button'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '-16px',
                                                                    right: '-18px',
                                                                    backgroundColor: 'transparent',
                                                                    border: 'none',
                                                                    cursor: 'pointer',
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                <i className="fa fa-minus" style={{ fontSize: 23 }} />
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            <button
                                                name='Télécharger'
                                                type="button"
                                                style={buttonStyle}
                                                onClick={() => {
                                                    const elements = document.querySelector('.d-flex.flex-wrap');
                                                    if (elements) {
                                                        elements.style.flexDirection = 'column';  // Alignement des éléments verticalement
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-plus" style={{ fontSize: 24, border: '1px solid #007782', padding: 20, color: '#007782' }} />
                                                <input
                                                    type="file"
                                                    style={fileInputStyle}
                                                    multiple
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    onChange={handleFileChange}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div> 
                                */}



                                <div className='d-flex flex-column justify-content-center mb-4' >
                                    <div className="d-flex">
                                        <div className='d-flex' style={{   /* Activer le défilement horizontal */ }}>
                                            {previews.length > 0 && (
                                                <div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' /* Activer le défilement vertical si nécessaire */ }}>
                                                        {avatars.map((preview, index) => (
                                                            <div key={index} style={{ position: 'relative', display: 'inline-block', width: 110, height: 130, margin: 5 }}>
                                                                <img src={preview} alt={`Aperçu ${index}`} className="imageServiceCall" />
                                                                <p style={{ fontSize: 13, wordWrap: 'break-word', lineHeight: 'normal', textAlign: 'center' }}>
                                                                    {fileNames[index]}
                                                                </p>
                                                                <button
                                                                    onClick={() => handleRemovePreview(index)}
                                                                    type='button'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '-16px',
                                                                        right: '-18px',
                                                                        backgroundColor: 'transparent',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    <i className="fa fa-plus" style={{ fontSize: 23 }} />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            <button
                                                name='Télécharger'
                                                type="button"
                                                style={buttonStyle}
                                            >
                                                <i className="fa fa-plus" style={{ fontSize: 24, border: '1px solid #007782', padding: 20, color: '#007782' }} />
                                                <input
                                                    type="file"
                                                    style={fileInputStyle}
                                                    multiple
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    onChange={handleFileChange}
                                                />
                                            </button>
                                        </div>
                                    </div>








                                </div>


                            </div>}
                            {
                                selectedOption === 'option4' && <div className='d-flex flex-column'>
                                    <div style={{ border: '1px dashed rgb(0, 119, 130)' }} className='p-2'>
                                        <p style={{ fontSize: 16 }}> Merci de bien vouloir déposer vos<span style={{ fontWeight: 'bold' }}> documents originaux</span> à la <span style={{ fontWeight: 'bold' }}>Direction Générale de Wemaza</span>, située à 100 mètres de l'immeuble Fayadort, Douala, Cameroun.<br /> Pour toute information supplémentaire, veuillez nous contacter au <span style={{ fontWeight: 'bold' }}>+237 6999990000</span>.</p>
                                    </div>
                                    <div className="d-flex flex-column align-items-center mt-3" style={{ maxWidth: 510, height: 'auto', border: '1px dashed #007782' }}>
                                        <label
                                            style={{ fontWeight: "normal", color: formErrors.Télécharger ? 'red' : 'black', fontSize: '16px' }}
                                            htmlFor="Télécharger"
                                            className="form-label p-2"
                                        >
                                            Télécharger vos documents scannés ou vos images
                                        </label>

                                        <div className='d-flex flex-column justify-content-center mb-4'>
                                            <div className="d-flex flex-column flex-sm-row">
                                                <div className='d-flex flex-wrap justify-content-start' style={{ width: '100%', overflowX: 'auto' }}>
                                                    {previews.length > 0 && (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto' }}>
                                                            {previews.map((preview, index) => (
                                                                <div key={index} style={{ position: 'relative', display: 'inline-block', width: 110, height: 130, margin: 5 }}>
                                                                    <img src={preview} alt={`Aperçu ${index}`} className="imageServiceCall" />
                                                                    <p style={{ fontSize: 13, wordWrap: 'break-word', lineHeight: 'normal', textAlign: 'center' }}>
                                                                        {fileNames[index]}
                                                                    </p>
                                                                    <button
                                                                        onClick={() => handleRemovePreview(index)}
                                                                        type='button'
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '-16px',
                                                                            right: '-18px',
                                                                            backgroundColor: 'transparent',
                                                                            border: 'none',
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-minus" style={{ fontSize: 23 }} />
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                                <div>
                                                    <button
                                                        name='Télécharger'
                                                        type="button"
                                                        style={buttonStyle}
                                                        onClick={() => {
                                                            const elements = document.querySelector('.d-flex.flex-wrap');
                                                            if (elements) {
                                                                elements.style.flexDirection = 'column';  // Alignement des éléments verticalement
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa fa-plus" style={{ fontSize: 24, border: '1px solid #007782', padding: 20, color: '#007782' }} />
                                                        <input
                                                            type="file"
                                                            style={fileInputStyle}
                                                            multiple
                                                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            onChange={handleFileChange}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            }


                            <div className="row" style={{ margin: '15px 15px 0 6px' }}>


                                <button type="button" class="btn buttoyellow btn-secondary buttoyellowWdth" onClick={() => { handleDeleteService(); supprimerService() ; handleremove() }}
                                    style={{ fontSize: '18px', justifyContent: 'center', display: 'flex' }}>

                                    <span className='hiddenbutton' style={{ display: 'flex', justifyContent: 'center', marginTop: '7px', alignItems: 'center', marginRight: '10px', width: '35px', height: '35px', padding: '7px', backgroundColor: "#000", borderRadius: '50%' }}>
                                        <CloseRoundedIcon style={{ color: '#ffc107', fontSize: '20px' }} />

                                    </span>

                                    <span className='marginright38' style={{ display: 'inline-block', marginTop: '12px' }} >
                                        ANNULER
                                    </span>
                                </button>

                                {btndisabled ? (<button type="submit" class="btn btn-warning buttoyellow buttoyellowWdth"
                                    style={{ fontSize: '18px', justifyContent: 'center', display: 'flex' }}>

                                    <span className='marginleft28' style={{ display: 'inline-block', marginTop: '12px' }}>
                                        SOUSCRIRE
                                    </span>

                                    <span className='hiddenbutton' style={{ display: 'flex', justifyContent: 'center', marginTop: '7px', alignItems: 'center', marginLeft: '10px', width: '35px', height: '35px', padding: '7px', backgroundColor: "#000", borderRadius: '50%' }}>
                                        <ArrowForwardIcon style={{ color: '#ffc107', fontSize: '20px' }} />

                                    </span>

                                </button>) : (<button type="submit" disabled class="btn btn-warning buttoyellow buttoyellowWdth"
                                    style={{ fontSize: '18px', justifyContent: 'center', display: 'flex', cursor: 'not-allowed', background: '#6c757d1c' }}>

                                    <span className='marginleft28' style={{ display: 'inline-block', marginTop: '12px' }}>
                                    SOUSCRIRE
                                    </span>

                                    <span className='hiddenbutton' style={{ display: 'flex', justifyContent: 'center', marginTop: '7px', alignItems: 'center', marginLeft: '10px', width: '35px', height: '35px', padding: '7px', backgroundColor: "#000", borderRadius: '50%' }}>
                                        <ArrowForwardIcon style={{ color: '#ffc107', fontSize: '20px' }} />

                                    </span>
                                </button>)}


                            </div>


                        </form>
                    </div>
                    <div className='hiddenimage'>
                        <img src="./assets/images/womencall.png" className='womencall' />
                    </div>
                </div>
            </div>
        </div>

    );
}


export default ServiceCallSante;


const currency = {
    "XOF": { countries: ["Benin", "Burkina Faso", "Ivory Coast", "Guinea-Bissau", "Mali", "Niger", "Senegal", "Togo"], symbol: "CFA" },
    "AED": { countries: ["United Arab Emirates"], symbol: "د.إ" },
    "AFN": { countries: ["Afghanistan"], symbol: "؋" },
    "ALL": { countries: ["Albania"], symbol: "L" },
    "AMD": { countries: ["Armenia"], symbol: "֏" },
    "ANG": { countries: ["Netherlands Antilles", "Netherlands"], symbol: "ƒ" },
    "AOA": { countries: ["Angola"], symbol: "Kz" },
    "ARS": { countries: ["Argentina"], symbol: "$" },
    "AUD": { countries: ["Australia"], symbol: "A$" },
    "AWG": { countries: ["Aruba"], symbol: "ƒ" },
    "AZN": { countries: ["Azerbaijan"], symbol: "₼" },
    "BAM": { countries: ["Bosnia and Herzegovina"], symbol: "KM" },
    "BBD": { countries: ["Barbados"], symbol: "Bds$" },
    "BDT": { countries: ["Bangladesh"], symbol: "৳" },
    "BGN": { countries: ["Bulgaria"], symbol: "лв" },
    "BHD": { countries: ["Bahrain"], symbol: ".د.ب" },
    "BIF": { countries: ["Burundi"], symbol: "FBu" },
    "BMD": { countries: ["Bermuda"], symbol: "$" },
    "BND": { countries: ["Brunei"], symbol: "B$" },
    "BOB": { countries: ["Bolivia"], symbol: "Bs." },
    "BRL": { countries: ["Brazil"], symbol: "R$" },
    "BSD": { countries: ["Bahamas"], symbol: "B$" },
    "BTN": { countries: ["Bhutan"], symbol: "Nu." },
    "BWP": { countries: ["Botswana"], symbol: "P" },
    "BYN": { countries: ["Belarus"], symbol: "Br" },
    "BZD": { countries: ["Belize"], symbol: "BZ$" },
    "CAD": { countries: ["Canada"], symbol: "C$" },
    "CDF": { countries: ["Democratic Republic of the Congo"], symbol: "FC" },
    "CHF": { countries: ["Switzerland"], symbol: "CHF" },
    "CLP": { countries: ["Chile"], symbol: "$" },
    "CNY": { countries: ["China"], symbol: "¥" },
    "COP": { countries: ["Colombia"], symbol: "$" },
    "CRC": { countries: ["Costa Rica"], symbol: "₡" },
    "CUP": { countries: ["Cuba"], symbol: "₱" },
    "CVE": { countries: ["Cape Verde"], symbol: "$" },
    "CZK": { countries: ["Czech Republic"], symbol: "Kč" },
    "DJF": { countries: ["Djibouti"], symbol: "Fdj" },
    "DKK": { countries: ["Denmark"], symbol: "kr" },
    "DOP": { countries: ["Dominican Republic"], symbol: "RD$" },
    "DZD": { countries: ["Algeria"], symbol: "دج" },
    "EGP": { countries: ["Egypt"], symbol: "£" },
    "ERN": { countries: ["Eritrea"], symbol: "Nfk" },
    "ETB": { countries: ["Ethiopia"], symbol: "Br" },
    "EUR": { countries: ["European Union", "Germany", "France", "Italy", "Spain", "Portugal", "Greece"], symbol: "€" },
    "FJD": { countries: ["Fiji"], symbol: "FJ$" },
    "FKP": { countries: ["Falkland Islands"], symbol: "£" },
    "FOK": { countries: ["Faroe Islands"], symbol: "kr" },
    "GBP": { countries: ["United Kingdom"], symbol: "£" },
    "GEL": { countries: ["Georgia"], symbol: "₾" },
    "GHS": { countries: ["Ghana"], symbol: "₵" },
    "GIP": { countries: ["Gibraltar"], symbol: "£" },
    "GNF": { countries: ["Guinea"], symbol: "FG" },
    "GTQ": { countries: ["Guatemala"], symbol: "Q" },
    "HKD": { countries: ["Hong Kong"], symbol: "HK$" },
    "HNL": { countries: ["Honduras"], symbol: "L" },
    "HRK": { countries: ["Croatia"], symbol: "kn" },
    "HUF": { countries: ["Hungary"], symbol: "Ft" },
    "IDR": { countries: ["Indonesia"], symbol: "Rp" },
    "ILS": { countries: ["Israel"], symbol: "₪" },
    "INR": { countries: ["India"], symbol: "₹" },
    "IQD": { countries: ["Iraq"], symbol: "ع.د" },
    "IRR": { countries: ["Iran"], symbol: "﷼" },
    "ISK": { countries: ["Iceland"], symbol: "kr" },
    "JMD": { countries: ["Jamaica"], symbol: "J$" },
    "JOD": { countries: ["Jordan"], symbol: "د.ا" },
    "JPY": { countries: ["Japan"], symbol: "¥" },
    "KES": { countries: ["Kenya"], symbol: "KSh" },
    "KRW": { countries: ["South Korea"], symbol: "₩" },
    "KWD": { countries: ["Kuwait"], symbol: "د.ك" },
    "KZT": { countries: ["Kazakhstan"], symbol: "₸" },
    "LBP": { countries: ["Lebanon"], symbol: "ل.ل" },
    "LKR": { countries: ["Sri Lanka"], symbol: "Rs" },
    "MAD": { countries: ["Morocco"], symbol: "د.م." },
    "MXN": { countries: ["Mexico"], symbol: "$" },
    "MYR": { countries: ["Malaysia"], symbol: "RM" },
    "NGN": { countries: ["Nigeria"], symbol: "₦" },
    "NOK": { countries: ["Norway"], symbol: "kr" },
    "NPR": { countries: ["Nepal"], symbol: "Rs" },
    "NZD": { countries: ["New Zealand"], symbol: "NZ$" },
    "OMR": { countries: ["Oman"], symbol: "ر.ع." },
    "PEN": { countries: ["Peru"], symbol: "S/" },
    "PHP": { countries: ["Philippines"], symbol: "₱" },
    "PKR": { countries: ["Pakistan"], symbol: "Rs" },
    "PLN": { countries: ["Poland"], symbol: "zł" },
    "QAR": { countries: ["Qatar"], symbol: "ر.ق" },
    "RON": { countries: ["Romania"], symbol: "lei" },
    "RUB": { countries: ["Russia"], symbol: "₽" },
    "SAR": { countries: ["Saudi Arabia"], symbol: "﷼" },
    "SEK": { countries: ["Sweden"], symbol: "kr" },
    "SGD": { countries: ["Singapore"], symbol: "S$" },
    "THB": { countries: ["Thailand"], symbol: "฿" },
    "TRY": { countries: ["Turkey"], symbol: "₺" },
    "UAH": { countries: ["Ukraine"], symbol: "₴" },
    "USD": { countries: ["United States", "Puerto Rico"], symbol: "$" },
    "VND": { countries: ["Vietnam"], symbol: "₫" },
    "XAF": { countries: ["Cameroon", "Central African Republic", "Chad", "Congo", "Gabon", "Equatorial Guinea"], symbol: "CFA" },
    "ZAR": { countries: ["South Africa"], symbol: "R" },
};