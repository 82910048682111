import { useNavigate } from 'react-router-dom';
import Http from '../../Http'
import * as action from '../../store/actions'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
/* export function login(credentials) {
 
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/login`, { email: credentials.email,
                password:credentials.password, rememberMe: credentials.rememberMe}, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }

                })

        })
    )
}; */
/* 
export function verification(credentials) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com//api/users/verify`, {
                email: credentials.email,
                verificationCode: credentials.verificationCode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
}; */


const handleVerificationcodefalse = (navigate) => {
    MySwal.fire({
        title: "Votre code de verification est incorret ",
        text: "Veuillez reéssayer",
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
    }).then((result) => {
        if (result.isConfirmed) {
            // Rediriger vers la page de connexion après confirmation
            navigate("/verify");  // Remplacez '/login' par l'URL de votre page de connexion
        }
    });
};
// Action de vérification
export function verification(credentials, navigate) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/verify`, {
                email: credentials.email,
                verificationCode: credentials.verificationCode
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {

                    const message = res.data.message
                    if (message == 'Invalid verification code') {
                        handleVerificationcodefalse(navigate);
                        return reject({
                            error: 'Invalid credentials',
                            statusCode: 500,
                            notNetwork: false
                        });
                    }
                    return resolve(res);
                })
                .catch(function (err) {
                    // Gestion des erreurs
                    if (!err.response) {
                        // Erreur réseau
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion Internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        // dispatch({
                        //     type: 'VERIFICATION_FAIL',
                        //     error: "Erreur réseau. Veuillez vérifier votre connexion."
                        // });
                        return reject(data);
                    } else {
                        const statusCode = err.response.status;
                        const errorData = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };

                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs spécifiques
                            errorData.error = err.response.data.message;
                            // dispatch({
                            //     type: 'VERIFICATION_FAIL',
                            //     error: err.response.data.message
                            // });
                        }

                        return reject(errorData);
                    }
                });
        })
    );
};


const handlemotdepasseincorrect = (navigate) => {
    MySwal.fire({
        title: "Votre mot de passe est incorrect",
        text: "Veuillez reéssayer",
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
    }).then((result) => {
        if (result.isConfirmed) {
            // Rediriger vers la page de connexion après confirmation
            navigate("/");  // Remplacez '/login' par l'URL de votre page de connexion
        }
    });
};
export function login(credentials, navigate) {

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/login`, {
                email: credentials.email,
                password: credentials.password,
                rememberMe: credentials.rememberMe
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    const message = res.data.message
                    if (message == 'Invalid credentials') {
                        const message_ = "votre email est incorrect"
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        return reject({
                            error: 'Invalid credentials',
                            statusCode: 500,
                            notNetwork: false
                        });
                    }

                    if (message == 'Account not verified') {
                        const message_ = "votre compte n est pas verifie"
                        /* dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_)) */
                        navigate('/verify')
                        return reject({
                            error: 'Account not verified',
                            statusCode: 500,
                            notNetwork: false
                        });
                    }

                    const usertoken = res.data;
                    const token = usertoken.token;
                    const user = usertoken.user;

                    // Stocker le token dans le localStorage
                    localStorage.setItem('authToken', token);
                    localStorage.setItem('userInfo', JSON.stringify(user));

                    // Vérifier si le token est bien dans le localStorage
                    const storedToken = localStorage.getItem('authToken');
                    const storeduserInfo = localStorage.getItem('userInfo');
                    console.log('Token stocké :', storedToken);  // Affiche le token dans la console
                    console.log('userInfo stocké :', storeduserInfo);  // Affiche une chaîne JSON de l'objet

                    // Dispatch de l'action avec les données de connexion
                    dispatch(action.authLogin(res.data));

                    // Résoudre la promesse avec la réponse
                    return resolve(res);
                })
                .catch(function (err) {
                    if (!err.response) {
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        }
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                            /*   handlemotdepasseincorrect(navigate);
                              const message_ = "votre mot de passe est incorrect";
                              dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_)); */
                            return reject({
                                error: 'mot de passe incorret',
                                statusCode: 500,
                                notNetwork: false
                            });
                        }
                    }

                });
        })
    );
};
/*   alert('test'); */

/*  handlemotdepasseincorrect();  */
// handle error
/*  if (!err.response) {
   /*   alert('alert echec') 
     // Erreur réseau (serveur en panne ou pas d'internet)
     const data = { notNetwork: true };
     return reject(data);
 } else
  {
     const statusCode = err.response.status; // Statut de l'erreur
     const data = {
         error: null,
         statusCode: statusCode,
         notNetwork: false
     }
     if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
         // Gestion des erreurs selon le statut HTTP
         data.error = err.response.data.message;
     }
     return reject(data);
 } */


export function socialLogin(credentials) {
    let formdata = new FormData();

    formdata.append('email', credentials.email);
    formdata.append('social_provider', credentials.social_provider);
    formdata.append('social_id', credentials.social_id);
    formdata.append('social_nickname', credentials.social_nickname);
    formdata.append('social_token', credentials.social_token);
    formdata.append('social_refresh_token', credentials.social_refresh_token);

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/v1/login-using-social-account`, formdata)
                .then(function (res) {
                    // handle success           
                    dispatch(action.authLogin(res.data));
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode: err,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function transaction(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/transactions/create`, {
                servicesBeneficiaryId: data.servicesBeneficiaryId,
                amount: data.amount,
                currency: data.currency,
                description: data.description,
                redirectUrl: data.redirectUrl
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    resolve(res);  // Résolution si l'enregistrement est réussi

                })
                .catch(err => {
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        console.log('data ssssssssss', data)
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        }));
}

export function priceByCurrrency(currencyValue, full_price) {
    return dispatch => (
        new Promise((resolve, reject) => {

            // Envoi de la requête POST avec multipart/form-data
            Http.get(`https://api.wemaza.com/api/exchange-rates/convert?amount=${full_price}&currency=${currencyValue}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    resolve(res);  // Résolution si l'enregistrement est réussi

                })
                .catch(err => {
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        console.log('data ssssssssss', data)
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        }));
}


export function serviceStatus(id) {
    return dispatch => (
        new Promise((resolve, reject) => {

            // Envoi de la requête POST avec multipart/form-data
            Http.get(`https://api.wemaza.com/api/transactions/status/${id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    resolve(res);  

                })
                .catch(err => {
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        console.log('data ssssssssss', data)
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        }));
}


// export function serviceBeneficiairie(userBeneficiaryId, totalAmount, services) {
//     console.log('etape 63', userBeneficiaryId)
//     console.log('etape 63', totalAmount)
//     console.log('etape 63', services)

//     return async dispatch => (
//         new Promise((resolve, reject) => {

//             console.log('etape 63')
//             // Préparer l'objet FormData
//             const formData = new FormData();
//             formData.append("userBeneficiaryId", userBeneficiaryId);
//             formData.append("totalAmount", totalAmount);

//             services.forEach((service, index) => {
//                 formData.append(`services[${index}][serviceId]`, service.serviceId);
//                 formData.append(`services[${index}][cityId]`, service.cityId);
//                 formData.append(`services[${index}][details]`, service.details);
//                 formData.append(`services[${index}][date_of_completion]`, service.date_of_completion);


//                 Array.from(service.documentFiles).forEach((file) => {
//                     formData.append(`services[${index}][documentFiles]`, file);
//                 });
//             });

//             console.log('etape 63', formData);

//             // Envoi de la requête POST avec multipart/form-data
//             Http.post(`https://api.wemaza.com/api/services-beneficiaries`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             })
//                 .then(function (res) {
//                     console.log("etape second 1");
//                     const id = res.data._id
//                     dispatch(action.setServiceBeneficiaryId(id))
//                     localStorage.setItem('savedId', id);
//                     resolve(res);  // Résolution si l'enregistrement est réussi
//                 })
//                 .catch(err => {

//                     if (!err.response) {
//                         // Erreur réseau (serveur en panne ou pas d'internet)
//                         const data = { notNetwork: true };
//                         const message_ = "Vérifiez votre connexion internet.";
//                         dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
//                         console.log('data ssssssssss', data);
//                         return reject(data);
//                     } else {
//                         const statusCode = err.response.status; // Statut de l'erreur
//                         const data = {
//                             error: null,
//                             statusCode: statusCode,
//                             notNetwork: false
//                         };
//                         if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
//                             // Gestion des erreurs selon le statut HTTP
//                             data.error = err.response.data.message;
//                         }
//                         return reject(data);
//                     }
//                 });
//         }));
// }
export function serviceBeneficiairie(userBeneficiaryId, totalAmount, services,savedServiceList) {
    console.log('etape 63', userBeneficiaryId);
    console.log('etape 63', totalAmount);
    console.log('etape 63', services);

    return async dispatch => {
        try {
            console.log('etape 63');
            // Préparer l'objet FormData
            const formData = new FormData();
            formData.append("userBeneficiaryId", userBeneficiaryId);
            formData.append("totalAmount", totalAmount);

            services.forEach((service, index) => {
                // Ajouter les informations de chaque service
                formData.append(`services[${index}][serviceId]`, service.serviceId);
                formData.append(`services[${index}][cityId]`, service.cityId);
                formData.append(`services[${index}][details]`, service.details);
                formData.append(`services[${index}][date_of_completion]`, service.date_of_completion);

            
                // Ajouter les fichiers associés à chaque service
                Array.from(service.documentFiles).forEach((file) => {
                    formData.append(`services[${index}][documentFiles]`, file);
                });
            
                // Ajouter les informations complémentaires du service provenant de savedServiceList
                const savedService = savedServiceList[index];  // Vous vous assurez que c'est le même index pour les deux listes
                formData.append(`services[${index}][price_to_pay]`, savedService.NewPriceConvert);
            });
            

            console.log('etape 63', formData);

            // Envoi de la requête POST avec multipart/form-data
            const res = await Http.post(`https://api.wemaza.com/api/services-beneficiaries`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log("etape second 1");
            
            return res; // Retourne la réponse si tout se passe bien

        } catch (err) {
            if (!err.response) {
                // Erreur réseau (serveur en panne ou pas d'internet)
                const data = { notNetwork: true };
                const message_ = "Vérifiez votre connexion internet.";
                dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_));
                console.log('data ssssssssss', data);
                throw data; // Lève une erreur pour propager l'erreur réseau
            } else {
                const statusCode = err.response.status; // Statut de l'erreur
                const data = {
                    error: null,
                    statusCode: statusCode,
                    notNetwork: false
                };
                if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                    // Gestion des erreurs selon le statut HTTP
                    data.error = err.response.data.message;
                }
                throw data; // Lève une erreur avec les détails spécifiques
            }
        }
    };
}



export function registerP(data1, navigate) {
    console.log('data1 image ppppppppppppppppppp', data1)
    return dispatch => (
        new Promise((resolve, reject) => {
            // Création de l'objet FormData pour l'envoi de fichiers
            const formData = new FormData();
            formData.append('name', data1.name);
            formData.append('password', data1.password);
            formData.append('avatar', data1.avatar); // Ajout de l'avatar
            formData.append('email', data1.email);
            formData.append('phone', data1.phone);
            formData.append('location', data1.location);
            formData.append('role', data1.role);

            // Envoi de la requête POST avec multipart/form-data
            Http.post(`https://api.wemaza.com/api/users/register`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (res) {
                    console.log("Bénéficiaire enregistré avec succès:", res);
                    const protectorId = res.data.protector_id;
                    const email = res.data.protectorEmail.email


                    console.log('email aaaaaaaaaaaaaa', email)
                    console.log('protectorId aaaaaaaaaaaaaa', protectorId)

                    // Sauvegarder dans le localStorage
                    localStorage.setItem('protector_id', protectorId);
                    localStorage.setItem('protector_email', email);

                    // Récupérer et afficher dans la console
                    const storedProtectorEmailJson = localStorage.getItem('protector_email');
                    const storedProtectoridlJson = localStorage.getItem('protector_id');
                    dispatch(action.setEmail(storedProtectorEmailJson));
                    dispatch(action.setIdProtector(storedProtectoridlJson));


                    console.log('protectorEmailObject ttttttttttttttttt', storedProtectorEmailJson)
                    resolve(res);  // Résolution si l'enregistrement est réussi
                })
                .catch(err => {

                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        console.log('data ssssssssss', data)
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        }));
}


export function order(data1, navigate) {
    console.log('data1 image ppppppppppppppppppp', data1);
    return dispatch => (
        new Promise((resolve, reject) => {

            const requestBody = {
                userBeneficiaryId: data1.userBeneficiaryId,
                services: data1.services.map(service => ({
                    serviceId: service.serviceId,
                    cityId: service.cityId,
                    details: service.details,
                    date_of_completion: service.date_of_completion,
                    documentFiles: service.documentFiles
                })),
                totalAmount: data1.totalAmount
            };

            // Envoi de la requête POST avec application/json
            Http.post('https://api.wemaza.com/api/services-beneficiaries', requestBody, {
                headers: {
                    'Content-Type': 'application/json' // Le type de contenu est bien application/json
                }
            })
                .then(function (res) {

                    resolve(res);  // Résolution si l'enregistrement est réussi
                })
                .catch(err => {

                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        console.log('data ssssssssss', data)
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        }));
}



const handleVerificationmail = () => {
    MySwal.fire({
        title: "Votre mail existe deja",
        text: "Veuillez vous connecter avec vos identifiants",
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
    }).then((result) => {
        /* if (result.isConfirmed) {
          // Rediriger vers la page de connexion après confirmation
         navigate("/login");  // Remplacez '/login' par l'URL de votre page de connexion
        } */
    });
};
/* export function register(data1, data2) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`https://api.wemaza.com/api/users/register`, {
                name: data1.name,
                password: data1.password,
                email: data1.email,
                phone: data1.phone,
                location: data1.location,
                role: data1.role
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {

                    /* localStorage.setItem('userInfoRegister', JSON.stringify(res));
                    const storeduserInfoRegister = localStorage.getItem('userInfoRegister');
                    console.log(data2, 'dat2hasjdahskjdahkdjajksda')
                    console.log("Utilisateur protecteur enregistré:", res);
                    console.log('userInfo stocké fffffffffffffffffffffffffffffffff:', storeduserInfoRegister);  // Affiche une chaîne JSON de l'objet
 */
// Si l'enregistrement du protecteur est réussi, récupérer son ID
/*        const protectorId = res.data.protector_id;
       console.log('protectorid tttttttttttttttttttt', protectorId)

       // Inclure l'ID du protecteur dans les données du bénéficiaire
       const beneficiaryData = { ...data2, id_User_protector: protectorId };
       console.log('beneficiaryData ggggggggggggggggggggg', beneficiaryData)

       // Appeler l'action registerB pour enregistrer le bénéficiaire
       dispatch(registerB(beneficiaryData));
       dispatch(action.authLogin(res));
       return resolve(res);



   })
   .catch(function (err) {
       if (!err.response) {
           const data = { notNetwork: true };
           return reject(data);
       } else {
           const statusCode = err.response;
           const data = {
               error: null,
               statusCode: err,
               notNetwork: false
           };
           if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
               data.error = err.response.data.message;
           }
           return reject(data);
       }
   });
})
);
} */


export function register(data1, data2) {
    return dispatch => (
        new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('name', data1.name);
            formData.append('password', data1.password);
            formData.append('avatar', data1.avatar);  // Ici, on suppose que l'avatar est un fichier
            formData.append('email', data1.email);
            formData.append('phone', data1.phone);
            formData.append('location', data1.location);
            formData.append('role', data1.role);
            Http.post(`/users/register`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'  // Changement ici
                }
            })
                .then(function (response) {
                    console.log("Utilisateur protecteur enregistré avec succès:", response.data);

                    // Récupérer l'ID du protecteur
                    const protectorId = response.data.protector_id;
                    console.log('ID du protecteur:', protectorId);

                    // Inclure l'ID du protecteur dans les données du bénéficiaire
                    const beneficiaryData = { ...data2, id_User_protector: protectorId };
                    console.log('Données du bénéficiaire:', beneficiaryData);

                    // Enregistrer le bénéficiaire avec les données modifiées
                    dispatch(registerB(beneficiaryData));

                    dispatch({
                        type: 'REGISTER_SUCCESS',
                        payload: response.data
                    });

                    // Authentification après l'enregistrement réussi
                    dispatch(action.authLogin(response.data));

                    return resolve(response);

                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
};

// export function registerans(data1, data2) {
//     return async (dispatch) => {
//         try {
//             const response = await Http.post(`https://api.wemaza.com/api/users/register`, {
//                 name: data1.name,
//                 password: data1.password,
//                 email: data1.email,
//                 phone: data1.phone,
//                 location: data1.location,
//                 role: data1.role
//             }, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             });

//             if (response.status === 200 || response.status === 201) {
//                 console.log("Utilisateur protecteur enregistré avec succès:", response.data);

//                 // Récupérer l'ID du protecteur
//                 const protectorId = response.data.protector_id;
//                 console.log('ID du protecteur:', protectorId);

//                 // Inclure l'ID du protecteur dans les données du bénéficiaire
//                 const beneficiaryData = { ...data2, id_User_protector: protectorId };
//                 console.log('Données du bénéficiaire:', beneficiaryData);

//                 // Enregistrer le bénéficiaire avec les données modifiées
//                 dispatch(registerB(beneficiaryData));

//                 dispatch({
//                     type: 'REGISTER_SUCCESS',
//                     payload: response.data
//                 });

//                 // Authentification après l'enregistrement réussi
//                 dispatch(action.authLogin(response.data));

//             }
//         } catch (error) {
//             const statusCode = error.message;

//             if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
//                 dispatch(action.emailexiste());
//             console.error("Erreur lors de l'enregistrement de l'utilisateur protecteur:", error);
//            ;}
//         }
//     };
// }



export function registerB(data2) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/users/register`, {
                name: data2.name,
                sexe: data2.sexe,
                phone: data2.phone,
                location: data2.location,
                role: data2.role,
                id_User_protector: data2.id_User_protector
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (response) {
                    console.log("Utilisateur protecteur enregistré avec succès:", response.data);
                    const beneficiary_id = response.data.beneficiary_id
                    localStorage.setItem('savedIdBeneficiary_id', beneficiary_id)
                    
                    // dispatch(action.setbenificiaryid(beneficiary_id));


                    return resolve(response);

                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // Erreur réseau (serveur en panne ou pas d'internet)
                        const data = { notNetwork: true };
                        const message_ = "Vérifiez votre connexion internet.";
                        dispatch(action.set_MESSAGE_INVALID_CREDENTIAL(message_))
                        return reject(data);
                    } else {
                        const statusCode = err.response.status; // Statut de l'erreur
                        const data = {
                            error: null,
                            statusCode: statusCode,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // Gestion des erreurs selon le statut HTTP
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                });
        })
    );
};



// Action pour enregistrer un bénéficiaire ou effectuer une autre opération
/* export function registerB(data2) {

    return new Promise((resolve, reject) => {
        Http.post(`https://api.wemaza.com/api/users/register `, {
            name: data2.name,
            sexe: data2.sexe,
            phone: data2.phone,
            location: data2.location,
            role: data2.role,
            id_User_protector: data2.id_User_protector
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log("Bénéficiaire enregistré avec succès:", response);
                resolve(response);  // Résolution si l'enregistrement est réussi
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement du bénéficiaire:", error);
                reject(error);  // Rejet si l'action échoue
            });
    });
} */





export function profile() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/user/profile?include=provider`)
                .then(function (res) {
                    // handle success     
                    const data = res.data.data
                    const profileD = {
                        ln: data.lastname,
                        fn: data.firstname,
                        email: data.email,
                        _ipvkd: data.provider.data.real_id,
                        ipvkds: data.country_id,
                        __ridu: data.real_id
                    }
                    dispatch(action.profile(profileD));
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        // status 401 means unauthorized
                        // status 422 means unprocessable entity
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function logout() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.delete(`/logout`)
                .then(function (res) {
                    // handle success             
                    dispatch(action.authLogout());
                    return resolve(res);
                })
                .catch(function (err) {
                    // handle error=
                    return reject(err);
                })

        })
    )
};

export function getAllCountries() {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    if (!err.response) {
                        // network error (server is down or no internet)
                        const data = {
                            notNetwork: true
                        };
                        return reject(data)
                    } else {
                        const statusCode = err.response;
                        const data = {
                            error: null,
                            statusCode: err,
                            notNetwork: false
                        };
                        if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                            // status 401 means unauthorized
                            // status 422 means unprocessable entity
                            data.error = err.response.data.message;
                        }
                        return reject(data);
                    }
                })

        })
    )
};

export function searchCountry(real_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/countries?search=id:${real_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};

export function getCitiesCountry(hash_id) {
    const limit = 250
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/get-all-cities/${hash_id}`)
                .then(function (res) {
                    const data = res.data
                    return resolve(data);
                })
                .catch(function (err) {
                    // handle error
                    const statusCode = err.response;
                    const data = {
                        error: null,
                        statusCode,
                    };
                    if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
                        data.error = err.response.data.message;
                    }
                    return reject(data);
                })

        })
    )
};