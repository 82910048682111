import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import RootReducer from './reducers';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: hardSet,
// };

// const persistedReducer = persistReducer(persistConfig, RootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  // persistedReducer, // Utilisez persistedReducer ici
  RootReducer,
  composeEnhancers(
    applyMiddleware(ReduxThunk, logger) // Ajoutez logger si nécessaire
  )
);

const persistor = persistStore(store);

// const exported = { store, persistor };
// export { store, persistor };
export default store
