import React, { useState } from 'react';

const Myform = () => {
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        location: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        password: '',
        email: '',
        location: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Validation pour chaque champ
        if (value.trim() === '') {
            setErrors({
                ...errors,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Validation globale du formulaire
        let formIsValid = true;
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if (formData[field].trim() === '') {
                newErrors[field] = 'Ce champ est obligatoire';
                formIsValid = false;
            }
        });

        setErrors(newErrors);

        if (formIsValid) {
            // Logique d'envoi ou traitement
            console.log('Formulaire soumis:', formData);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{margin:300}}>
            <div className="form-group">
                <input
                    className={`input-field form-control ${errors.name ? 'is-invalid' : ''}`}
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                />
                {errors.name && <small className="text-danger">{errors.name}</small>}
            </div>

            <div className="form-group">
                <input
                    className={`input-field form-control ${errors.password ? 'is-invalid' : ''}`}
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                />
                {errors.password && <small className="text-danger">{errors.password}</small>}
            </div>

            <div className="form-group">
                <input
                    className={`input-field form-control ${errors.email ? 'is-invalid' : ''}`}
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <small className="text-danger">{errors.email}</small>}
            </div>

            <div className="form-group">
                <input
                    className={`input-field form-control ${errors.location ? 'is-invalid' : ''}`}
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={formData.location}
                    onChange={handleChange}
                />
                {errors.location && <small className="text-danger">{errors.location}</small>}
            </div>

            <button type="submit" className="btn btn-primary">Envoyer</button>
        </form>
    );
};

export default Myform;
