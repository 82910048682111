import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const FromTest = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [valid, setValid] = useState(true)
  const PhoneNumber = () => { }
  const handleChange = (value) => {
    setPhoneNumber(value)
    setValid(validphonenumber(value))
  }
  const validphonenumber = (input) => {
    const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    return patt.test(input);
  }
  return (
    <div style={{ margin: 400 }}>
      <label>
        test number
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default FromTest;