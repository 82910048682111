import React, { use, useEffect, useState } from 'react';
import axios from 'axios';
import './style1.css';
import { yellow } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { navigateFalse, puttrue, serviceCallA, serviceCallA_, setListesData, SetService, setSymbol, setTotalAmount, updateServiceCity, updateServicesReducer, updateServiceStatusNew } from '../../../store/actions';
import ServiceCall from '../newPack/ServiceCall';
import AuthManager from '../../components/AuthManager';
import { Link, useNavigate } from 'react-router-dom';
import ServiceCard from './ServiceCard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
/* import Link from '@mui/material/Link'; */
import HomeIcon from '@mui/icons-material/Home';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { priceByCurrrency } from '../../../services/auth/authService';

const MySwal = withReactContent(Swal);
const serializeFile = (file) => {
    return {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified
    };
};

const ServiceTemp = () => {

    // States
    const [isDirect, setIsDirect] = useState(true);
    const [btnActive, setBtnActive] = useState(false);
    const [isAnticipation, setIsAnticipation] = useState(false);
    const [newList, setNewList] = useState()
    const titleRedux = useSelector((state) => state.packReducer.title);
    const totalAmountRedux = useSelector((state) => state.packReducer.totalAmount);

    const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));

    // Hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Redux selectors
    const serviceCal = useSelector((state) => state.santeReducer.serviceCal);
    const serviceRedux = useSelector((state) => state.packReducer.serviceId);
    const servicesRedux = useSelector((state) => state.packReducer.services);
    console.log('servicesRedux', servicesRedux)
    const maVariableT = useSelector((state) => state.packReducer.navigateTrueFalse);
   

    const serviceFilter = (servicesLocal1 ?? []).filter(service => service.status === true);



    // Sauvegarde dans localStorage
    localStorage.setItem('maCle', maVariableT);

    const handleBtnActive = () => {
        if (Array.isArray(serviceFilter) && serviceFilter.length >= 1) {
            console.log('etage 2')
            setBtnActive(true);
        } else if (serviceFilter.length === 0) {
            console.log('etage 3')
            setBtnActive(false);
        }
    };
    const handdleNavigate = () => {
        navigate('/connected-protector-beneficiary-account');
    };
    useEffect(() => {

        handleBtnActive();
    }, [serviceFilter]);


    const modificationStore23 = () => {
        const newStatus = true;
        dispatch(updateServiceStatusNew(serviceId, newStatus));
        // const newReduxService = useSelector(state => state.packReducer.services)
        if (servicesRedux != null) {
            if (servicesRedux.length >= 1) {
                // Traiter les fichiers dans servicesRedux
                servicesRedux.forEach(service => {
                    if (service.documentFiles && Array.isArray(service.documentFiles)) {
                        service.documentFiles = service.documentFiles.map(file => serializeFile(file));
                    }
                });
                // Sauvegarder dans localStorage si nécessaire
                const savedServices = JSON.parse(localStorage.getItem('servicesLocal'));
                // Vérifier si les données sont différentes avant de sauvegarder et de mettre à jour l'état
                if (JSON.stringify(servicesRedux) !== JSON.stringify(savedServices)) {
                    console.log("Mise à jour des services dans localStorage et dans l'état");
                    localStorage.setItem('servicesLocal', JSON.stringify(servicesRedux));
                    // addService();
                }

            }
        }
    }
    // Fonction de mise à jour des services
    const serviceId = useSelector(state => state.packReducer.serviceId)

    const supprimerService = () => {
        if (servicesLocal1 != null) {
            const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
            // dispatch(updateServicesReducer(servicesLocal1));
            localStorage.removeItem('servicesLocal');
            const newStatus = false;
            dispatch(updateServiceStatusNew(serviceId, newStatus));
            const updatedServices = servicesLocal1.map(service =>
                service._id === serviceId ? { ...service, status: newStatus } : service
            );
            modificationStore2(updatedServices);
        }
    }

    const modificationStore = (price, cityId2, date_of_completion,full_price) => {
        console.log('cityId', cityId2)
        const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
        console.log('servicesLocal1', servicesLocal1)

        if (servicesLocal1 != null) {
            console.log('etape 1')
            // dispatch(updateServicesReducer(servicesLocal1));
            const newStatus = true;
            dispatch(updateServiceStatusNew(serviceId, newStatus));
            const updatedServices = servicesLocal1.map(service =>
                service._id === serviceId
                    ? { ...service, status: newStatus, priceConvert: price, cityId: cityId2, date_of_completion: date_of_completion,full_priceCFA:full_price }
                    : service
            );

            modificationStore2(updatedServices);
            dispatch(updateServicesReducer(updatedServices));
        } else if (servicesLocal1 === null) {
            console.log('etape 3')
            const newStatus = true;
            dispatch(updateServiceStatusNew(serviceId, newStatus))
            const updatedServices = servicesRedux.map(service =>
                service._id === serviceId ? { ...service, status: newStatus, priceConvert: price, cityId: cityId2, date_of_completion: date_of_completion ,full_priceCFA:full_price } : service
            );
            modificationStore2(updatedServices);
            dispatch(updateServicesReducer(updatedServices));
        }
    }

    const modificationStore2 = (newList) => {
        try {
            console.log('newList', newList)

            if (newList != null) {
                if (newList.length >= 1) {
                    // Traiter les fichiers dans servicesRedux
                    newList.forEach(service => {
                        if (service.documentFiles && Array.isArray(service.documentFiles)) {
                            service.documentFiles = service.documentFiles.map(file => serializeFile(file));
                        }
                    });
                    // Sauvegarder dans localStorage si nécessaire
                    const savedServices = JSON.parse(localStorage.getItem('servicesLocal'));
                    // Vérifier si les données sont différentes avant de sauvegarder et de mettre à jour l'état
                    if (JSON.stringify(newList) !== JSON.stringify(savedServices)) {
                        console.log("Mise à jour des services dans localStorage et dans l'état");
                        localStorage.setItem('servicesLocal', JSON.stringify(newList));
                    }

                }
            }
        } catch (e) { console.log(e) }
    }



    // console.log('servicesLocal1', servicesLocal1);
    useEffect(() => {
        dispatch(navigateFalse());
        getOffresSanteByPackId();
        localStorage.setItem('totalAmount', JSON.stringify(totalAmountRedux));
        localStorage.setItem('title', JSON.stringify(titleRedux));

    }, []);


    //   useEffect(
    //     ()=>{
    //         const servicesLocal1 = JSON.parse(localStorage.getItem('servicesLocal'));
    //         modificationStore2()
    //     },[servicesLocal1]
    //   )





    const services = servicesLocal1 ? servicesLocal1 : servicesRedux;
    console.log('services', services)


    //   useEffect(()=>{
    //     dispatch(setTotalAmount(totalPrix));
    //   },[totalPrix]);

    // API Call
    const getOffresSanteByPackId = async () => {
        try {
            const response = await axios.get('https://api.wemaza.com/api/services/pack/67c6ea2786e443d37893be5c');
            const datas = response.data;
            console.log('ggggg', datas);
            dispatch(setListesData(datas));
        } catch (err) {
            if (err.response) {
                console.log(err.response.data.message || 'Erreur inconnue');
            } else if (err.request) {
                console.log('Pas de réponse du serveur.');
            } else {
                console.log('Erreur de connexion. Vérifiez votre réseau.');
            }
        }
    };

    // Calcul du prix total

    useEffect(() => {
        const storedServices = JSON.parse(localStorage.getItem('servicesLocal1'));
        if (storedServices && storedServices.length > 0) {
            // Vérifiez si les services dans l'état sont différents de ceux dans localStorage
            if (JSON.stringify(storedServices) !== JSON.stringify(servicesLocal1)) {
                dispatch(updateServicesReducer(storedServices)); // Effectuer l'action uniquement si nécessaire
            }
        }
    }, [servicesLocal1]); // Assurez-vous que `servicesLocal1` n'est mis à jour que si nécessaire



    const [full_priceCFAcONVERT,setFull_priceCFAcONVERT]= useState(0)
    const arrondi = services
        .filter(service => service.status === true)
        .reduce((total, service) => total + parseFloat(service.full_priceCFA), 0);
  
    const currencyCountry = useSelector((state) => state.packReducer.currencyCountry);
    console.log('currencyCountry',currencyCountry);
     const registerComponent = () => {
            dispatch(priceByCurrrency(currencyCountry, arrondi))
                .then((result) => {
                    const amount = result.data.amount;
                    setFull_priceCFAcONVERT(amount);
                })
                .catch((e) => {
                    console.log('errors ', e);
                });
        }
        useEffect(()=>{
            if(currencyCountry && arrondi) {registerComponent()}
        },[arrondi,currencyCountry])

    // localStorage.setItem('title', JSON.stringify(titleRedux));
    // const storedServices = JSON.parse(localStorage.getItem('totalAmount'));
    function arrondirSuperieurDeuxDecimales(nombre) {
        return Math.ceil(nombre * 100) / 100;
    }
    const totalPrix =Number( arrondirSuperieurDeuxDecimales(full_priceCFAcONVERT));
    localStorage.setItem('totalAmount', JSON.stringify(totalPrix));
   

    useEffect(() => {
        dispatch(setTotalAmount(totalPrix))
    }, [totalPrix]);

    // Gestion du changement de couleur
    const handleColorChange = (val) => {
        if (val === "isDirect") {
            setIsDirect(true);
            setIsAnticipation(false);
        } else {
            setIsDirect(false);
            setIsAnticipation(true);
        }
    };
    const symbolFromRedux = useSelector(state => state.packReducer.symbol);
    const [symbol,setsymbol]= useState()
    // const savedSymbol = localStorage.getItem('currencySymbol');
   
    useEffect(() => {
        setsymbol(symbolFromRedux); 
    }, [symbolFromRedux]);


    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    };

    // Vérification de connexion
    const handleVerificationConnect = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: "Pour continuer veuillez vous connectez",
            text: "Cliquez sur OK pour continuer",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: true,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/connected-protector-beneficiary-account");
            }
        });
    };
    const foundSymbol = localStorage.getItem('foundSymbol')?localStorage.getItem('foundSymbol'): null;


    // Vérification de reconnexion
    const handleVerificationUnConnect = (e) => {
        e.preventDefault();
        MySwal.fire({
            title: "Pour continuer veuillez vous connectez",
            text: "Veuillez vous reconnecter",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,  // Empêche la fermeture de l'alerte en cliquant à l'extérieur
        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/linked-protector-beneficiary-account");
            }
        });
    };

    return (
        <div style={{ position: 'relative', top: '-21px', minHeight: '47vh' }} className='login-form marginbottomService2'>
            <div className='ml-5' role="presentation" style={{ marginLeft: 10, marginTop: 20 }} onClick={handleClick}>
                <div className="mt-4" style={{ paddingBottom: '10px' }}>
                    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'rgb(30, 73, 155)' }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            <HomeIcon sx={{ mr: 1 }} className='breadcrumbs' />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            to={'/ListeService'}
                            style={{ fontSize: 16, color: 'rgb(30, 73, 155)', fontWeight: '200' }}

                        >
                            {/*  <HomeIcon sx={{ mr:1 }} fontSize="10" className='breadcrumbs'/> */}
                            ListeService
                        </Link>

                    </Breadcrumbs>
                </div>
            </div>



            <div style={{ marginLeft: 40, marginRight: 40 }} className='htmltemplate'>
                <div id="scroll">
                    {serviceCal ?
                        <ServiceCall modificationStore={modificationStore} supprimerService={supprimerService} /> : null
                    }
                    <div className="container-fuild mil-p-0-f">
                        <div className="mil-calculator d-flex align-items-center justify-content-center">
                            <form style={{ width: 1500 }}>
                                <div className="row mil-aic mil-jcb mil-mb-15">
                                    <div className="col-12 col-md-12 mil-sm-mb-15">
                                        <div className="mil-input-frame mil-custom-select mil-up " style={{ translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1, justifyContent: 'space-between', background: 'none' }}>
                                            {isDirect === true ?
                                                <div style={{ height: 75, marginTop: 120, width: '360px', borderRadius: '10px' }} className="bdf widthhidden mil-select-button mil-br-md mil-bg-m-4 d-flex justify-content-arround">
                                                    <span className="mil-selected-value mil-fw-600 mil-m-1" style={{ fontSize: '22px', whiteSpace: 'nowrap' }}>Pass Spontané</span>

                                                </div>
                                                :
                                                <div onClick={(val) => handleColorChange("isDirect")} style={{ height: 75, marginTop: 120, width: '360px', borderRadius: '10px' }} className="mil-select-button mil-br-md mil-bg-m-4 d-flex justify-content-arround">
                                                    <span className="mil-selected-value mil-fw-600 mil-m-1" style={{ fontSize: '22px', whiteSpace: 'nowrap' }}>Pass Spontané</span>

                                                </div>
                                            }
                                            {/* <div style={{ height: 75, marginTop: 120 ,width:'360px', borderRadius: '10px'}} className="bdf mil-select-button mil-br-md mil-bg-m-4 d-flex justify-content-arround"> */}

                                            {/* <span className="mil-selected-value mil-fw-600 mil-m-1" style={{fontSize: '22px', whiteSpace: 'nowrap'}}>Pass Spontané</span>
    
                                            
                                           
                                        </div> */}
                                            {isAnticipation ?
                                                // <div className="bdf mil-select-button mil-br-md mil-bg-m-4 d-flex hidden" style={{ height: 75, marginTop: 120, width: '360px', borderRadius: '10px', justifyContent: 'end' }}>

                                                //     <span className="mil-selected-value mil-fw-600 mil-m-1" style={{ fontSize: '22px', whiteSpace: 'nowrap', marginRight: '3rem' }}>Pass Anticipation</span>
                                                // </div>

                                                <div></div>
                                                :
                                                // <div onClick={(val) => handleColorChange("isAnticipation")} className="hidden mil-select-button mil-br-md mil-bg-m-4 d-flex" style={{ height: 75, marginTop: 120, width: '360px', borderRadius: '10px', justifyContent: 'end' }}>

                                                //     <span className="mil-selected-value mil-fw-600 mil-m-1" style={{ fontSize: '22px', whiteSpace: 'nowrap', marginRight: '3rem' }}>Pass Anticipation</span>
                                                // </div>
                                                <div></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="mil-additional-services">
                                    <div className="testrow">
                                        {services.map((service) => (
                                            <ServiceCard
                                                key={service._id}
                                                service={service}
                                                onClick={onclick}
                                                checked={service.status} // vérifie si c'est le service sélectionné
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div style={{ background: 'white', borderRadius: 20 }} className="d-flex align-items-center flex-column flex-lg-row p-3">
                                    <div className="mb-3 mb-lg-0">
                                        <p style={{ fontSize: 13, color: 'rgb(123, 123, 122)', paddingLeft: '1rem' }}>
                                            Le prix est indicatif. Pour une discussion plus <br />détaillée de tous les services dont vous avez
                                            besoin, utilisez le formulaire de contact ou demandez à être rappelé.
                                        </p>
                                    </div>

                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center" style={{ width: '65%' }}>
                                        <div className="mb-3 mb-md-0">
                                            <p style={{ fontSize: 15, fontWeight: 'bold' }}>
                                                Prix total:<span> </span><span style={{ fontSize: 35 }}>{totalPrix}<span>&nbsp;</span><span style={{ fontSize: "18px" }}>{symbol}</span> </span>
                                            </p>
                                        </div>

                                        <div>
                                            <AuthManager>
                                                {(isLoggedIn, handleLogout) => (
                                                    <div>
                                                        {isLoggedIn ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary buttoyellow1"
                                                                style={{ padding: '10px 30px', fontWeight: 'bold', fontSize: '20px', width: 250 }}
                                                                disabled={!btnActive}
                                                                onClick={handdleNavigate}
                                                            >
                                                                Continuer ma commande
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary buttoyellow1"
                                                                style={{ padding: '10px 30px', fontWeight: 'bold', fontSize: '20px', width: 250 }}
                                                                onClick={handleVerificationUnConnect}
                                                                disabled={!btnActive}
                                                            >
                                                                Continuer ma commande
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </AuthManager>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >


            </div >



        </div>

    );
}


export default ServiceTemp;