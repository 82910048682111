import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerP } from '../../../services/auth/authService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { emailexiste, setEmail, verificationModalT } from '../../../store/actions';
import Verify from '../verfiy/Verify';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SearchBar from '../geolocation/SearchBar';

const MySwal = withReactContent(Swal);
const RegisterForProtecteur = () => {
    const [address, setAddress]= useState("");
    const [shortCountryName,setShortCountryName] = useState(null);
    const [formValues,setFormValues] = useState({address});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath_ = location.pathname;
    const [showPassword, setShowPassword] = useState(false);
    const [showCgu, setShowCgu] = useState(false);
    const [showCgv, setShowCgv] = useState(false);
    const [isShowBtn, setIsShowBtn] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);
    const erreur = useSelector((state) => state.__ct.message)

    const errors1 = useSelector((state) => state.__ct.erroremail)
    // Fonction de gestion de la case à cocher
    const handleCheckboxChange = () => {
        setShowPassword(!showPassword);
    };
    const [avatar, setAvatar] = useState(null);
    console.log('avatar hh ', avatar)
    const [preview, setPreview] = useState(null);


    const handleAddressGeocode = (e) => {
       
        
        setAddress( e);
        // formValues.address = addr;
        // setFormValues({
        //   ...formValues
        // });
      }
    
      const getProfileschedulesWithMyLocation = (coord) => {
    
        setShortCountryName(coord.short_countryName);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log('file',file)

        if (file) {
            const fileURL = URL.createObjectURL(file);
            setPreview(fileURL); // Pour prévisualiser l'image localement
            setAvatar(file); // Stocker le fichier pour l'envoi au serveur
        }
    };
    useEffect(() => {
        if (avatar) {
            setFormData(prevState => ({
                ...prevState,
                avatar: avatar
            }));
        }
    }, [avatar]);
    useEffect(() => {
        if (address) {
            setFormData(prevState => ({
                ...prevState,
                location: address
            }));
        }
    }, [address]);

    const handleCGVChange = () => {
        if (showCgu === true && !showCgv === true) {
            setIsShowBtn(true);
        } else {
            setIsShowBtn(false);
        }
        setShowCgv(!showCgv);
    };

    const handleCGUChange = () => {
        if (!showCgu === true && showCgv === true) {
            setIsShowBtn(true);
        } else {
            setIsShowBtn(false);
        }
        setShowCgu(!showCgu);
    };
    // État pour les données du formulaire
    const [formData, setFormData] = useState({
        name: '',
        password: '',
        email: '',
        avatar: avatar,
        phone: phoneNumber,
        location: address,
        role: 'protector'
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        password: '',
        email: '',
        location: ''
    });




    // Gestion de changement pour mettre à jour formData
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        // Validation simple
        if (value.trim() === '') {
            setFormErrors({
                ...formErrors,
                [name]: 'Ce champ est obligatoire'
            });
        } else {
            setFormErrors({
                ...formErrors,
                [name]: ''
            });
        }
    };

    const handleChangePhone = (value) => {
        setPhoneNumber(value);
        setValid(validphonenumber(value));
        setFormData((prevFormData) => ({
            ...prevFormData,
            phone: value,
        }));
    }
    const validphonenumber = (input) => {
        const patt = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return patt.test(input);
    }
    const email_ = useSelector((state) => state.__ct.email)
    localStorage.setItem('emailRedux', email_);
    const storedEmail = localStorage.getItem('emailRedux');
    console.log('ttttttttttttt,', storedEmail)



    const registerComponent = (formData, formData_) => {
        dispatch(registerP(formData, formData_))
            .then((result) => {
                handleActive();

            })
            .catch((e) => {
                console.log('errors ', e);
                if (e.error === "Ce mail existe déjà") {
                    dispatch(emailexiste());
                }
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if (field === 'avatar') {
                return;
            }


            if (formData[field].trim() === '') {
                newErrors[field] = `Ce ${field} est obligatoire`;
                formIsValid = false;
            }
        });

        setFormErrors(newErrors);

        if (formIsValid) {
            try {
                console.log('formdata kkkkkkkkkkkkk', formData)
                registerComponent(formData, navigate);
            } catch (error) {
                console.error('Erreur lors de l\'inscription', error);
            }
        }
    };

    // const [isActive, setIsActive] = useState(false)
    const handleActive = () => {
        dispatch(verificationModalT())
    }
    const isActive = useSelector((state) => state.packReducer.isActive)



    return (
        <div className='marginbottom104'>
            <section className="login-form sign-up-form d-flex align-items-center">

                {isActive ? <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}>
                    <Verify currentPath={currentPath_} />
                </div> : null}
                <div className="container mt-5" style={{ marginBottom: 150, }}>
                    <div className="login-form-title text-center">
                        <p style={{ fontSize: 40, fontWeight: 'bold', lineHeight: '48px' }}> Créez votre compte Protecteur,</p>
                        <p style={{ fontSize: 19, color: 'rgb(102, 102, 102)' }}>en quelques secondes</p>


                    </div>
                    <div className="login-form-box"> 
                        <div className="login-card">
                            <form onSubmit={handleSubmit}>


{/*                                 
                                <div className="upload">
                                    {preview != null ? (<img src={preview} width="100" height="100" alt="" />
                                    ) : <img src="./assets/images/noprofil.jpg" width={100} height={100} alt />}

                                    <div className="round">
                                        <input type="file" onChange={handleFileChange} />
                                        <i className="fa fa-camera" style={{ color: '#fff' }} />
                                    </div>
                                </div> */}
                                  <div className="upload">
                                    <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                        {preview != null ? (
                                            <img src={preview} width="100" height="100" alt="" />
                                        ) : (
                                            <img src="./assets/images/noprofil.jpg" width={100} height={100} alt="" />
                                        )}

                                        <div className="round">
                                            <i className="fa fa-camera" style={{ color: '#fff' }} />
                                        </div>
                                    </label>

                                    <input
                                        type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange}
                                    />
                                </div>




                                <div className={`form-group ${formErrors.name ? 'is-invalid' : ''}`}>
                                    <input
                                        className={`input-field form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="name"
                                        placeholder="Nom"

                                        onChange={handleChange}
                                    />
                                    {formErrors.name && <small className="text-danger">Veuillez saisir votre nom et prénom</small>}
                                </div>

                                <div className="form-group">
                                    <input
                                        className={`input-field form-control ${formErrors.location || errors1 ? 'is-invalid' : ''}`}
                                        type="email"
                                        name="email"
                                        placeholder="Email"

                                        onChange={handleChange}
                                    />
                                    {formErrors.email && <small className="text-danger">Veuillez saisir votre email</small>}
                                    {errors1 && <small className="text-danger" style={{ display: 'block', marginTop: 8 }}>Email existe déjà</small>}
                                </div>


                                <div className="form-group">
                                    {/* <label for="inputEmail4">Adresse</label> */}

                                    <SearchBar
                                        name="address"
                                        address={address}
                                        handleAddressGeocode={handleAddressGeocode}
                                        getProfileschedulesWithMyLocation={getProfileschedulesWithMyLocation}
                                        classNameVal={`input-field form-control ${formErrors.location ? 'is-invalid' : ''}`}
                                    />
                                    {formErrors.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                    </div>


                                {/* <div className="form-group">
                                    <input
                                        className={`input-field form-control ${formErrors.location ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="location"
                                        placeholder="Address"

                                        onChange={handleChange}
                                    />
                                    {formErrors.location && <small className="text-danger">Veuillez saisir votre address</small>}
                                </div> */}

                                <div className="form-group">
                                    <input
                                        className={`input-field form-control ${formErrors.password ? 'is-invalid' : ''}`}
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Mot de passe"

                                        onChange={handleChange}
                                    />
                                    {formErrors.password && <small className="text-danger">Veuillez saisir votre mot de passe</small>}
                                </div>
                                <div className="">
                                    <PhoneInput
                                        country={'fr'}
                                        className="phonenumberclass"
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone"

                                        value={phoneNumber}
                                        onChange={handleChangePhone}
                                        style={{ height: 61 }}
                                    />
                                    {formErrors.phone && <small className="text-danger">Veuillez saisir votre numéro de téléphone</small>}
                                </div>

                                <div className="form-check">
                                    <input
                                        className="form-check-input "
                                        type="checkbox"
                                        id="showPasswordCheckbox"
                                        style={{ marginLeft: '-10px' }}
                                        checked={showPassword}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label labelInsc" htmlFor="showPasswordCheckbox">
                                        Afficher le mot de passe
                                    </label>
                                </div>

                                <div className="form-check checkboxclass">
                                    <input
                                        className="form-check-input "
                                        type="checkbox"
                                        id="showCGVCheckbox"


                                        checked={showCgv}
                                        onChange={handleCGVChange}
                                    />
                                    <label className="form-check-label labelInsc"
                                    /*  htmlFor="showCGVCheckbox" */

                                    >
                                        J'accepte les  <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}>  conditions générales de vente </span> de Wemaza et la   <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}> politique données personnelles </span>
                                    </label>
                                </div>

                                <div className="form-check checkboxclass">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="showCguBox"

                                        checked={showCgu}
                                        onChange={handleCGUChange}
                                    />
                                    <label className="form-check-label labelInsc"
                                    /*  htmlFor="showCguBox" */
                                    >
                                        J'accepte les <span style={{ color: "#ec3654", textDecoration: 'underline', cursor: 'pointer' }}>  conditions générales d'utilisation  </span> de Wemaza
                                    </label>
                                </div>
                                {erreur != "" ? (
                                    <p
                                        style={{
                                            color: "#721c24",
                                            textAlign: "center",
                                            backgroundColor: "#f8d7da",
                                            padding: 10,
                                        }}
                                    >
                                        {erreur}
                                    </p>) : null}
                                {isShowBtn ?
                                    <button type="submit" className="hover-effect btn btn-primary mb-0">
                                        S'inscrire
                                    </button>
                                    :
                                    <button type="submit" className="hover-effect btn btn-primary mb-0" disabled style={{ cursor: 'not-allowed' }}>
                                        S'inscrire
                                    </button>
                                }

                            </form>
                        </div>
                        <div className="join-now-outer text-center">
                            <Link to={'/login'} style={{ textDecoration: 'none' }}><span style={{ textDecoration: 'underline' }}>Vous avez déjà un compte </span> ? <span style={{ color: '#4CAF50' }}> Se connecter</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RegisterForProtecteur;



