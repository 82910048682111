import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserBeneficiaryId } from '../../store/actions';

const ProfilBeneficiaire = ({ beneficiaire }) => {
    const dispatch = useDispatch()
    const hanldeSelectbeneficiaire = (id) => {

        localStorage.setItem('savedUserBeneficiaryId', id);
        dispatch(setUserBeneficiaryId(id))
    }

    const navigate = useNavigate();
    const navigateTrueFalse = useSelector(state => state.packReducer.navigateTrueFalse)
    const valeurRecuperee = localStorage.getItem('maCle')
    const handlesante =()=>{}
    // const handlesante = () => {
    //     if (valeurRecuperee === 'true') {
    //         navigate('/Intermediary')
    //     }
    //     else {
    //         navigate(`/paiement/${beneficiaire._id}`)
    //     }
    // }


    return (

        <div onClick={() => { handlesante(); hanldeSelectbeneficiaire(beneficiaire._id); }}
            className='with300px' style={{
            cursor:'pointer',
            background: "transparent",
            display: "flex",
            alignItems: "center",
            padding: "1px 40px",
            borderRadius: "8px",
            border: "2px solid #A5ADB4FF",
            width: "300px",
            height: "70px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            justifyContent: "space-between",
            marginTop: 20,
            margin: 20,
            background: "#E0E0E0"
        }}>
            <IconButton>
                <AccountCircle style={{ fontSize: 50, color: '#1976d2' }} />
            </IconButton>
            <div style={{
                marginLeft: "16px",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
                whiteSpace:'nowrap'
            }}>
                {beneficiaire.name}<br />
                <span style={{ fontSize: "14px", fontWeight: "normal", color: "#555" }}>Tel: {beneficiaire.phone}</span>
            </div>
        </div>

    );
};

export default ProfilBeneficiaire;